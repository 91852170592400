<template>
    <b-table
        id="customer-order-table"
        ref="customerordertable"
        sort-by="checkedOutAt"
        :fields="header"
        :items="customerOrderProvider"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :filter="filter"
        :sort-desc="sortDesc"
        sort-icon-left
        striped
    >
        <template #thead-top>
            <b-tr>
            <b-td colspan="2">
                <b-form-input 
                    type="search" 
                    placeholder="Search by Order ID"
                    v-model="filter"
                    :debounce="400"
                />
            </b-td>
            <b-td colspan="3">&nbsp;</b-td>
            </b-tr>
        </template>

        <template #cell(orderId)="data">
            <router-link :to="`/account/orders/${data.item.orderId}`">{{ data.item.orderId }}</router-link>
        </template>

        <template #cell(total)="data">
            {{ data.item.total | amountThousandSeparator }}
        </template>

        <template #cell(checkedOutAt)="data">
            {{ data.item.checkedOutAt | readableDateTime }}
        </template>

        <template #custom-foot>
            <b-tr>
                <b-td colspan="5">
                    <b-pagination
                        v-model="pagination.currentPage"
                        :total-rows="pagination.total"
                        :per-page="pagination.perPage"
                        aria-controls="customer-order-table"
                    />
                </b-td>
            </b-tr>
        </template>
    </b-table>
</template>

<script>
import { api } from "../../config";
export default {
    props: {
        userId: String
    },
    data() {
        return {
            header: [
                { key: 'orderId', label: 'Order ID' },
                { key: 'total', label: 'Amount' },
                { key: 'paymentMethod', label: 'Payment' },
                { key: 'checkedOutAt', label: 'Date', sortable: true },
                { key: 'status', label: 'Status' }
            ],
            filter: null,
            sortDesc: true,
            pagination: {
                perPage: 25,
                currentPage: 1,
                total: 0,
            }
        }
    },
    computed: {
        pageOffset() {

            if(this.pagination.currentPage == 1) return 0
            else return (this.pagination.perPage * this.pagination.currentPage) - this.pagination.perPage
        }
    },
    watch: {
        userId: function (value) {
            if(!value) return

            this.$refs.customerordertable.refresh()
        }
    },
    methods: {
        async customerOrderProvider(ctx) {
            let orders = []
            let endpoint = `${api}/customer/${this.userId}/orders`

            endpoint += `?limit=${ctx.perPage}&offset=${this.pageOffset}`
            if(ctx.filter != null && ctx.filter != '') {
                endpoint += `&orderId=${ctx.filter}`
            }
            endpoint += `&sorting=${ctx.sortDesc ? 'DESC' : 'ASC'}`

			let request = await fetch(endpoint, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			let response = await request.json();
            orders = response.rows.map((order) => {
				switch (order.status) {
					case "Reserved":
						order.status = "Processing";
						break;
					case "For Fraud Review":
						order.status = "Processing";
						break;
					case "For Payment Review":
						order.status = "Processing";
						break;
					case "For Stock Allocation":
						order.status = "Processing";
						break;
				}

                return order
			});

            this.pagination.total = response.count
			return orders;
        }
    }
}
</script>