<template>
	<div class="mb-5">
		<ProductBanner :title="details.name"></ProductBanner>

		<main role="main">
			<div class="products single-product pt-5">
				<div class="container">
					<div class="row mt-5">
						<div class="col-12">
							<b-breadcrumb :items="breadcrumbs" class="bg-light"></b-breadcrumb>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-12 col-md-12 col-lg-5">
							<div class="product-image mb-3">
								<ZoomImage :photo="details.photo" />
							</div>
							<div class="row product-image-thumbnails mb-4">
								<div class="col-3" v-for="(variant, index) in variants" :key="index">
									<router-link :to="`/products/${variant.slug}`">
										<img :src="variant.photo" class="img-fluid" />
									</router-link>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-12 col-lg-7">
							<h2 class="product-name text-uppercase">{{ details.name }}</h2>
							<h5>{{ details.manufacturer }}</h5>
							<h6 v-if="showModelSubtitle"><i>{{ details.model }}</i></h6>
							<h6>{{ details.category }}</h6>
							<b-row class="mb-2">
								<b-col>
									<b-form-rating
										id="productRater"
										variant="warning"
										class="mr-1"
										inline
										readonly
										v-model="productRating"
										@click.native="showRater"
									/>
									<!-- Ratings: {{ productRating }} -->
								</b-col>
							</b-row>
							<b-row class="mb-2">
								<b-col>
									<b-button variant="outline-danger" size="sm" @click="addToWishlist">
										<template v-if="!inWishlist">Add to Wishlist</template>
										<template v-else>Remove from Wishlist</template>
									</b-button>

									<b-button
										class="ml-3"
										size="sm"
										variant="outline-danger"
										v-if="details.department === 'Motorcycles' && comparison.includes(details.sku)"
										@click="removeFromComparison(details.sku)"
									>Remove from comparison</b-button>

									<template v-if="details.department === 'Motorcycles' && !comparison.includes(details.sku)">
										<b-button
											class="ml-3"
											size="sm"
											variant="outline-primary"
											v-if="comparison.length < 3"
											@click="addToComparison(details.sku)"
										>Add to compare</b-button>

										<b-button
											class="ml-3"
											size="sm"
											variant="outline-primary"
											disabled
											v-else
											v-b-tooltip.hover
											title="You already have 3 products in your comparison list, remove first a product from the list."
										>Add to compare</b-button>
									</template>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<p class="product-description" style="white-space: pre-wrap">{{ details.shortDescription }}</p>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<h6 class="product-price mb-3">
										<span v-if="details.department === 'Motorcycles'">SKU: {{ details.materialNo }}</span>
										<!-- <span v-else>Part No: {{ details.partNumber }}</span> -->
									</h6>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<h3 class="product-price mb-3">{{ details.price | currencyDisplay }}</h3>
								</b-col>
							</b-row>
							<b-row align-v="baseline">
								<b-col col offset-md="0" cols="12" md="4" class="mb-3">
									<template v-if="loading.stocks">
										<p>
											<b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="2" />Loading stocks...
										</p>
									</template>
									<template v-else>
										<p v-if="stocks <= 0">Out of stock</p>
										<input
											v-else
											type="number"
											value="1"
											min="1"
											:max="stocks"
											step="1"
											id="quantity"
											v-model="entryQuantity"
											class="form-control"
										/>
									</template>
								</b-col>
								<b-col col cols="12" md="4">
									<b-button
										block
										class="rounded-0 text-uppercase"
										variant="danger"
										:disabled="!ready || (entryQuantity < 1 || entryQuantity > stocks)"
										@click="addToCart()"
									>Add to Cart</b-button>
								</b-col>
							</b-row>
							<b-row class="mt-1">
								<b-col>
									<p class="product-description text-danger" v-if="(details.LTOFee != 1 || details.freebies != null) && details.department === 'Motorcycles'">
										<span v-if="details.LTOFee != 1 || details.freebies != null">For Single Order Only:<br></span>
										<span v-if="details.LTOFee != 1">Free LTO Registration for Loan Payment<br></span>
										<span v-if="details.freebies != null && details.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
										<span v-if="details.freebies != null && details.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
									</p>
									<p class="product-description text-danger" v-if="details.department === 'Motorcycles'">
										Motorcycle Delivery is available for NCR only. 
									</p>
									<p class="product-description text-danger" v-if="details.department !== 'Motorcycles'">
										Delivery of parts & accessories is available for the following only: NCR, Zamboanga City, Davao City, Tagum City 
									</p>
								</b-col>
							</b-row>
						</div>
					</div>

					<!-- Product Specifications -->
					<div class="row mt-5">
						<div class="col-12">
							<table class="table">
								<tbody
									v-for="(specificationValue, specificationKey) in details.specification"
									:key="specificationKey"
								>
									<tr style="background-color: rgba(0,0,0,.05);">
										<th scope="col" colspan="2">{{ specificationKey | unCamelCase }}</th>
									</tr>
									<tr v-for="(value, property) in specificationValue" :key="property">
										<td>{{ property }}</td>
										<td>{{ value }}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<!-- End Product Specifications -->

					<!-- Review Section starts here -->
					<ReviewList :product="details"></ReviewList>
					<br />
					<!-- Review Section ends here -->

					<b-modal
						ref="login-modal"
						ok-title="Login"
						@ok="proceedToLogin"
					>In order to proceed, please create or login an account.</b-modal>

					<b-modal ref="rating-modal" ok-title="Login" title="Rate Now!" hide-footer>
						<b-form-rating variant="warning" @change="postRating" v-model="userProductRating"></b-form-rating>
					</b-modal>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import Noty from "noty";
import { mapGetters } from "vuex";
import { find, includes } from "lodash";
import { api } from "../../config";
import { analytics } from "../../main";

import ReviewList from "@/components/Review/List";
import ProductBanner from "@/components/ProductBanner";
import ZoomImage from "@/components/Products/ZoomImage";

export default {
	components: {
		ReviewList,
		ProductBanner,
		ZoomImage,
	},
	data: () => ({
		loading: {
			stocks: true,
		},
		details: {
			price: 0,
			name: "",
		},
		productRating: 0,
		userProductRating: 0,
		entryQuantity: 1,
		comments: [],
		reviews: [],
		variants: [],
		questions: [],
		purchasedProducts: [],
		stocks: 0,
		ready: true,
		inWishlist: false,
		unquestioned: true,
		breadcrumbs: [],
		couriersId: [],
		couriersDetails: []
	}),
	computed: {
		...mapGetters({
			user: "user",
			loggedIn: "loggedIn",
			order: "Cart/order",
			entries: "Cart/entries",
			comparison: "Cart/comparison",
			token: "token",
		}),
		purchased() {
			return find(
				this.purchasedProducts,
				{ "entries.sku": this.details.sku } !== undefined
			);
		},
		showModelSubtitle() {
			let dept = this.details.department
			let validDept = ['Parts', 'Accessories', 'Helmet']

			return includes(validDept, dept)
		}
	},
	metaInfo() {
		return {
			meta: [
				// Twitter Card
				{ name: "twitter:card", content: "summary" },
				{ name: "twitter:title", content: this.details.name },
				{
					name: "twitter:description",
					content: this.details.shortDescription,
				},
				// image must be an absolute path
				{ name: "twitter:image", content: this.details.photo },
				// Facebook OpenGraph
				{ property: "og:title", content: this.details.name },
				{
					property: "og:site_name",
					content: "Transcycle Online Store",
				},
				{ property: "og:type", content: "website" },
				{ property: "og:image", content: this.details.photo },
				{
					property: "og:description",
					content: this.details.shortDescription,
				},
			],
		};
	},
	async mounted() {
		await this.fetchCouriers()
		await this.getProductVariant(this.$route.params.slug);
		await this.$store.dispatch("Cart/getOrder")
	},
	methods: {
		async fetchCouriers() {
			this.couriersId = []
			this.couriersDetails = []
            let couriers = []
			let request = await fetch(`${api}/setup/couriers/fetch`, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${this.$store.getters.token}`,
				},
			});
			couriers = await request.json();
			couriers.data.forEach(element => {
				this.couriersId.push(element.nsId)
				this.couriersDetails.push ({
					nsID: element.nsId,
					name: element.name
				})
			});
			console.log(this.couriersDetails)
        },
		async fetchDimensionsCourier(nsItemId) {
			let self = this
			let count = 0
			let courierDetails = []
            let load = {
				"itemId": [nsItemId],
				"couriersId": this.couriersId
			}
			let reqCouriers = await fetch(`${api}/products/dimension-per-courier`, {
				method: 'POST',
				body: JSON.stringify(load),
				headers: {
					"Content-Type": "application/json",
					"Authorization": `Bearer ${this.$store.getters.token}`,
				},
			});
			courierDetails = await reqCouriers.json();
			console.log(self.details)
			courierDetails.data.forEach(function(res) {
				count ++
				console.log(res.values.custrecord_ucfc_vendor[0].text)
				if (res.values.custrecord_ucfc_vendor[0].text.includes("PH GLOBAL JET EXPRESS, INC.")) {
					self.details.jnt = {
						box_length: res.values.custrecord_ucfc_fld_box_length,
						box_width: res.values.custrecord_ucfc_fld_box_width,
						box_height: res.values.custrecord_ucfc_fld_box_height,
						package_weight: res.values.custrecord_ucfc_fld_packageweight
					}
				} 
				if (res.values.custrecord_ucfc_vendor[0].text.includes("WALL STREET COURIER SERVICES INC.")) {
					self.details.ninjaVan = {
						box_length: res.values.custrecord_ucfc_fld_box_length,
						box_width: res.values.custrecord_ucfc_fld_box_width,
						box_height: res.values.custrecord_ucfc_fld_box_height,
						package_weight: res.values.custrecord_ucfc_fld_packageweight
					}
				} 
			})
			if (courierDetails.data.length === count ) {
				console.log(this.details)
			}
        },
		async getProductVariant(slug) {
			let request = await fetch(`${api}/products/details/${slug}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			this.details = await request.json();
			this.fetchDimensionsCourier(this.details.nsItemId)
			this.details.specification = JSON.parse(
				this.details.longDescription
			);
			this.details.dimensions = JSON.parse(
				this.details.dimensions
			);
			this.details.packaging = JSON.parse(
				this.details.packaging
			);
			console.log(this.details)
			this.breadcrumbs = [
				{
					text: this.details.department,
					to: `/products/${this.details.department}`
				},
				{
					text: this.details.manufacturer,
					to: `/products/${this.details.department}?categories&departments=${this.details.department}&hitsPerPage=20&manufacturers[]=SUZUKI%20PHILIPPINES%20INC.&page=1&price&replica=products_timestamp_desc&term`
				},
				{
					text: this.details.category,
					to: `/products/${this.details.department}?categories=${this.details.category}&departments=${this.details.department}&hitsPerPage=20&manufacturers&page=1&price&replica=products_timestamp_desc&term`
				},
				{
					text: this.details.name,
					to: `/products/${this.details.slug}`
				},
			]

		},
		async checkWishlist() {
			if (this.loggedIn) {
				let request = await fetch(`${api}/customer/wishlists/exists/${this.details.sku}`, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`,
					},
				});
				let response = await request.json();
				this.inWishlist = response.exists;
			}
		},
		async getOtherProductVariants() {
			if (this.details.department !== "Motorcycles") return;

			let request = await fetch(
				`${api}/products/details/others/${encodeURIComponent(this.details.model)}/${this.details.sku}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			this.variants = await request.json();
		},
		async fetchProductStocks() {
			this.loading.stocks = true;
			let department = this.details.department
				.replace(/\//g, " ")
				.replace(/\s/g, "-");

			let request = await fetch(
				`${api}/products/stocks/${department}/${this.details.sku}/${this.details.objectID}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			let responseBody = await request.json();

			this.stocks = responseBody.stocks;
			this.ready = true;
			this.loading.stocks = false;
		},
		switchVariant(element) {
			document.getElementById("primaryImage").src = element.getAttribute(
				"data-image"
			);
			document.getElementById("total").innerHTML = "{{total}}";
			analytics.logEvent("switched_product_variant");
		},
		async addToCart() {
			this.ready = false;

			if (!this.loggedIn) {
				this.$refs["login-modal"].show();
				return;
			}

			let quantity = parseInt(this.entryQuantity);
			let activeEntryIndex = this.entries.findIndex((item) => item.sku === this.details.sku);

			let newQuantity = this.entries[activeEntryIndex] === undefined ? this.entryQuantity : quantity + parseInt(this.entries[activeEntryIndex].quantity)
			await this.fetchProductStocks();

			if(newQuantity <= this.stocks) {
				await fetch(`${api}/cart/entries`, {
					method: "POST",
					body: JSON.stringify({
						orderId: this.order.orderId,
						sku: this.details.sku,
						objectID: this.details.objectID,
						quantity,
						product: this.details.slug,
						name: this.details.name,
						photo: this.details.photo,
						price: this.details.price,
						LTORegistrationFee: this.details.LTORegistrationFee,
						dimensions: this.details.dimensions,
						packaging: this.details.packaging,
						itemGroup: this.details.group,
						ninjaVan: this.details.ninjaVan,
						jnt: this.details.jnt
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`,
					},
				});

				new Noty({
					type: "success",
					theme: "semanticui",
					layout: "bottomRight",
					text: `${this.details.name} added to cart`,
					timeout: 5000,
				}).show();

				analytics.logEvent("added_cart_entry");

				this.stocks = this.stocks - quantity;
				await this.$store.dispatch("Cart/getOrder");
			} else {
				new Noty({
					type: "error",
					theme: "semanticui",
					layout: "bottomRight",
					text: `Insufficient stocks for ${this.details.name}`,
					timeout: 5000,
				}).show();
			}
			
			this.ready = true;
		},
		showRater() {
			if (this.loggedIn && this.purchased)
				this.$refs["rating-modal"].show();
		},
		async fetchProductRating() {
			let response = await fetch(
				`${api}/products/rating/${this.details.sku}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			let responseBody = await response.json();

			this.productRating = responseBody.data;
		},
		async postRating() {
			let self = this;

			let response = await fetch(`${api}/products/rating`, {
				method: "POST",
				body: JSON.stringify({
					userId: this.user.userId,
					rating: this.userProductRating,
					sku: this.details.sku,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.token}`,
				},
			});

			if (response.status === 200) {
				new Noty({
					type: "success",
					theme: "semanticui",
					layout: "bottomRight",
					text: "Rating has been posted",
					timeout: 5000,
				}).show();
				analytics.logEvent("posted_rating");
				setTimeout(() => {
					self.$refs["rating-modal"].hide();
					self.fetchProductRating();
				}, 1000);
			}
		},
		addToComparison(sku) {
			this.$store.dispatch("Cart/addToComparison", sku);

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${this.details.name} was added to comparison`,
				timeout: 5000,
			}).show();

			analytics.logEvent("added_comparison_entry");
		},
		removeFromComparison(sku) {
			this.$store.dispatch("Cart/removeFromComparison", sku);

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${this.details.name} was removed from comparison`,
				timeout: 5000,
			}).show();
			analytics.logEvent("removed_comparison_entry");
		},
		async addToWishlist() {
			let action = "added";

			await fetch(`${api}/customer/wishlists`, {
				method: "POST",
				body: JSON.stringify({
					sku: this.details.sku,
					status: !this.inWishlist,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			if (this.inWishlist) action = "removed";

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${this.details.name} was ${action} to your wishlist`,
				timeout: 5000,
			}).show();

			analytics.logEvent(`${action}_wishlist_entry`);

			await this.checkWishlist();
		},
		async fetchPurchasedProducts() {
			if (!this.loggedIn) return;

			let response = await fetch(`${api}/products/purchased/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.token}`,
				},
			});

			let responseBody = await response.json();

			this.purchasedProducts = responseBody.data;
		},
		proceedToLogin() {
			analytics.logEvent("redirected_cart_login");
			this.$router.push(`/login?redirectURL=${this.$route.path}`);
		},
	},
	watch: {
		async details() {
			await this.checkWishlist();
			await this.getOtherProductVariants();
			await this.fetchProductStocks();
			await this.fetchPurchasedProducts();
			await this.fetchProductRating();
		},
	},
};
</script>

<style>
.description {
	margin-top: 50px;
}

.table td.fit:first-child,
.table th.fit:first-child {
	white-space: nowrap;
	width: 30%;
}

image {
	float: left;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	border: 1px solid #ebebeb;
	margin: 5px;
}

.gallery li {
	display: inline;
}

.button {
	background-color: rgb(179, 5, 34);
	margin-left: 20px;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.textarea {
	margin-left: 10px;
	margin-right: 20px;
}

.btn-margin {
	margin-left: 30px;
	margin-bottom: 20px;
}

.review-sub {
	margin-left: 60px;
	display: block;
}

.review-main {
	margin-left: 0px !important;
}

.query-sub {
	margin-left: 60px;
	display: block;
}

.query-main {
	margin-left: 0px !important;
}

ul:first-child {
	margin-left: 0px !important;
}

ul {
	margin-left: 60px;
	display: block;
}

textarea {
	display: block;
	box-sizing: padding-box;
	overflow: hidden;
	padding: 5px;
	width: 250px;
	font-size: 14px;
	margin: 10px auto;
	border-radius: 6px;
	box-shadow: 2px 2px 8px rgba(black, 0.3);
	border: 0;
}

#productRater:hover {
	cursor: pointer;
}
</style>
