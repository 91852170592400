<template>
	<div>
		<banner title="Reservation" />
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h4>Reservation {{status}}</h4>
							<p>Reservation is valid only for 3 day(s). Reservation fee is non-refundable. In case that completion of payment was not made within the reservation period, the reserved product will be released back to the saleable items pool.</p>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	}
}
</script>