<template>
	<div>

		<banner title="View Order" />
		<main role="main">
			<section class="products ptb-5">
				<div class="container" v-if="Object.keys(order).length">
					<div class="row">
						<div class="bs-stepper mb-3" style="width: 100%;">
							<div class="bs-stepper-header" role="tablist">
								<div class="step">
									<button type="button" class="step-trigger" role="tab">
										<span class="bs-stepper-circle" id="paidStep">1</span>
										<span class="bs-stepper-label" id="paidLabel">Checkout</span>
									</button>
								</div>
								<div class="bs-stepper-line" id="paidLine"></div>
								<div class="step">
									<button type="button" class="step-trigger" role="tab">
										<span class="bs-stepper-circle" id="processingStep">2</span>
										<span class="bs-stepper-label" id="processingLabel">Processing</span>
									</button>
								</div>
								<div class="bs-stepper-line" id="processingLine"></div>
								<div class="step">
									<button type="button" class="step-trigger" role="tab">
										<span class="bs-stepper-circle" id="shippedStep">3</span>
										<span class="bs-stepper-label" id="shippedLabel">Shipping</span>
									</button>
								</div>
								<div class="bs-stepper-line" id="shippedLine"></div>
								<div class="step">
									<button type="button" class="step-trigger" role="tab">
										<span class="bs-stepper-circle" id="deliveredStep">4</span>
										<span class="bs-stepper-label" id="deliveredLabel">Completed</span>
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-md-6">
							<div class="card">
								<div class="row no-gutters">
									<div class="col-md-12">
										<div class="card-body">
											<table class="table table-striped">
												<thead>
													<tr>
														<th scope="col">Item</th>
														<th scope="col">Amount</th>
													</tr>
												</thead>
												<tbody>
													<tr class="clickable-row" v-for="entry in entries" :key="entry.uid">
														<td>
															<router-link :to="`/products/${entry.product}`">{{ entry.name }}</router-link>
														</td>
														<td>{{ entry.price | currencyDisplay }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>

							<div class="row mt-3" v-if="order.status === 'To Pay'">
								<div class="col-12">
									<hr />
									<b-button @click="recallOrder" class="btn-red" :disabled="recalling">Change Payment Option</b-button>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="card">
								<div class="row">
									<div class="col-md-12">
										<div class="card-body">
											<h5 class="card-title">
												Order #
												<a :href="`/account/orders/${order.orderId}`">{{ order.orderId }}</a>
											</h5>
											<table class="table mt-3">
												<tbody>
													<tr v-if="payments.length">
														<td>Payment Ref #:</td>
														<td class="text-right">{{ payments[payments.length - 1].reference }}</td>
													</tr>
													<tr v-if="order.salesInvoiceNumber">
														<td>Sales Invoice #:</td>
														<td class="text-right">{{ order.salesInvoiceNumber }}</td>
													</tr>
													<tr>
														<td>Date Ordered:</td>
														<td class="text-right">{{ order.checkedOutAt | readableDateTime }}</td>
													</tr>
													<tr>
														<td>Payment Method:</td>
														<td class="text-right">{{ order.paymentMethod }} <span v-if="order.paymentMethod === 'Pay Online'">- {{ order.paymentChannel }}</span></td>
													</tr>
													<tr>
														<td>Shipping Method:</td>
														<td class="text-right">
															{{ order.shippingMethod.toUpperCase() }}
															<span
																v-if="order.shippingMethod === 'delivery'"
															>
																({{ order.deliveryDateStart | readableMonthDay }} -
																{{ order.deliveryDateEnd | readableMonthDay }})
															</span>
															<br />
															<span>{{ order.branchName }}</span>
														</td>
													</tr>
													<tr v-if="(order.freebies != null) && order.paymentMethod === 'Pay in Installment'">
														<td>Promos applied</td>
														<td class="text-right">
															<span v-if="order.freebies != null && order.freebies.includes('LTO')">Free LTO Registration for Loan Payment<br></span>
															<span v-if="order.freebies != null && order.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
															<span v-if="order.freebies != null && order.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
														</td>
													</tr>
													<tr v-if="order.shippingMethod === 'delivery'">
														<td>Shipping Address:</td>
														<td class="text-right">
															<span>
																{{order.deliveryAddress}}
															</span>
														</td>
													</tr>
												</tbody>
											</table>

											<table class="table mt-3">
												<thead>
													<tr>
														<th scope="col" colspan="2">Summary</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td>Quantity:</td>
														<td class="text-right">{{ entryCount | thousandSeparator }}</td>
													</tr>
													<tr v-if="order.paymentMethod == 'Pay in Installment'">
														<td>Downpayment:</td>
														<!-- <td class="text-right">{{ application.downpaymentAmount | amountThousandSeparator }}</td> -->
														<td class="text-right">{{ application.downpaymentAmount ? (application.downpaymentAmount | amountThousandSeparator) : 0  }}</td>
													</tr>
													<tr>
														<td>LTO Registration Fee</td>
														<td class="text-right">{{ order.ltoRegistrationFees | amountThousandSeparator }}</td>
													</tr>
													<tr v-if="order.shippingMethod === 'delivery'">
														<td>Delivery Fee</td>
														<td class="text-right">{{ order.shipping | amountThousandSeparator }}</td>
													</tr>
													<tr>
														<td><b>Subtotal</b></td>
														<td class="text-right"><b>{{ order.subtotal | amountThousandSeparator }}</b></td>
													</tr>
													<tr v-if="parseFloat(order.onlineTransactionFee) > 0">
														<td>Online Transaction Fee</td>
														<td class="text-right">{{ order.onlineTransactionFee | amountThousandSeparator }}</td>
													</tr>
													<tr>
														<td><b>Total</b></td>
														<td class="text-right">
															<b>{{ order.total | currencyDisplay }}</b>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container" v-else>
					<div class="row">
						<div class="col-12">
							<h5>Order is not available</h5>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
import { api } from "../../config";
import { mapGetters } from "vuex";
import { channels } from "../../channels";
import $ from "jquery";
import _ from "lodash";
export default {
	components: {
		banner
	},
	data: () => ({
		order: {
		},
		payments: [],
		entries: [],
		entryCount: 0,
		application: {
			downpayment: 0,
		},
		paymentChannels: [],
		recalling: false,
	}),
	computed: {
		...mapGetters({
			user: "user"
		})
	},
	async mounted() {
		this.getOrder(this.$route.params.id);
		this.getOrdeEntries(this.$route.params.id);
		this.getPayment(this.$route.params.id);

		this.paymentChannels = [];
		channels.forEach((group) => {
			group.options.forEach((option) => {
				this.paymentChannels.push(option);
			});
		});
	},
	methods: {
		async getOrder(id) {
			let request = await fetch(`${api}/order/explicit/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.order = await request.json();

			if (this.order.downpayment === 1) {
				this.getApplication(this.$route.params.id);
			}

			if(this.order.paymentMethod === "Pay Online") {
				let channelIndex = this.paymentChannels.findIndex(
					(channel) => channel.value == this.order.method
				);
				this.order.paymentChannel = this.paymentChannels[channelIndex].text;
			}
		},
		async getOrdeEntries(id) {
			let request = await fetch(`${api}/order/entries/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			let response = await request.json();

			this.entries = response.data

			this.entryCount = _.sumBy(this.entries, (item) => {
				return parseFloat(item.quantity);
			});
		},
		async getPayment(id) {
			let request = await fetch(`${api}/payments/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.payments = await request.json();
		},
		async getApplication(orderId) {
			let request = await fetch(`${api}/applications/${orderId}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});
			let response = await request.json();
			this.application = response.application;
		},
		recallOrder() {
			this.recalling = true;
			this.$router.push(`/cart/checkout/${this.order.orderId}`);
		},
	},
	watch: {
		order(value) {

			let color = "#4CAF50";
			let status = value.status;

			switch (value.status) {
				case "To Pay":
					status = "Pending";
					break;
				case "For Fraud Review":
					status = "Processing";
					break;
				case "For Payment Review":
					status = "Processing";
					break;
				case "For Loan Evaluation":
					status = "Processing";
					break;
				case "For Document Checking":
					status = "Processing";
					break;
				case "For Loan Application Update":
					status = "Processing";
					break;
				case "For Stock Allocation":
					status = "Processing";
					break;
				case "Delivered":
					status = "Shipped";
					break;
				case "Picked Up":
					status = "Shipped";
					break;
			}

			setTimeout(() => {
				switch (status) {
					case "Pending":
						$("#paidStep").css("background-color", color);
						$("#paidLabel").css("color", color);
						$("#paidLine").css("border", `1px solid ${color}`);
						break;
					case "Processing":
						$("#paidStep").css("background-color", color);
						$("#paidLine").css("background-color", color);
						$("#paidLabel").css("color", color);

						$("#processingStep").css("background-color", "#ffffff");
						$("#processingStep").css("color", color);
						$("#processingStep").css(
							"border",
							`1px solid ${color}`
						);
						$("#processingLabel").css("color", color);
						break;
					case "Shipped":
						$("#paidStep").css("background-color", color);
						$("#paidLine").css("background-color", color);
						$("#paidLabel").css("color", color);

						$("#processingStep").css("background-color", color);
						$("#processingLine").css("background-color", color);
						$("#processingLabel").css("color", color);

						$("#shippedStep").css("background-color", "#ffffff");
						$("#shippedStep").css("color", color);
						$("#shippedStep").css("border", `1px solid ${color}`);
						$("#shippedLabel").css("color", color);
						break;

					case "Closed":
						$("#paidStep").css("background-color", color);
						$("#paidLine").css("background-color", color);
						$("#paidLabel").css("color", color);

						$("#processingStep").css("background-color", color);
						$("#processingLine").css("background-color", color);
						$("#processingLabel").css("color", color);

						$("#shippedStep").css("background-color", color);
						$("#shippedLabel").css("color", color);
						$("#shippedLine").css("background-color", color);

						$("#deliveredStep").css("background-color", "#ffffff");
						$("#deliveredStep").css("color", color);
						$("#deliveredLabel").css("color", color);
						$("#deliveredStep").css("border", `1px solid ${color}`);
						break;
				}
			}, 1000);
		},
	},
};
</script>
