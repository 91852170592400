<template>
    <b-modal
        ref="sessionTimeOutModal"
        centered
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        cancel-title="Logout"
        ok-title="Continue Session"
        title="Session Alert"
        @shown="runExpirationCountDown"
        @ok="emitOk"
        @cancel="emitCancel"
        @hidden="expirationCountDown = 60"
    >
        <div class="d-block text-center">
            <p>Your session is about to expire.</p>
            <p>You will be logout in {{expirationCountDown}} secs.</p>
        </div>
    </b-modal>
</template>

<script>
export default {
    data() {
        return {
            expirationCountDown: 60,
            countdownInterval: null
        }
    },
    methods: {
        runExpirationCountDown() {
            let self = this

			clearInterval(self.countdownInterval)
			this.countdownInterval = setInterval(function () {
				self.expirationCountDown--
				if(self.expirationCountDown <= 0) {
					clearInterval(self.countdownInterval)
					self.emitCancel()
					self.$refs.sessionTimeOutModal.hide();
				}
			}, 1000)
        },
        emitOk() {
            clearInterval(this.countdownInterval)
            this.$emit('continue-session')
        },
        emitCancel() {
            this.$emit('cancel-session', true)
        }
    }
}
</script>