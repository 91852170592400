import Vue from "vue";
import Vuex from "vuex";
import { api } from "../config";

import { Cart } from "./Cart";
import { Setup } from "./Setup";
import UserLocation from "./UserLocation";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		user: {
			data: null,
		},
		loggedIn: false,
		liveChatReset: false,
		token: null,
		expirationTime: null,
		completeProfile: false,
	},
	getters: {
		user(state) {
			return state.user;
		},
		loggedIn(state) {
			return state.loggedIn;
		},
		liveChatReset(state) {
			return state.liveChatReset;
		},
		token(state) {
			return state.token;
		},
		expirationTime(state) {
			return state.expirationTime;
		},
		completeProfile(state) {
			return state.completeProfile;
		},
	},
	mutations: {
		SET_LOGGED_IN(state, value) {
			state.loggedIn = value;
		},
		SET_LIVECHAT_RESET(state, value) {
			state.liveChatReset = value;
		},
		SET_USER(state, data) {
			state.user = data;
		},
		SET_TOKEN(state, data) {
			state.token = data;
		},
		SET_EXPIRATION_TIME(state, data) {
			state.expirationTime = data
		},
		SET_PROFILE_STATUS(state, data) {
			state.completeProfile = data;
		},
	},
	actions: {
		async fetchUser({ commit, rootState }, user) {
			if (user.email) {
				let request = await fetch(`${api}/user/${user.email}`, {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootState.token}`,
					},
				});
				let response = await request.json();

				commit("SET_LOGGED_IN", true);
				commit("SET_LIVECHAT_RESET", false);

				commit("SET_USER", response);

				commit(
					"SET_PROFILE_STATUS",
					response.firstname !== null &&
						response.lastname !== null &&
						response.contactNumber !== null &&
						response.streetAddress !== null &&
						response.barangay !== null &&
						response.city !== null &&
						response.province !== null &&
						response.region !== null &&
						response.firstname.length >= 1 &&
						response.lastname.length >= 1 &&
						response.contactNumber.length >= 1 &&
						response.streetAddress.length >= 1 &&
						response.barangay.length >= 1 &&
						response.city.length >= 1 &&
						response.province.length >= 1 &&
						response.region.length >= 1
				);
			} else {
				commit("SET_LOGGED_IN", false);
				commit("SET_USER", null);
			}
		},
		async setUserPrivacyPolicy({ rootState }, payload) {
			await fetch(`${api}/users/privacy`, {
				method: "post",
				body: JSON.stringify({
					value: payload,
					userId: rootState.user.userId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
		},
		async logout({ commit }) {
			commit("SET_LIVECHAT_RESET", true);
			commit("SET_LOGGED_IN", false);
			commit("SET_USER", {});
		},
		async setToken({ commit }, payload) {
			commit("SET_TOKEN", payload);
		},
		async setExpirationTime({ commit }, payload) {
			commit("SET_EXPIRATION_TIME", payload);
		},
		// async validateReferral({ rootState }, payload) {
		// 	if (payload) {
		// 		await fetch(`${api}/referrals`, {
		// 			body: JSON.stringify({
		// 				code: payload.code,
		// 				user: payload.user,
		// 			}),
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `Bearer ${rootState.token}`,
		// 			},
		// 		});
		// 	}
		// },
		// async noReferralLogin({ rootState }, payload) {
		// 	if (payload) {
		// 		await fetch(`${api}/referrals/standalone`, {
		// 			body: JSON.stringify({
		// 				user: payload.user,
		// 			}),
		// 			headers: {
		// 				"Content-Type": "application/json",
		// 				Authorization: `Bearer ${rootState.token}`,
		// 			},
		// 		});
		// 	}
		// },
	},
	modules: {
		Cart,
		Setup,
		UserLocation
	},
});
