import Vue from "vue";
import VueRouter from "vue-router";
import queryString from "query-string";
import Layout from "../views/Layout.vue";

import Home from "../views/Home.vue";
import Branches from "../views/Branches.vue";
import Login from "../views/Login.vue";
import Logout from "../views/Logout.vue";

import ProductsMotorcycles from "../views/Products/Motorcycles.vue";
import ProductsPartsAccessories from "../views/Products/Parts&Accessories.vue";
import ProductsView from "../views/Products/View.vue";
import ProductsComparison from "../views/Products/Comparison.vue";

import Cart from "../views/Cart/Index.vue";
import CartCheckout from "../views/Cart/Checkout.vue";
import CartStatus from "../views/Cart/Status.vue";
import CartReservation from "../views/Cart/Reservation.vue";
import CartReservationStatus from "../views/Cart/ReservationStatus.vue";
import ProductsParts from "../views/Products/Parts.vue";
import ProductsOils from "../views/Products/Oils.vue";
import ProductsHelmets from "../views/Products/Helmets.vue";
import ProductsAccessories from "../views/Products/Accessories.vue";
import Promos from "../views/Products/Promos.vue";

import Account from "../views/Account/Index.vue";
import ViewOrder from "../views/Account/Orders.vue";
import ApplicationsNew from "../views/Applications/New.vue";
import ApplicationsStatus from "../views/Applications/Status.vue";
import ApplicationsView from "../views/Applications/View.vue";
import ApplicationsProcess from "../views/Applications/Process.vue"

import FAQ from "../views/FAQ.vue";
import TermsAndConditions from "../views/TermsAndConditions.vue";
import About from "../views/About.vue";


import PageNotFound from "../views/PageNotFound.vue"

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Layout",
		component: Layout,
		redirect: "/home",
		children: [
			{
				path: "/home",
				name: "Home",
				component: Home,
				meta: {
					protected: false,
					title: "Home",
				},
			},
			{
				path: "/branches",
				name: "Branches",
				component: Branches,
				meta: {
					protected: false,
					title: "Our Branches",
				},
			},
			{
				path: "/login/:code?",
				name: "Login",
				component: Login,
				meta: {
					protected: false,
					title: "Login",
				},
			},
			{
				path: "/logout",
				name: "Logout",
				component: Logout,
				meta: {
					protected: false,
					title: "Logout",
				},
			},
			{
				path: "/products/motorcycles",
				name: "ProductsMotorcycles",
				component: ProductsMotorcycles,
				meta: {
					protected: false,
					title: "Motorcycles",
				},
			},
			{
				path: "/products/parts&accessories",
				name: "ProductsPartsAccessories",
				component: ProductsPartsAccessories,
				meta: {
					protected: false,
					title: "Products & Accessories",
				},
			},
			{
				path: "/products/comparison",
				name: "ProductsComparison",
				component: ProductsComparison,
				meta: {
					protected: false,
					title: "Product Comparison",
				},
			},
			{
				path: "/products/parts",
				name: "ProductsParts",
				component: ProductsParts,
				meta: {
					protected: false,
					title: "Spare Parts",
				},
			},
			{
				path: "/products/oils",
				name: "ProductsOils",
				component: ProductsOils,
				meta: {
					protected: false,
					title: "Engine Oils",
				},
			},
			{
				path: "/products/helmets",
				name: "ProductsHelmets",
				component: ProductsHelmets,
				meta: {
					protected: false,
					title: "Helmets",
				},
			},
			{
				path: "/products/accessories",
				name: "ProductsAccessories",
				component: ProductsAccessories,
				meta: {
					protected: false,
					title: "Accessories",
				},
			},
			{
				path: "/products/promos",
				name: "Promos",
				component: Promos,
				meta: {
					protected: false,
					title: "Promos"
				},
			},
			{
				path: "/products/:slug",
				name: "ProductsView",
				component: ProductsView,
				meta: {
					protected: false,
					title: "View Product",
				},
			},
			{
				path: "/cart",
				name: "Cart",
				component: Cart,
				meta: {
					protected: true,
					title: "Cart",
				},
			},
			{
				path: "/cart/checkout/:orderId",
				name: "CartCheckout",
				component: CartCheckout,
				meta: {
					protected: true,
					title: "Checkout",
				},
			},
			{
				path: "/cart/status/:status",
				name: "CartStatus",
				component: CartStatus,
				meta: {
					protected: true,
					title: "Checkout Status",
				},
			},
			{
				path: "/cart/checkout/reservations",
				name: "CartReservation",
				component: CartReservation,
				meta: {
					protected: true,
				},
			},
			{
				path: "/cart/checkout/reservations/status/:orderId/:status",
				name: "CartReservationStatus",
				component: CartReservationStatus,
				meta: {
					protected: true,
				},
			},
			{
				path: "/account",
				name: "Account",
				component: Account,
				meta: {
					protected: true,
					title: "Account",
				},
			},
			{
				path: "/account/orders/:id",
				name: "ViewOrder",
				component: ViewOrder,
				meta: {
					protected: true,
					title: "View Order",
				},
			},
			{
				path: "/applications/new/:slug",
				name: "ApplicationsNew",
				component: ApplicationsNew,
				meta: {
					protected: true,
					title: "New Loan Application",
				},
			},
			{
				path: "/applications/status",
				name: "ApplicationsStatus",
				component: ApplicationsStatus,
				meta: {
					protected: true,
					title: "Loan Application Status",
				},
			},
			{
				path: "/applications/process",
				name: "ApplicationsProcess",
				component: ApplicationsProcess,
				meta: {
					protected: true,
					title: "Loan Application Process",
				},
			},
			{
				path: "/applications/:orderId",
				name: "ApplicationsView",
				component: ApplicationsView,
				meta: {
					protected: true,
					title: "View Loan Application",
				},
			},
			{
				path: "/faq",
				name: "FAQ",
				component: FAQ,
				meta: {
					protected: false,
					title: "Frequently Asked Questions",
				},
			},
			{
				path: "/terms",
				name: "TermsAndConditions",
				component: TermsAndConditions,
				meta: {
					protected: false,
					title: "Terms and Conditions",
				},
			},
			{
				path: "/about",
				name: "About",
				component: About,
				meta: {
					protected: false,
					title: "About Us",
				},
			},
			// {
			// 	path: "/rewards",
			// 	name: "Rewards",
			// 	component: Rewards,
			// 	meta: {
			// 		protected: false,
			// 		title: "Rewards",
			// 	},
			// },
			{
				path: "/404",
				component: PageNotFound,
				meta: {
					protected: false,
					title: "Page Not Found",
				},
			},
		],
	},
	{ path: "*", redirect: "/404" },  
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	parseQuery(query) {
		return queryString.parse(query, { arrayFormat: "bracket" });
	},
	stringifyQuery(query) {
		let result = queryString.stringify(query, {
			arrayFormat: "bracket",
		});

		return result ? "?" + result : "";
	},
});

export default router;
