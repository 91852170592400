<template>
    <b-modal
        ref="ecqPopupModal"
        title="Delivery Notice"
        header-bg-variant="warning"
        ok-only
        no-close-on-backdrop
        @shown="startTimer"
        @hide="setPopupDone"
    >
        <div>
            <p>Hi, ka-Transcycleista!</p>
            <p>Due to the implementation of ECQ regulations in Metro Manila and neighboring areas, the delivery schedule of your purchases may take longer.</p>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { api } from"../../config"
export default {
    data() {
        return {
            ecqPopupLocalStorageKey: 'ecqPopup',
            popupTimeout: 15000,
            popupActive: false,
            subscribe: null
        }
    },
    computed: {
        ...mapGetters('UserLocation', ['currentLocation','isEcqCustomer'])
    },
    async mounted() {
        const self = this
        await this.setEcqPopupActive()

        this.unsubscribe = this.$store.subscribe((mutation) => {
            if(mutation.type == 'UserLocation/SET_CURRENTLOCATION' && mutation.payload && this.popupActive) {
                self.validateEcqLocation(self.validateEcqLocationCallback)
            }
        })
        
    },
    methods: {
        ...mapActions({
            validateEcqLocation: 'UserLocation/validateEcqLocation'
        }),
        async setEcqPopupActive() {
            try {
                const request = await fetch(`${api}/setup/DLVRY_ECQ_NOTICE`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                })
    
                let response = await request.json();
                
                this.popupActive = parseInt(response.data.value) === 1 && localStorage.getItem(this.ecqPopupLocalStorageKey) != 1
            } catch (error) {
                console.log(error);
            }
        },
        startTimer() {
            const self = this
            setTimeout(() => {
                self.$refs.ecqPopupModal.hide()
            }, this.popupTimeout)
        },
        setPopupDone() {
            localStorage.setItem(this.ecqPopupLocalStorageKey, 1)
            if(this.unsubscribe) this.unsubscribe()
        },
        validateEcqLocationCallback() {
            if(this.isEcqCustomer) this.$refs.ecqPopupModal.show()
        }
    }
}
</script>