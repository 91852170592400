var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',{attrs:{"role":"main"}},[_c('section',{staticClass:"products pt-4 pb-4"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('p',[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("STEP 2: Register your new Transcycle Online account or Login "),(_vm.loggedIn)?_c('router-link',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"to":"/products/motorcycles"}},[_vm._v("here")]):_c('router-link',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"to":"/login"}},[_vm._v("here")]),_vm._v(". ")],1)]),_c('p',[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("STEP 3: Choose your Motorcycle from our selection of products and add to cart, "),_c('router-link',{staticStyle:{"color":"blue","text-decoration":"underline"},attrs:{"to":"/products/motorcycles"}},[_vm._v("see what's new")]),_vm._v(". ")],1)]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_c('div',{staticClass:"col-md-2 offset-md-5 text-center"},[_c('p',[_c('b-button',{staticClass:"text-uppercase",attrs:{"squared":"","size":"sm","variant":"danger","to":"/products/motorcycles"}},[_vm._v("SHOP NOW")])],1)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('span',{staticStyle:{"font-size":"15pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("How to Apply for a Motorcycle Loan Online")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Are you thinking about applying for a motorcycle loan with Transcycle for your website purchase? Easy!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Just follow the steps below:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("STEP 1: Make sure that you have digital or scanned copies of the following ready!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ml-5"},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("1. Valid ID – Driver’s License, Voter’s ID, Passport, SSS ID, or UMID, and the like")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ml-5"},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("2. Proof of Income – One (1) Month Pay Slip, Certificate of Employment with Compensation, or Latest ITR or Bank Statement, and the like")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ml-5"},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("3. Proof of Billing – Latest Electric Bill, Latest Telephone or Internet Bill, Latest Water Bill")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"ml-5"},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("4. Latest Photo – 2 inch by 2 inch Photo with White Background")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("STEP 4: In Select Payment Method, choose “Apply for Installment”.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"13pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"600","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("STEP 5: Fill up the application information needed, and upload the required documents.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("Read and if you agree, click on the consent box of our Data Privacy Act and Implementing Rules and Regulations policies.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pb-3"},[_c('span',{staticStyle:{"font-size":"11pt","font-family":"Calibri,sans-serif","color":"#000000","background-color":"transparent","font-weight":"400","font-style":"normal","font-variant":"normal","text-decoration":"none","vertical-align":"baseline","white-space":"pre-wrap"}},[_vm._v("You’re done applying! Just wait for 1 to 2 days so we may contact you for further instructions that may be needed for processing and approval.")])])
}]

export { render, staticRenderFns }