<template>
	<div>
		<banner title="Promos"></banner>
		<main>
			<section class="pb-5 pt-3">
				<b-container>
					<b-row>
						<b-col md="3" sm="12">
							<div class="sidebar products-sidebar mb-3">
								<div class="input-group md-form form-sm form-2 pl-0 mb-3 searchbar">
									<input
										class="form-control my-0 py-1 red-border rounded-0"
										type="text"
										placeholder="Search"
										aria-label="Search"
										v-model="filters.term"
									/>
									<div class="input-group-append">
										<span class="input-group-text red lighten-3 rounded-0" id="basic-text1">
											<i class="fas fa-search text-grey" aria-hidden="true"></i>
										</span>
									</div>
								</div>
								<h6 class="text-uppercase mb-3">Filters</h6>
                                <b-form-radio-group
                                    class="mb-3" 
                                    stacked 
                                    v-model="filters.groups"
                                    :options="groups"
                                />
								<h6 class="text-uppercase mb-3" v-if="filters.groups == 'Motorcycles'" >Brands</h6>
								<b-form-checkbox-group stacked v-model="filters.manufacturers" :options="manufacturers" v-if="filters.groups == 'Motorcycles'" />
								<b-button mb-3 block variant="danger" @click="clearFilters" style="margin-top: 20px;">Clear All</b-button>
							</div>
						</b-col>
						<b-col md="9" sm="12">
							<b-row>
                                <b-col col cols="12" md="8" />
                                <b-col col cols="12" md="4">
                                    <sortBy v-model="filters.replica"></sortBy>
                                </b-col>
							</b-row>

							<productList class="mt-4" :loading="searching" :records="hits"> </productList>
							
							<b-row>
								<b-col>
									<b-pagination :total-rows="pagination.totalRows" :items="pagination.items" :per-page="filters.hitsPerPage" v-model="filters.page" />
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-container>
			</section>
		</main>
	</div>
</template>

<script>
import {api} from '@/config.js'
import banner from '@/components/ProductBanner'
import productList from './Lists'
import search from '@/libraries/Search'
import sortBy from "./SortByField";
import _ from "lodash";

export default {
	components: {
		banner,
		productList,
        sortBy
	},
	data() {
		let filters = search.setDefaultFilter(this.getDefaultFilters())

		return {
			searching: false,
			manufacturers: [],
			emptySearch: true,
			departmentId: 7, //for Promos
			searchText: null,
			emptySearchMessage: null,
			hits: [],
			pagination: {
				totalRows: null,
				items: []
			},
			groups: [],
			sideBarGroups: [],
			filters
		}
	},
    computed: {
		searchFilters() {
			let filters = {...this.filters}
			return search.getURLString(filters)
		}
	},
    watch: {
		filters: {
			handler(value) {
				search.setURLParameters(value)
				this.doSearch()
			},
			deep: true
		},
		"filters.manufacturers": {
			handler(value) {
				this.getModels(value)
			}
		},
	},
	async mounted() {
		let self = this
		this.checkEnforceUrl();
		this.doSearch()
        this.loadGroups()
		await this.getManufacturers();

		window.onpopstate = function() { self.doSearch() }
	},
    methods: {
        getDefaultFilters() {
            return {
                page: 1,
                hitsPerPage: 20,
                replica: 'products_timestamp_desc',
                departments: 'Promos',
                groups: 'Parts & Accessories',
                term: null,
				manufacturers: null,
            }
        },
		async checkEnforceUrl() {
			if(location.search) location.replace(location.href.replace(/\?.+/, ""));
		},
		async getManufacturers() {
			let request = await fetch(
				`${api}/products/manufacturers?department=Motorcycles`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			let responseBody = await request.json();
			this.manufacturers = responseBody.map((rec) => rec.manufacturer);
		},
        async doSearch() {
            let self = this
            let filters = this.searchFilters
			if(filters.includes("departments=Promos&groups=Motorcycles")) {
				//console.log(2)
				filters = filters.replace("departments=Promos&groups=Motorcycles","departments=Motorcycles&mcPromos=1")
				filters = filters.replace("&hitsPerPage=20","&hitsPerPage=500")
			} 
			console.log(filters)
            this.searching = true
            fetch(`${api}/products/search?${filters}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async response => {
                let responseBody = await response.json()
                let _tempPageItems = []
                self.hits = responseBody.hits
                self.searching = false
                self.pagination.totalRows = responseBody.nbHits
                for (let index = 0; index < responseBody.nbPages; index++) {
                    _tempPageItems.push(index)
                }
                self.pagination.items = _tempPageItems
            })
        },
        loadGroups() {
			let self = this

			fetch(`${api}/item-groups/reverse?department=${this.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
				}
			}).then(async request => {
				let responseBody = await request.json()
				let data = _.sortBy(responseBody.data[0].itemGroups, ["active"]);
				
                data = data.map(el => {
                    return {
                        text: el.name,
                        value: el.name,
						disabled: !el.active
                    }
                })
                self.groups = [
                    ...data
                ]
			})
		},
        clearFilters() {
			let _tmpFilter = this.getDefaultFilters()
			_tmpFilter.groups = ['clear']

			this.filters = _tmpFilter
		}
    }
}
</script>
