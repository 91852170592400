<template>
	<div>
		<banner title="Reservation" />
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-12">
									<h4>Pay the reservation fee</h4>
								</div>
							</div>
							<div class="row mt-3 mb-3">
								<div class="col-md-4">
									<a href="javascript:void(0)" @click="reserve('paymaya')">
										<img src="/img/paymaya.png" alt="Paymaya" class="img-thumbnail" />
									</a>
								</div>
								<!-- <div class="col-md-4">
                            <a href="javascript:void(0)" @click="reserve('gcash')">
                                <img src="/img/gcash.jpg" alt="Paymaya" class="img-thumbnail">
                            </a>
                        </div>
                        <div class="col-md-4">
                            <a href="javascript:void(0)" @click="reserve('cliqq')">
                                <img src="/img/cliqq.jpg" alt="Paymaya" class="img-thumbnail">
                            </a>
								</div>-->
							</div>
						</div>
						<div class="col-md-6">
							<table class="table">
								<thead>
									<tr>
										<th scope="col" colspan="2">Summary</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Items</td>
										<td>{{entries.length}}</td>
									</tr>
									<tr>
										<td>Reservation Fee</td>
										<td>{{order.LTORegistrationFee | thousandSeparator}}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
import { mapGetters } from "vuex";
export default {
	components: {
		banner
	},
	data: () => ({}),
	computed: {
		...mapGetters({
			user: "user",
			order: "Cart/order",
			entries: "Cart/entries"
		})
	},
	methods: {
		async reserve(mode) {
			switch (mode) {
				case "paymaya": {
					let response = await fetch(
						`https://pg-sandbox.paymaya.com/checkout/v1/checkouts`,
						{
							method: "POST",
							body: JSON.stringify({
								totalAmount: {
									currency: "PHP",
									value: parseFloat(
										this.order.LTORegistrationFee
									)
								},
								items: [
									{
										name: `Reservation Fee for Order# ${this.order.orderId}`,
										quantity: 1,
										totalAmount: {
											value: parseFloat(
												this.order.LTORegistrationFee
											)
										}
									}
								],
								redirectUrl: {
									success:
										window.location.origin +
										"/cart/checkout/reservations/status/" +
										this.order.orderId +
										"/Processing",
									failure:
										window.location.origin +
										"/cart/checkout/reservations/status/" +
										this.order.orderId +
										"/Failed",
									cancel:
										window.location.origin +
										"/cart/checkout/reservations/status/" +
										this.order.orderId +
										"/Cancelled"
								},
								requestReferenceNumber: `RSVN-${this.order.orderId}`,
								metadata: {}
							}),
							headers: {
								"Content-Type": "application/json",
								Authorization:
									"Basic cGstZW80c0wzOTNDV1U1S212ZUpVYVc4VjczMFRUZWkyelk4ekU0ZEhKRHhrRjo="
							}
						}
					);
					let data = await response.json();
					this.paymaya(data);

					break;
				}
			}
		}
	}
};
</script>

<style>
</style>