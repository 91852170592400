<template>
    <div class="mb-5">
        <banner title="Terms and Conditions" />
        <main role="main">
            <div class="products single-product">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12">
                            <!-- <iframe
								src="https://firebasestorage.googleapis.com/v0/b/scgc-transcycle-website-dev.appspot.com/o/TermsandConditionsTranscycleWebsite_June2020.html?alt=media&token=5409f3f9-c75f-4e50-894f-dd3959c22948"
								frameborder="0"
								id="iframe"
                            ></iframe>-->
                            <p>
                                <span id="docs-internal-guid-d755bcc7-7fff-730e-d353-d83d8cc21014"></span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Ecommerce Terms of Use of Website, and Terms and Conditions of Sale</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b
                                    style="font-weight:normal;"
                                    id="docs-internal-guid-bcf009e2-7fff-fcc0-178f-3b29f3da68fa"
                                >
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >I. </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >AGREEMENT TO TERMS OF USE OF WEBSITE AND TERMS AND CONDITIONS OF SALE</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >These Terms of Use of Website and Terms and Conditions of Sale constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and&nbsp;TRANSCYCLE&nbsp;(“we,” “us” or “our”), concerning your access to and use of the</span>
                                <a href="https://transcycle.com.ph/" style="text-decoration:none;">
                                    <span
                                        style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:underline;-webkit-text-decoration-skip:none;text-decoration-skip-ink:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                    >Transcycle Marketplace</span>
                                </a>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp;website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >By accessing the Site you agree that you have read, understood, and agree to be bound by all of these Terms of Use. If you do not agree with all these Terms of Use, then you are expressly prohibited from using the Site and must discontinue your use immediately.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <br />
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >It is your responsibility to periodically review these Terms of Use to stay informed on updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Use of the Services is limited to authorized Customers who are of legal age and who have the legal capacity to enter into and form contracts under any applicable law. Customers who have breached or are in breach of the terms and conditions contained herein and Customers who have been permanently or temporarily suspended from use of any of our Services may not use or register for this Site.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >II. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >INTELLECTUAL PROPERTY RIGHTS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the Republic of the Philippines, foreign jurisdictions, and international conventions.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >III. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >USER REPRESENTATIONS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >By using the Site , you represent and warrant that:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(1) all registration information you submit will be true, accurate, current, and complete;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(2) you will maintain the accuracy of such information and promptly update such registration information as necessary;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(3) you have the legal capacity and you agree to comply with these Terms of Use as well as with the Terms of Sale;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(4) you will not use the Site for any illegal or unauthorized purpose;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(5) your use of the Site will not violate any applicable law or regulation.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Site (or any portion thereof).</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >IV. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >USER REGISTRATION</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You may be required to register with the Site. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >V. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >PRODUCTS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the Site. However, we do not guarantee that the colors, features, specifications, and details of the products will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >All products are subject to availability&nbsp;and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change without prior notice.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >VI. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >PROHIBITED ACTIVITIES</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >As a user of the Site, you agree not to:</span>
                            </p>
                            <ol style="margin-top:0;margin-bottom:0;">
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >use a buying agent or purchasing agent to make purchases on the Site;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >use the Site to advertise or offer to sell goods and services;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >engage in unauthorized framing of or linking to the Site;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >make improper use of our support services or submit false reports of abuse or misconduct;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >attempt to impersonate another user or person or use the username of another user;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Site to you;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, CSS, JavaScript, or other code;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, malwares, ransomwares, or other material that interferes with any party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Site;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;margin-left: -3.8000000000000007pt;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >use the Site in a manner inconsistent with any applicable laws or regulations.</span>
                                    </p>
                                </li>
                            </ol>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >VII. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >GUIDELINES FOR REVIEWS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We may provide you areas on the Site to leave reviews or ratings. When posting a review, you must comply with the following criteria:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(1) you should have firsthand experience with the person/entity being reviewed;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hate language;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(4) your reviews should not contain references to illegal activity;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(5) you should not be affiliated with competitors if posting negative reviews;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(6) you should not make any conclusions as to the legality of conduct;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(7) you may not post any false or misleading statements;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully-paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >VIII. </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <span class="Apple-tab-span" style="white-space:pre;"></span>
                                </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >MOBILE APPLICATION LICENSE</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Use License</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If you access the Site via a mobile application, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the mobile application on wireless electronic devices owned or controlled by you, and to access and use the mobile application on such devices strictly in accordance with the terms and conditions of this mobile application license contained in these Terms of Use.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You shall not:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(1) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the application;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the application;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(3) violate any applicable laws, rules, or regulations in connection with your access or use of the application;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(4) remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark) posted by us or the licensors of the application;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(5) use the application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(6) make the application available over a network or other environments permitting access or use by multiple devices or users at the same time;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(7) use the application for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the application;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(8) use the application to send automated queries to any website or to send any unsolicited commercial e-mail;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(9) use any proprietary information or any of our interfaces or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the application.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >IX. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >THIRD-PARTY WEBSITES AND CONTENT</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >The Site may contain (or you may be sent via the Site) links to other websites (“Third-Party Websites”) as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties (“Third-Party Content”).</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by us, and we are not responsible for any Third Party Websites accessed through the Site or any Third-Party Content posted on, available through, or installed from the Site, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Inclusion of, linking to, or permitting the use or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Site and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk, and you should be aware these Terms of Use no longer govern.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Site or relating to any applications you use or install from the Site. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us harmless from any harm caused by your purchase of such products or services. Additionally, you shall hold us harmless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >X. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >ADVERTISERS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We allow advertisers to display their advertisements and other information in certain areas of the Site, such as sidebar advertisements or banner advertisements. If you are an advertiser, you shall take full responsibility for any advertisements you place on the Site and any services provided on the Site or products sold through those advertisements.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Further, as an advertiser, you warrant and represent that you possess all rights and authority to place advertisements on the Site, including, but not limited to, intellectual property rights, publicity rights, and contractual rights.&nbsp;We simply provide the space to place such advertisements, and we have no other relationship with advertisers.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XI.</span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >SITE MANAGEMENT</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We reserve the right to:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(1) monitor the Site for violations of these Terms of Use;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms of Use, including without limitation, reporting such user to law enforcement authorities;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(4) in&nbsp;our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >(5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the proper functioning of the Site.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XII.</span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >TERMS OF THE SALE</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >A. Ordering</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >a. In placing your Order, you must only submit to us information that is accurate and true. You must also keep your information up to date by informing us of any changes, by using the relevant sections of the Site.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >b. You can also create an account on our website provided that you abide by the rules in using our website.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >c. A username and password may be selected for the privacy and security of your account. We recommend you not to share your user credentials with anyone.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >d. An order can only be executed using your user credentials, after confirmation of your registration using your indicated email address.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >e. As part of our functions and in order to provide you with better services, we will collect the following personal information from you specifically:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-left: 99.25pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Name</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Billing and Delivery Address</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-left: 99.25pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Email Address</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-left: 99.25pt;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Mobile Number</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >f. By completing and submitting your purchase order online, you are making an offer to purchase goods, which, if accepted by us, will result in a binding contract.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >g. Products that you purchased through credit payment are subject for approval until we have authorization from your payment card issuer; for PayCash, until your payment is settled via bank and Bayad Centers. We will not be liable if there is a delay in order, and we will not accept your order if payment is not settled or authorized to prevent fraudulent transactions.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >h. We do not have to accept your order, and for example, we will not accept your order if:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <ul style="margin-top:0;margin-bottom:0;">
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >We do not have the products in stock.</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Your payment card issuer does not authorize your payment.</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >You have incomplete details in submitting your order form.</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >There is an error on our website regarding the price or other details of the products and you refuse to abide and pay the current or right price.</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Your billing address is not within the areas of the Company’s operations.</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >You have cancelled it in accordance with the instructions &amp; terms below.</span>
                                    </p>
                                </li>
                            </ul>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >B. Formation of a Contract</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >a. No Contract in respect of any Products shall exist between you and us until we have delivered</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp;the Products to you.</span>
                            </p>
                            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >b. To submit an Order, you will be required to follow the online shopping process on the Site. After this you will receive an Order Confirmation through mail and text which will act as an acknowledgement of your Order.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >c. An Order is only considered accepted by us upon your Order being shipped to the delivery address you provided or once payment for your Order has been confirmed by us.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >d. A Contract will relate only to those Products which we have shipped or delivered to you. If your Order consists of more than one Product, the Products may be delivered to you in separate packages at separate times.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >C. Price and Payment</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >In placing your order, you have to choose the type of payment you are going to use:</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp;</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp; &nbsp;</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >• Installment – our agent, through your email, will send quotation and process of installment.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp; &nbsp; &nbsp;</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >• Cash-over-the-counter – Cash payment in any Transcycle Branch. We will call or text you to confirm order and will proceed to the reservation of the unit ordered in the nearest branch from your location.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >&nbsp; &nbsp; &nbsp;</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >• Other Payment Channels – Pay via bank and non-bank (e.g. pawnshop, Bayad Center, etc.). We will notify you of our receipt of order and you will be given only a specific period of time to pay through a bank or non-bank payment center using a reference number.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Prices provided in the website are quoted in Philippine Peso, and inclusive of VAT. Additional charge of 3.15% will be added to the price if payment is via a credit card. This fee is inclusive of bank charge and convenience fee. Delivery costs, if any, will also be added to the total price of your purchase.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >The price you pay is the price of the products shown at the time you place your order, even if the price of the product has since changed. However, while we make every effort to ensure that all details, descriptions and prices, which appear on this Site, are accurate, there may be instances where errors may occur. If we discover an error in the price of any Products, which you have ordered, we will inform you of this as soon as possible and give you the option of reconfirming your Order at the correct price or cancelling it and get a refund.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >a. We are under no obligation to fulfil your Order if the price listed on the website is incorrect, even after you receive an Order Confirmation from us.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.295;margin-top:0pt;margin-bottom:8pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >b.&nbsp; Currently, you can pay cash via payment centers (bank or non-bank) or use any Visa or Mastercard credit cards through the integrated Pesopay payment system.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >c. For credit card; upon authorization of the payment, by clicking the “Order Now” button you are confirming that the Visa or Mastercard credit cards you are using belongs to you or that you are the legitimate holder of the promotional voucher, and have sufficient funds or credit facilities to cover the cost of the Product and delivery.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >d. For PayCash; you are confirming that you have sufficient funds to cover the cost upon getting the reference number in ordering online. The reference number used for payment is valid only until the date set as deadline of payment, by respective payment center (bank or non bank).</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >e. Transcycle may use a third party payment processor (Paynamics) to link your payment options (Bancnet Processing, Gcash Processing, Paypal Processing, Over the Counter Payment Processing, Electronic Funds Transfer services, Mobile payment services, Alternative Forms of Payment Services) to the Application and Service. The processing of payments, as applicable, in connection with your use of the Website, Application and Service will be subject to the terms, conditions and privacy policies of the Paynamics and the third-party payment service provider in addition to these User Terms. We will not be responsible for any errors by the Paynamics, or of the third-party payment service providers. In connection with your use of the Services, we will obtain certain transaction details, which we will use solely in accordance with its Privacy Policy.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >D. Product Return and Exchange Policy</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:8pt;padding:0pt 0pt 0pt 45pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Buyer may only apply for the refund and/or exchange of the unit or item under the circumstances.&nbsp;</span>
                            </p>
                            <ol style="margin-top:0;margin-bottom:0;">
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Unit or Item was damaged or defective during delivery;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:8pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Delivered unit or item doesn’t match the ordered specifications</span>
                                    </p>
                                </li>
                            </ol>
                            <p
                                dir="ltr"
                                style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 8pt 45pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Buyer must complete the </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Return/Exchange Request Form</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                > that will be provided by our Customer Support. Please be advised that we will only accept refund and/or exchange of units or items within 7 days of receipt of delivery. Our company, Transcycle, has sole discretion over approval of buyer’s application upon investigation and validation of the request. Buyer will be notified by our customer care agents through email and/or sms on the result of the request.&nbsp;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:8pt;padding:0pt 0pt 0pt 45pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Buyer must return the unit or item with below </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Return criteria:</span>
                            </p>
                            <ol style="margin-top:0;margin-bottom:0;">
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Original condition</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Original packaging</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Original copy of Sales Invoice</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:decimal;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:8pt;padding:0pt 0pt 0pt 45pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Product tag still intact</span>
                                    </p>
                                </li>
                            </ol>
                            <p
                                dir="ltr"
                                style="line-height:1.38;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:8pt;padding:0pt 0pt 0pt 45pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Once a buyer's application for exchange is accepted or approved, we will deliver a new item in exchange for the damaged unit or item within 7 days. In exchanging products, we can only offer a brand new unit or item for the same purchased item. With this, we are not obliged to provide or give you the option to exchange for another unit or item and/or refund.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:#ffffff;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We may charge administrative and handling charges depending on the circumstances of the return. Also, due to hygiene concerns, we do not accept returns or exchanges for our Helmet products.&nbsp;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >E. Refusal of Order</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We reserve the right to withdraw any Product from this Site at any time and/or remove or edit any materials or content on this Site. We will make our best efforts to always process all Orders but there may be exceptional circumstances wherein we may need to refuse to process an Order after we have sent you an Order Confirmation, which we reserve the right to do at any time, at our sole discretion.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If we cancel your Order and you have already made payment for your Order, your payment will be fully refunded to you.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We will not be liable to you or any other third party by reason of our withdrawal of any Product from this Site, whether it has been sold or not, removal or revision of any of the materials or contents on this Site or refusal to process or accept an Order.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >F. REFUND POLICY</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >a.&nbsp; All processed Transcycle Online Payment transactions are only eligible for a refund if you cancel a payment for a justified reason within 48 hours of placing your order.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >b. To request for a refund you must send a letter to us stating the reason for your order’s cancelation.&nbsp; You may contact us through our Customer Support.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >c. If your online order has been paid and you want to cancel it beyond the 48-hour period, (a) we have the sole discretion to cancel your order depending on your reason for cancellation as we strictly follow the NO CANCELLATION OF ORDER POLICY.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >d.&nbsp; If you already paid for your order online and there is no stock available, we will give you the option for an alternate product of equal (but subject to partial and additional payment) to the price you paid or a refund.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >e. In cancelling your order, you also agree to pay the additional charges for the transaction made online, which will be deducted from the total refund.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >f. A total refund is only given if the transaction fails due to:</span>
                            </p>
                            <ul style="margin-top:0;margin-left: 50px !important;margin-bottom:0;">
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Error and failure in our system;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Error in the description of goods and pricing;</span>
                                    </p>
                                </li>
                                <li
                                    dir="ltr"
                                    style="list-style-type:disc;font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;"
                                >
                                    <p
                                        dir="ltr"
                                        style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                                        role="presentation"
                                    >
                                        <span
                                            style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                        >Unavailability of Stock</span>
                                    </p>
                                </li>
                            </ul>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >g. Buyer must complete the </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Refund Request form</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                > that will be provided by our Customer Support.&nbsp;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-left: 36pt;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >G. CLAIMING OF UNIT AND DELIVERY</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >a. As we confirm your payment details, we will refer you to the nearest branch from your location. To claim the unit, you can go to the branch to get the unit yourself or you can request for delivery.</span>
                            </p>
                            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >b. If you choose to have your products delivered at your billing address, you confirm that you are willing to pay the additional cost added to the original price.</span>
                            </p>
                            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >c.&nbsp; We aim to deliver the Product to you at the place of delivery requested by you in your Order and within the delivery period indicated by us in the Order Confirmation.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >d.&nbsp; If the Product you ordered and paid for is not available, we will offer an alternative product or provide you a refund, at your option.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >e. Upon delivery of the Product, you shall be required to inspect the product for latent or obvious defects, and immediately bring the same to the attention of our authorized representative before affixing your signature on the delivery receipt.&nbsp; Otherwise, you are deemed to acknowledge that you have received the Product in good condition. You may contact us in case of any faults, defects, or damage to the Product upon delivery and you shall be required to provide the documents delivered with the Product as well as other details of the Order.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >f. Please note that if it is not possible for us to deliver to some locations in which event, we will inform you using the contact details that you have provided us and arrange for either the cancellation of your Order or its delivery to an alternative delivery address, which you will provide.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >h. All risk in the Product shall pass to you upon delivery, except that, where delivery is delayed due to your breach of your obligations under Contract, risk shall pass at the date when delivery would have occurred if not for your breach. From the time the risk passes to you, we will not be liable for loss or destruction of the Product.&nbsp;</span>
                            </p>
                            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >i. If you are not available to receive the delivery, we may leave a card or send you an email with instructions on the re-delivery of your Order.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >j. If delivery is delayed through your unreasonable refusal to accept delivery or if you do not (within two weeks of our first attempt to deliver the Product to you) accept delivery, we may, without having to effect any other right or remedy available to us, do either or both of the following:</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-left: 36pt;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >• charge you for any fee and other costs reasonably incurred by us; or</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >• no longer make the Product available for delivery or collection and notify you that we are immediately cancelling the applicable Contract, in which case we will refund to you your payment through store credit, as may be applicable, any money already paid to us under the Contract, less our reasonable administration charges which includes the attempt to deliver and the return of the Product, and any other fees and costs we may incur.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p dir="ltr" style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;">
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >k. We shall notify you if we expect that we will be unable to meet our estimated delivery period, but, except to the extent mandated by law, we shall not be liable to you for any losses, liabilities, costs, damages, charges or expenses arising out of late delivery.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >l. You acknowledge that the Products are standard and not made to fit any particular requirements that you may have.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <br />
                                    <br />
                                </span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >H. CANCELLATION BY THE CUSTOMER BEFORE DELIVERY</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >&nbsp;</p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If you wish to cancel your Order before we send you the Order Confirmation, before the Order has been dispatched, please contact us through our </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >E-Commerce Live Chat</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >, which can be found at the lower right corner of our Storefront’s main screen. No Cancellation Fees shall be applicable (four) 4 hours before the confirmed delivery time.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >However, once you have paid already and the unit is ready for dispatch, you cannot cancel your order. We will have the sole discretion if we are going to allow cancellation or not depending on the reason for cancellation.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;background-color:#ffffff;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If a rescheduling is needed, you can contact us through mail or landline or mobile (go to Contact Us Page) 4 hours before the confirmed delivery time and we will assist you the best way we can. No penalties, just appreciation for giving us the heads up.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XIII.</span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >
                                    <span class="Apple-tab-span" style="white-space:pre;"></span>
                                </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >PRIVACY POLICY</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We care about data privacy and security. Please review our Privacy Policy </span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:#ffff00;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                ></span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                ><a href="http://portal.siycha.com.ph/privacy-policy.html" target="_blank">http://portal.siycha.com.ph/privacy-policy.html</a></span>
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                > posted on the Site. By using the Site, you agree to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be advised the Site is hosted in the United States.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XIV.</span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >COPYRIGHT INFRINGEMENTS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We respect the intellectual property rights of others. If you believe that any material available on or through the Site infringes upon any copyright you own or control, please immediately notify us using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XV. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >TERM AND TERMINATION</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE&nbsp;YOUR ACCOUNT AND&nbsp;ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XVI. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >MODIFICATIONS AND INTERRUPTIONS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Site. We also reserve the right to modify or discontinue all or part of the Site without notice at any time.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Site.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time or for any reason without notice to you.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the Site. Nothing in these Terms of Use will be construed to obligate us to maintain and support the Site or to supply any corrections, updates, or releases in connection therewith.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XVII. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >GOVERNING LAW</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >These Terms of Use and your use of the Site are governed by and construed in accordance with the laws of the Republic of the Philippines,&nbsp;applicable to agreements made and to be entirely performed within its jurisdiction.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XVIII. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >CORRECTIONS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >There may be information on the Site that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at any time, without prior notice.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XIX. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >LIMITATIONS OF LIABILITY</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp;</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XX. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >USER DATA</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >We will maintain certain data that you transmit to the Site for the purpose of managing the performance of the Site, as well as data relating to your use of the Site. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Site.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XXI. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:700;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >XXII. </span>
                                <span
                                    style="color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 14.6667px; font-weight: 700; white-space: pre-wrap;"
                                ></span>
                                <span
                                    style="background-color: transparent; color: rgb(0, 0, 0); font-family: Calibri, sans-serif; font-size: 11pt; font-weight: 700; white-space: pre-wrap;"
                                >MISCELLANEOUS</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >These Terms of Use and any policies or operating rules posted by us on the Site or in respect to the Site constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >These Terms of Use operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >If any provision or part of a provision of these Terms of Use is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Terms of Use or use of the Site.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <b style="font-weight:normal;">
                                    <br />
                                </b>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: justify;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <span
                                    style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;"
                                >You agree that these Terms of Use will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.</span>
                            </p>
                            <p
                                dir="ltr"
                                style="line-height:1.2;text-align: center;margin-top:0pt;margin-bottom:0pt;"
                            >
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import banner from '@/components/ProductBanner';
export default {
    components: {
        banner
    }
}
</script>

<style scoped>
.box {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}
iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
