<template>
    <div>
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="5000"
            indicators
            no-hover-pause
        >
            <b-carousel-slide
                v-for="(slide, index) in slides"
                :key="index"
                :img-src="slide.image"
            >
                <template #default>
                    <b-container v-bind:class="(slide.url == '/rewards')?'right_bottom_btns absolute':'absolute'">
                        <b-row>
                            <b-col>
                                <div>
                                    <a v-show="slide.url == '/applications/process'" href="/products/motorcycles" class="btn btn-red rounded-0 text-uppercase mr-2 banner-button1">
                                        Shop Now
                                        <i class="fa fa-angle-right"></i>
                                    </a>
                                    <a v-show="slide.url.length && slide.buttonText.length" :href="slide.url" class="btn btn-red rounded-0 text-uppercase banner-button2">
                                        {{slide.buttonText}}
                                        <i class="fa fa-angle-right"></i>
                                    </a>
                                    <a v-show="slide.url == '/rewards'" href="https://m.me/TranscyclePH" target="_blank" class="btn btn-red rounded-0 text-uppercase banner-button2">
                                        CHAT US
                                        <i class="fa fa-angle-right"></i>
                                    </a>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
import { api } from "../../config";
export default {
    async mounted() {
        await this.getSlides();
    },
    data: () => ({
        slides: [],
        slide: 0,
    }),
    methods: {
        async getSlides() {
            let request = await fetch(`${api}/slides`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            let response = await request.json();
            this.slides = response.slides;
        },
    },
};
</script>

<style scoped>
.slide-title,
.slide-description {
    display: inline-block;
    color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
}
.absolute {
    position: absolute;
    top: 65%;
    text-align: left;
    padding-left: 4%;
}
/* Extra small devices (phones, 368px and down) */
@media only screen and (max-width: 368px) {
  .banner-button1 {
      font-size: 5px;
      font-weight: bold;
      width: 22%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Extra small devices (phones, 368px and down) */
@media only screen and (max-width: 368px) {
  .banner-button2 {
      font-size: 5px;
      font-weight: bold;
      width: 40%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Extra small devices (phones, 368px and up) */
@media only screen and (min-width: 368px) {
  .banner-button1 {
      font-size: 6px;
      font-weight: bold;
      width: 22%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 3% !important;
  }
}
/* Extra small devices (phones, 368px and up) */
@media only screen and (min-width: 368px) {
  .banner-button2 {
      font-size: 6px;
      font-weight: bold;
      width: 40%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 2% !important;
  }
}
/* Extra small devices (phones, 416px and up) */
@media only screen and (min-width: 416px) {
  .banner-button1 {
      font-size: 6.2px;
      font-weight: bold;
      width: 18%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 2% !important;
  }
}
/* Extra small devices (phones, 416px and up) */
@media only screen and (min-width: 416px) {
  .banner-button2 {
      font-size: 6px;
      font-weight: bold;
      width: 29%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 2% !important;
  }
}
/* Extra small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .banner-button1 {
      font-size: 5px;
      font-weight: bold;
      width: 19%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 2% !important;
  }
}
/* Extra small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .banner-button2 {
      font-size: 5px;
      font-weight: bold;
      width: 30%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 2% !important;
  }
}
/* Extra small devices (phones, 440px and up) */
@media only screen and (min-width: 440px) {
  .banner-button1 {
      font-size: 6px;
      font-weight: bold;
      width: 17%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Extra small devices (phones, 440px and up) */
@media only screen and (min-width: 440px) {
  .banner-button2 {
      font-size: 6px;
      font-weight: bold;
      width: 32%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .banner-button1 {
      font-size: 6px;
      font-weight: bold;
      width: 15%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Extra small devices (phones, 500px and up) */
@media only screen and (min-width: 500px) {
  .banner-button2 {
      font-size: 6px;
      font-weight: bold;
      width: 30%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner-button1 {
      font-size: 7px;
      font-weight: bold;
      height: 10%;
      width: 18%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner-button2 {
      font-size: 7px;
      font-weight: bold;
      height: 10%;
      width: 32%;
  }
  .absolute {
    position: absolute;
    top: 75%;
    text-align: left;
    padding-left: 4%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-button1 {
      font-size: 8px;
      font-weight: bold;
      height: 20%;
      width: 18%;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .banner-button2 {
      font-size: 8px;
      font-weight: bold;
      height: 20%;
      width: 30%;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}  

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .banner-button1 {
      font-size: 12px;
      font-weight: bold;
      height: 25%;
      width: 20%;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .banner-button2 {
      font-size: 12px;
      font-weight: bold;
      height: 25%;
      width: 27%;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}  

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .banner-button1 {
    font-size: 2rem;
    font-weight: bold;
    height: 25%;
    width: 23%;
    font-size: 1.2vw;
    width: 16vw;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .banner-button2 {
    font-size: 2rem;
    font-weight: bold;
    height: 25%;
    width: 23%;
    font-size: 1.2vw;
    width: 25vw;
  }
  .absolute {
    position: absolute;
    top: 70%;
    text-align: left;
    padding-left: 4%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 2550px) {
  .absolute {
    max-width: 100%;
  }
}

.right_bottom_btns {
  max-width: 100%;
}

.right_bottom_btns .banner-button2 {
  margin: 15px 0 0 auto;
  display: block;
  color: #1c1978;
  background: #fff000;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1023px) {

  .right_bottom_btns {
    top: 76%;
  }

  .right_bottom_btns .banner-button2 {
    margin: 11px 0 0 auto;
  }
}
@media only screen and (max-width: 992px) {
  .right_bottom_btns .banner-button2 {
      font-size: 9px;
      width: 200px;
  }
}  
@media only screen and (max-width: 768px) {
  .right_bottom_btns {
    top: 80%;
  }
  .right_bottom_btns .banner-button2 {
      margin: 7px 0 0 auto;
      padding: 0.3em;
      width: 110px;
      font-size: 8px;
  }
}

@media only screen and (max-width: 480px) {
  .right_bottom_btns .banner-button2 {
      font-size: 5px;
      font-weight: bold;
      width: 85px;
      padding: 0.2em;
  }
}
</style>
