<template>
	<div>
		<header class="header">
			<div class="top-head">
				<div class="container">
					<div class="row h-100">
						<div class="col-12 col-md-8 top-search text-right my-auto d-inline w-100">
							<form @submit="searchMotorcycles" v-on:submit.prevent>
								<div class="input-group input-group-sm">
									<input
										type="text"
										class="form-control border-top-0 border-left-0 border-right-0 rounded-0 shadow-none"
										placeholder="Search motorcycles"
										v-model="headerSearchterm"
									/>
									<div class="input-group-append">
										<button
											class="btn border-top-0 border-left-0 border-right-0 text-secondary"
											type="submit"
										>
											<i class="fa fa-search"></i>
										</button>
									</div>
								</div>
							</form>
						</div>
						<div class="col-12 col-md-4 top-link my-auto">
							<a class="pl-3" v-if="loggedIn" href="/account">Account</a>
							<router-link class="pl-3" v-else to="/login">Register</router-link>

							<router-link class="pl-3" v-if="loggedIn" to="/logout">Logout</router-link>
							<router-link class="pl-3" v-else to="/login">Login</router-link>

							<router-link class="pl-3" to="/applications/process">Apply for a Loan</router-link>
						</div>
					</div>
				</div>
			</div>
			<nav class="navbar navbar-expand-lg navbar-light">
				<div class="container">
					<router-link class="navbar-brand" to="/home">
						<img src="/img/logo-small.png" srcset="/img/logo-medium.png 1024w, /img/logo-medium.png 1200w, /img/logo.png 1600w" />
					</router-link>
					<button
						class="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav text-uppercase">
							<li class="nav-item">
								<router-link class="nav-link" to="/home">Home</router-link>
							</li>
							<li class="nav-item">
								<router-link class="nav-link" to="/products/motorcycles">Motorcycles</router-link>
							</li>
							<li v-b-hover="handleHover"  class="nav-item">
								<div v-if="isHovered" class="dropdown">
									<b-dropdown @toggle="$router.push('/products/parts&accessories')" id="dropdown-1" ref="dropdown" right text="PARTS & ACCESSORIES">
										<b-dropdown-item v-for="menu in partsMenus" :key="menu">
											<router-link class="nav-link" :to="`/products/parts&accessories/?departments=${menu}`">{{ menu }}</router-link>
										</b-dropdown-item>
									</b-dropdown>
								</div>
								<div v-else>
									<router-link class="nav-link" to="/products/parts&accessories">Parts & Accessories</router-link>
								</div>
							</li>
							<li v-b-hover="handleHover2"  class="nav-item">
								<div v-if="isHovered2" class="dropdown">
									<b-dropdown @toggle="$router.push('/products/promos')" id="dropdown-1" ref="dropdown" right text="Promos">
										<b-dropdown-item
											v-for="menu in promoMenus"
											:key="menu"
											:href="menu === 'Motorcycles' ? 'https://transcycle.promo/' : '#'"
											target="_blank"
										>
										{{ menu }}
										</b-dropdown-item>
									</b-dropdown>
								</div>
								<div v-else>
									<router-link class="nav-link" to="/products/promos">Promos</router-link>
								</div>
							</li>
							<!-- <li class="nav-item">
								<router-link class="nav-link" to="/products/promos">Promos</router-link>
							</li> -->
							<li class="nav-item">
								<a class="nav-link" target="_blank" :href="serviceSchedulerURL">Services</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="/branches">Branches</a>
							</li>
							<li class="nav-item" style="display: flex; justify-content: center;">
								<router-link to="/products/comparison" title="Product comparison">
									<i class="compare-icon"></i>
									<span class="cart-num" id="cart-count">{{ comparison.length }}</span>
								</router-link>

								<router-link to="/cart" title="Cart">
									<i class="cart-icon"></i>
									<span class="cart-num" id="cart-count">{{ entries.length }}</span>
								</router-link>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>

		<router-view :key="$route.fullPath" />

		<section>
			<b-button
				class="openChatButton btn-circle btn-md"
				v-show="!showChatWidget"
				@click="checkChatWidgetVisibility"
				pill
				variant="primary"
			>
				<b-icon-chat-dots></b-icon-chat-dots>
			</b-button>
			<div class="widget" @show="showChatWidget">
				<b-navbar type="dark" variant="primary">
					<b-collapse id="nav-collapse" is-nav>
						<b-navbar-nav>
							<b-nav-text>Live Chat Support</b-nav-text>
						</b-navbar-nav>

						<b-navbar-nav class="ml-auto">
							<b-nav-item active @click="showChatWidget = false">X</b-nav-item>
						</b-navbar-nav>
					</b-collapse>
				</b-navbar>

				<iframe :src="maydayIframeSource" height="370" frameborder="0"></iframe>
			</div>
		</section>

		<footer class="footer ptb-5">
			<div class="container mt-3">
				<div class="row">
					<div class="col-12 col-md-4 footer-about">
						<div class="row">
							<div class="col-md-10 offset-md-0">
								<img class="logo-footer pc" src="/img/logo.png" />
								<img class="logo-footer sp" src="/img/logo-center.png" />
								<p>
									Gentrade International (Phils.) Inc. was incorporated on May 16, 1996 for the
									purpose of selling motorcycle units, spare parts and accessories.
								</p>
							</div>
						</div>
					</div>
					<b-col :cols="12" :sm="12" :md="5" class="footer-contact">
						<div class="font-weight-bold text-center footer-contact-head">
							<p>For more information or assistance, contact us via</p>
						</div>
						<div class="font-weight-normal footer-contact-details">
							<div class="footer-contact-details">
								<div class="footer-contact-details-item">
									<img src="/img/footer-mail.png" alt="footer mail icon">
									<div class="footer-contact-details-item-text">
										<p>
											<!-- <a href="mailto:customersupport@unistarcredit.com.ph">customersupport@unistarcredit.com.ph</a> -->
											<a href="mailto:customersupport@transcycle.com.ph">customersupport@transcycle.com.ph</a>
											
										</p>
									</div>
								</div>
								<div class="footer-contact-details-item">
									<img src="/img/footer-smartphone.png" alt="footer smartphone icon">
									<div class="footer-contact-details-item-text">
										<p>(Globe) <a href="tel:+639178564207">0917 8564207</a> or <a href="tel:+639171525914">0917 1525914</a></p>
										<p>(Smart) <a href="tel:+639988481992">0998 8481992</a> or <a href="tel:+639190625302">0919 0625302</a></p>
									</div>
								</div>
								<div class="footer-contact-details-item">
									<img src="/img/footer-viber.png" alt="footer smartphone icon">
									<div class="footer-contact-details-item-text">
										<p>(Viber) <a href="tel:+639178564207">0917 8564207</a> or <a href="tel:+639171525914">0917 1525914</a> or <a href="tel:+639988481992">0998 8481992</a></p>
									</div>
								</div>
							</div>
						</div>
					</b-col>
					<div class="col-12 col-sm-12 col-md-1 footer-social-media">
						<ul>
							<li>
								<a href="http://www.facebook.com/TranscyclePH/">
									<i class="fab fa-facebook"></i>
								</a>
							</li>
							<li>
								<a href="http://www.instagram.com/transcycleph/">
									<i class="fab fa-instagram"></i>
								</a>
							</li>
							<li>
								<a href="https://www.youtube.com/channel/UCJNsK63UFC1halr61yfhGkw">
									<i class="fab fa-youtube"></i>
								</a>
							</li>
						</ul>
					</div>
					<b-col :cols="12" :sm="12" :md="2" class="footer-nav">
						<ul>
							<li>
								<a href="/about">About us</a>
							</li>
							<li>
								<a href="/faq">FAQs</a>
							</li>
							<li>
								<a href="/terms">Terms and Conditions</a>
							</li>
						</ul>
					</b-col>
				</div>
				<div class="row mt-5 copyright">
					<div class="col-12 col-md-6">
						<p>© 2019. Transcycle. All Rights Reserved.</p>
					</div>
				</div>
			</div>
			<div class="wheel"></div>
		</footer>

		<b-modal
			ref="privacyPolicyModal"
			centered
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			title="Privacy Policy"
		>
			<div class="d-block">
				<p>
					SCGC is committed to safeguard your privacy online. We may collect technical information regarding
					visits to our Web site, such as IP address, browser type, operating system, and length of visit to
					certain pages, to measure use, audit logs and improve the content of our site. We analyze these data
					for trends and statistics to help us provide you with better and secure service. This is statistical
					data about our users’ browsing actions and patterns, and does not identify any individual.
				</p>
				<p>
					We will only request information from you, such as your name, address, contact number, and
					employment/business information in order to respond to inquiry. Any information you provide will be
					used for the company’s marketing and promotional services.
				</p>
				<div class="text-center">
					<b-form-checkbox
						id="checkbox-1"
						v-model="agree"
						name="checkbox-1"
						:value="true"
						:unchecked-value="false"
					>
						I have read and agree to the
						<a
							class="text-underline"
							href="http:///portal.siycha.com.ph/privacy-policy.html"
							target="_blank"
						>Privacy Policy</a>.
					</b-form-checkbox>
					<b-button :disabled="!agree" variant="outline-primary" @click="agreePrivacyPolicy">Continue</b-button>
				</div>
			</div>
		</b-modal>

		<b-modal
			ref="offlineModal"
			centered
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			hide-footer
			title="You are offline"
		>
			<div class="d-block text-center">
				<p>Please check your internet connection</p>
				<div class="mt-3">
					<div class="loadingio-spinner-radio-xx93dqhpell">
						<div class="loadingio-spinner-radio-ullsi3onzr">
							<div class="ldio-3zpoz0tmgnp">
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { serviceSchedulerURL, api, maydayURL } from "../config";

export default {
	data() {
		return {
			partsMenus: ['Parts', 'Engine Oil & Lubricants', 'Helmet', 'Apparel', 'Accessories'],
			promoMenus: ['Motorcycles'],
			agree: false,
			serviceSchedulerURL,
			api,
			showChatWidget: false,
			maydayURL,
			maydayIframeSource: null,
			headerSearchterm: "",
			offlineNoty: null,
			isHovered: false,
			isHovered2: false
		};
	},
	async mounted() {

		this.checkUserPrivacyPolicy();
		this.$store.dispatch("Cart/getComparison");

		if (
			this.loggedIn &&
			this.user.firstname &&
			this.user.lastname &&
			this.user.email &&
			this.user.contactNumber
		) {
			this.maydayIframeSource = `${this.maydayURL}&name=${this.user.firstname}%20${this.user.lastname}&email=${this.user.email}&contactNumber=${this.user.contactNumber}&loggedInStatus=${this.loggedIn}`;
		} else {
			this.maydayIframeSource = `${this.maydayURL}&liveChatReset=${this.liveChatReset}`;
		}
	},
	computed: {
		// map `this.user` to `this.$store.getters.user`
		...mapGetters({
			loggedIn: "loggedIn",
			liveChatReset: "liveChatReset",
			comparison: "Cart/comparison",
			entries: "Cart/entries",
		}),
		...mapState({
			user: "user",
			expirationTime: "expirationTime"
		}),
	},
	methods: {
		async checkUserPrivacyPolicy() {
			let browserPrivacyPolicy = parseInt(
				localStorage.getItem("privacyPolicy")
			);
			if (
				!this.loggedIn &&
				(isNaN(browserPrivacyPolicy) || browserPrivacyPolicy === 0)
			) {
				this.$refs.privacyPolicyModal.show();
			}
		},
		async agreePrivacyPolicy() {
			if (this.loggedIn) {
				await this.$store.dispatch("setUserPrivacyPolicy", 1);
				this.$refs.privacyPolicyModal.hide();
			} else {
				localStorage.setItem("privacyPolicy", 1);
				this.$refs.privacyPolicyModal.hide();
			}
		},
		checkChatWidgetVisibility() {
			this.showChatWidget = true;
		},
		async searchMotorcycles() {
			this.$router.push(
				`/products/motorcycles?categories&departments=Motorcycles&hitsPerPage=20&manufacturers&page=1&price&replica=products_timestamp_desc&term=${this.headerSearchterm}`
			);
		},
		handleHover(hovered) {
			this.isHovered = hovered
			if(this.isHovered == true) this.$refs.dropdown.show(true)
			else this.$refs.dropdown.show(false)
		},
		handleHover2(hovered) {
			this.isHovered2 = hovered
			if(this.isHovered == true) this.$refs.dropdown.show(true)
			else this.$refs.dropdown.show(false)
		},
	},
	watch: {
		loggedIn(value) {
			if (value) {
				//this.$store.dispatch("Cart/getOrder");
			}
		},
		user(value) {
			if (
				this.loggedIn &&
				value &&
				value.hasOwnProperty("privacyPolicy") &&
				value.privacyPolicy === 0
			) {
				this.$refs.privacyPolicyModal.show();
			} else if (
				this.loggedIn &&
				value &&
				value.hasOwnProperty("privacyPolicy") &&
				value.privacyPolicy === 1
			) {
				this.$refs.privacyPolicyModal.hide();
			}

			if (
				this.loggedIn &&
				value &&
				value.firstname &&
				value.lastname &&
				value.email &&
				value.contactNumber
			) {
				this.maydayIframeSource = `${this.maydayURL}&name=${value.firstname}%20${value.lastname}&email=${value.email}&contactNumber=${value.contactNumber}&loggedInStatus=${this.loggedIn}`;
			}
		},
		showChatWidget(value) {
			if (value) {
				document.querySelector(".widget").style.display = "block";
			} else {
				document.querySelector(".widget").style.display = "none";
			}
		},
		isOnline() {
			if (this.isOnline) {
				this.$refs.offlineModal.hide();
			}
		},
		isOffline() {
			if (this.isOffline) {
				this.$refs.offlineModal.show();
			}
		},
	},
};
</script>

<style>
.partMenu {
	width: 300px !important;
}
.text-underline {
	text-decoration: underline !important;
}
* {
	box-sizing: border-box;
}
.btn-circle.btn-md {
	width: 50px;
	height: 50px;
	padding: 7px 6px;
	border-radius: 25px;
	font-size: 16px;
	text-align: center;
}
.openChatButton {
	position: fixed;
	bottom: 23px;
	right: 28px;
	z-index: 2000;
}
.widget {
	display: none;
	position: fixed;
	bottom: 0;
	right: 28px;
	z-index: 3000;
	height: 420px;
	border: 1px solid #007bff;
}

/* footer */
.footer-contact {
	margin-top: 15px;
}

.footer-contact .footer-contact-head p {
	font-size: 17px;
}

.footer-contact .footer-contact-details .footer-contact-details-item {
	margin-left: 20px;
}

.footer-contact .footer-contact-details .footer-contact-details-item img {
	display: inline-block;
	width: 25px;
	vertical-align: top;
}
.footer-contact .footer-contact-details .footer-contact-details-item .footer-contact-details-item-text {
	margin-left: 15px;
	display: inline-block;
	width: calc(100% - 40px);
	word-break: break-all;
}

@media (max-width: 768px) {
	.footer-contact .footer-contact-details {
		margin: 0 auto;
		width: 360px;
	}

	.footer-contact .footer-contact-details .footer-contact-details-item {
		margin-left: 0;
	}

	.footer-contact .footer-contact-details .footer-contact-details-item .footer-contact-details-item-text {
		width: 320px;
	}

	.footer-social-media ul li a {
		display: block;
		text-align: center;
		margin: 0 auto;
		width: fit-content;
	}
}

@keyframes ldio-3zpoz0tmgnp {
	0% {
		opacity: 0;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
.ldio-3zpoz0tmgnp div {
	position: absolute;
	animation: ldio-3zpoz0tmgnp 1s linear infinite;
	box-sizing: border-box !important;
}
.ldio-3zpoz0tmgnp div:nth-child(1) {
	width: 22px;
	height: 22px;
	left: 17px;
	top: 64px;
	border-radius: 50%;
	background: #6a6a6a;
}
.ldio-3zpoz0tmgnp div:nth-child(2) {
	width: 33px;
	height: 33px;
	left: 28px;
	top: 42px;
	border-radius: 0 33px 0 0;
	border: 10px solid #979797;
	background: none;
	animation-delay: 0.1s;
	border-bottom: 0;
	border-left: 0;
}
.ldio-3zpoz0tmgnp div:nth-child(3) {
	width: 55px;
	height: 55px;
	left: 28px;
	top: 20px;
	border-radius: 0 55px 0 0;
	border: 10px solid #bdbdbd;
	background: none;
	animation-delay: 0.2s;
	border-bottom: 0;
	border-left: 0;
}
.loadingio-spinner-radio-ullsi3onzr {
	width: 100px;
	height: 100px;
	display: inline-block;
	overflow: hidden;
	background: none;
}
.ldio-3zpoz0tmgnp {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(1);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-3zpoz0tmgnp div {
	box-sizing: content-box;
}

.logo-footer.pc {
    display: initial;
}
.logo-footer.sp {
    display: none;
}
@media screen and (max-width: 767px) {
	.logo-footer.pc {
		display: none;
	}
	.logo-footer.sp {
		display: initial;
	}
}
</style>
