<template>
    <div>
        <banner title="Helmets"></banner>
        <main>
             <section class="pb-5 pt-3">
                 <b-container>
                     <b-row>
                         <b-col md="3" sm="12">
                            <div class="sidebar products-sidebar mb-3">
                                <div class="input-group md-form form-sm form-2 pl-0 mb-3 searchbar">
                                    <input
                                    class="form-control my-0 py-1 red-border rounded-0"
                                    type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    v-model="filters.term"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text red lighten-3 rounded-0" id="basic-text1">
                                            <i class="fas fa-search text-grey" aria-hidden="true"></i>
                                        </span>
                                    </div>
                                </div>
                                <b-card no-body >
                                    <b-card-header>
                                        <b-row>
                                            <b-col col md="9">Brands</b-col>
                                            <b-col col md="1">
                                                <b-button 
                                                    pill light 
                                                    size="sm" 
                                                    variant="outline-secondary" 
                                                    v-b-toggle="'accordion-oil'"
                                                >
                                                    <b-icon icon="chevron-down" />
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-card-header>
                                    <b-collapse 
                                        visible accordion="oil-accordion" 
                                        role="tabpanel"
                                        id="accordion-oil"
                                    >
                                        <b-form-checkbox-group class="m-3" stacked :options="brandOptions" v-model="filters.manufacturers" />
                                    </b-collapse>
                                </b-card>
                                <b-button block variant="danger" @click="clearFilters">Clear All</b-button>
                            </div>
                         </b-col>

                         <b-col md="9" sm="12">
                            <b-row>
                                <b-col col cols="12" md="8">
                                    <b-nav pills>
                                        <b-nav-item 
                                            :active="filters.groups === null"
                                            @click="filters.groups = null">All</b-nav-item
                                        >
                                        <b-nav-item
                                            v-for="group in itemGroups"
                                            :key="group.id"
                                            :active="filters.groups === group.name"
                                            @click="filters.groups = group.name"
                                        >{{ group.name }}</b-nav-item>
                                    </b-nav>
                                </b-col>
                                <b-col col cols="12" md="4">
                                    <sortBy v-model="filters.replica"></sortBy>
                                </b-col>
                            </b-row>

                            <productList class="mt-4" :loading="searching" :records="hits"> </productList>
                            
                            <b-row>
                                <b-col>
                                    <b-pagination :total-rows="pagination.totalRows" :items="pagination.items" :per-page="filters.hitsPerPage" v-model="filters.page" />
                                </b-col>
                            </b-row>
                         </b-col>
                     </b-row>
                 </b-container>
             </section>
        </main>
    </div>
</template>

<script>
import _ from 'lodash'
import {api} from '@/config.js'
import banner from '@/components/ProductBanner'
import productList from './Lists'
import sortBy from './SortByField'
import search from '@/libraries/Search'

export default {
    components: {
        banner,
        productList,
        sortBy
    },
    data(){
        let filters = search.setDefaultFilter(this.getDefaultFilters())

        return {
            searching: false,
            searchText: null,
            departmentId: 4,
            brandOptions: [],
            itemGroups: [],
            hits: [],
            pagination: {
				totalRows: null,
				items: []
			},
            filters
        }
    },
    computed: {
		requestFilters() {
			return search.getURLString(this.filters)
		}
    },
    watch: {
		filters: {
			handler(value) {
				search.setURLParameters(value)
				this.doSearch()
			},
			deep: true
		}
	},
    mounted() {
        let self = this
        this.getBrands()
        this.getGroupings()
        this.doSearch()

        window.onpopstate = function() { self.doSearch() }
    },
    methods: {
        getDefaultFilters() {
			return {
				page: 1,
				hitsPerPage: 20,
				replica: 'products_timestamp_desc',
				departments: 'Helmet',
                manufacturers: null,
				groups: null,
				term: null,
			}
		},
        doSearch() {
			let self = this
			let filters = this.requestFilters
			this.searching = true

			fetch(`${api}/products/search?${filters}`, {
				headers: {
					"Content-Type": "application/json",
				},
			}).then(async response => {
				let responseBody = await response.json()
				let _tempPageItems = []
				self.hits = responseBody.hits
				self.searching = false
				self.pagination.totalRows = responseBody.nbHits
				for (let index = 0; index < responseBody.nbPages; index++) {
					_tempPageItems.push(index)
				}
				self.pagination.items = _tempPageItems
			})
		},
        getBrands(){
            let self = this

            fetch(`${api}/parts/distinct/brand?department=${self.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
                }
            }).then(async request => {
                let responseBody = await request.json()
                let brands = responseBody.data.map(rec => rec.DISTINCT)
                _.remove(brands, rec => rec === "")
                self.brandOptions = brands
            })
        },
        getModels(){
            let self = this

            fetch(`${api}/parts/distinct/model?department=${self.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
                }
            }).then(async request => {
                let responseBody = await request.json()
                self.modelOptions = _.compact(responseBody.data.map(rec => rec.DISTINCT))
            })
        },
        getGroupings() {
            let self = this

            fetch(`${api}/item-groups/reverse?department=${this.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
                }
            }).then(async request => {
                let responseBody = await request.json()
                self.itemGroups = responseBody.data[0].itemGroups
            })
        },
        clearFilters() {
            this.filters = this.getDefaultFilters()
        }
    }
}
</script>