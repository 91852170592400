<template>
	<b-form @submit="addAddress" novalidate v-on:submit.prevent>
		<div class="row">
			<div class="col-md-12 col-sm-12">
				<div class="row">
					<div class="form-group col-md-6">
						<label>Region</label>

						<select v-model.trim.lazy="$v.region.$model" class="form-control">
							<option v-for="r in regions" :key="r" :selected="region === r" :value="r">{{ r }}</option>
						</select>
						<small class="error text-danger" v-if="!$v.region.required">Region is required</small>
					</div>

					<div class="form-group col-md-6">
						<label>Province</label>

						<select v-model.trim.lazy="$v.province.$model" class="form-control">
							<option v-for="p in provinces" :key="p" :selected="province === p" :value="p">
								{{ p }}
							</option>
						</select>
						<small class="error text-danger" v-if="!$v.province.required">Province is required</small>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md-6">
						<label>City</label>

						<select v-model.trim.lazy="$v.city.$model" class="form-control">
							<option v-for="c in cities" :key="c" :selected="city === c" :value="c">{{ c }}</option>
						</select>
						<small class="error text-danger" v-if="!$v.city.required">City is required</small>
					</div>

					<div class="form-group col-md-6">
						<label>Barangay</label>

						<select v-model.trim.lazy="$v.barangay.$model" class="form-control">
							<option v-for="b in barangays" :key="b" :selected="barangay === b" :value="b">
								{{ b }}
							</option>
						</select>
						<small class="error text-danger" v-if="!$v.barangay.required">Barangay is required</small>
					</div>
				</div>

				<div class="form-group">
					<label>Street Address</label>
					<textarea class="form-control" v-model.trim.lazy="$v.streetAddress.$model"></textarea>
					<small class="error text-danger" v-if="!$v.streetAddress.required"
						>Street Address is required</small
					>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 text-center">
				<button
					type="submit"
					class="btn btn-primary"
					:disabled="submitStatus === 'PENDING' || $v.$invalid"
				>
					Submit
				</button>
			</div>
		</div>
	</b-form>
</template>

<script>
import { api } from "../../config";
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
	data: () => ({
		uid: "",
		userId: "",
		streetAddress: "",
		barangay: "",
		city: "",
		province: "",
		region: "",
		submitStatus: null,
		//regions: ["NCR"]
		regions: ["NCR","09","11"]
	}),
	computed: {
		...mapGetters({
			user: "user",
			// regions: "Setup/regions",
			provinces: "Setup/provinces",
			cities: "Setup/cities",
			barangays: "Setup/barangays",
		}),
	},
	mounted() {
		this.uid = this.user.uid;
		this.userId = this.user.userId;
        this.streetAddress = this.user.secondaryAddress.streetAddress;
		this.barangay = this.user.secondaryAddress.barangay;
		this.city = this.user.secondaryAddress.city;
		this.province = this.user.secondaryAddress.province;
		this.region = this.user.secondaryAddress.region;
	},
	methods: {
		async addAddress() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "PENDING";

				await fetch(`${api}/users/add-address`, {
					method: "POST",
					body: JSON.stringify({
						userId: this.userId,
						streetAddress: this.streetAddress,
						barangay: this.barangay,
						city: this.city,
						province: this.province,
						region: this.region,
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`,
					},
				});

				this.submitStatus = "OK";

				await this.$store.dispatch("fetchUser", this.user);
				await this.$store.dispatch("Cart/getOrder");
				this.$eventBus.$emit("hideAddAddressModal");
				this.$eventBus.$emit("changeDeliveryAddress",`${this.streetAddress}, ${this.barangay} ${this.city} ${this.province} ${this.region}`, this.region);
				// window.location.reload();
			}
		},
	},
	validations: {
		streetAddress: {
			required,
		},
		barangay: {
			required,
		},
		city: {
			required,
		},
		province: {
			required,
		},
		region: {
			required,
		},
	},
	watch: {
		region(value) {
			if (value) {
				this.$store.dispatch("Setup/getProvinces", {
					region: value,
				});
			}
		},
		province(value) {
			if (value) {
				this.$store.dispatch("Setup/getCities", {
					region: this.region,
					province: value,
				});
			}
		},
		city(value) {
			if (value) {
				this.$store.dispatch("Setup/getBarangays", {
					region: this.region,
					province: this.province,
					city: this.city,
				});
			}
		},
	},
};
</script>

<style></style>
