<template>
    <div class="mb-5">
        <banner title="Branches"/>
        <main role="main">
            <div class="products single-product pt-5">
                <b-container>
                    <b-row class="mt-5">
                        <b-col cols="4">
                            <div class="row">
                                <!-- <select v-model="selectedGeo" class="form-control">
                                    <option
                                        v-for="(value, key) in geos"
                                        :key="key"
                                        :value="value"
                                    >{{ value }}</option>
                                </select>-->
                                <!-- <select v-model="selectedRegion" class="form-control">
                                    <option
                                        v-for="(value, key) in regions"
                                        :key="key"
                                        :value="value"
                                    >{{ value }}</option>
                                </select> -->
                                <div class="search-bar-container">
                                    <input type="text" class="form-control" v-model="query" @input="updateSearch" @keyup.enter="updateQuery" placeholder="Search...">
                                    <button @click="clearSearch" v-if="query.length > 0">&#x00d7;</button>
                                    <!-- <ul v-if="showSuggestions">
                                        <li v-for="result in searchResults" :key="result.id" @click="selectResult(result)">
                                            {{ result.name }}
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                            <b-row class="mt-3">
                                <div class="row noResults" :class="{ 'hide': branches.length !== 0, 'init-hide': initHide }">
                                    <p>No Branches Found on searched term <span class="text-info">"{{ queryTextEnter }}"</span></p>
                                </div>
                                <b-col cols="12" role="tablist" class="branches">
                                    <b-card
                                        no-body
                                        class="mb-1"
                                        v-for="(branch, index) in branches"
                                        :key="index"
                                    >
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button
                                                block
                                                v-b-toggle="`accordion-${index}`"
                                                variant="default"
                                                :data-branch-name=branch.name
                                                @click="handleMarkerClick(branch)"
                                            >
                                                {{ branch.readableName }}
                                            </b-button>
                                        </b-card-header>
                                        <b-collapse
                                            :id="`accordion-${index}`"
                                            :visible="index === 0"
                                            accordion="my-accordion"
                                            role="tabpanel"
                                        >
                                            <b-card-body>
                                                <b-card-text>{{ branch.address }}</b-card-text>
                                                <b-card-text class="txt-sm">
                                                    <div v-if="branch.branchSchedule">
                                                        <b>Branch Schedule:</b> {{ branch.branchSchedule }} 
                                                    </div>  
                                                    <div v-if="branch.branchContactNo">
                                                        <b>Branch Contact Number:</b> 
                                                        {{  branch.branchContactNo  }}
                                                    </div>  
                                                </b-card-text>
                                                <b-card-text class="txt-sm">
                                                    <b>CUSTOMER HOTLINE NUMBERS:</b><br/>
                                                    09178564207 / 09171525914<br/>
                                                    09988481992 / 09190625302<br/>
                                                    <span style="    max-width: calc(100% - 25px); display: inline-block;">
                                                        Monday - Saturday, 7:30 AM - 4:30 PM
                                                    </span>
                                                </b-card-text>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="8" ref="mapbox">
                            <GmapMap
                                v-if="ready"
                                :center="{
									lat: parseFloat(branches[0].latitude),
									lng: parseFloat(branches[0].longitude),
								}"
                                :zoom="11"
                                map-type-id="roadmap"
                                :style="`width: ${mapboxWidth}px; height: 600px`"
                            >
                                <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in branches"
                                    :position="
										google &&
											new google.maps.LatLng(parseFloat(m.latitude), parseFloat(m.longitude))
									"
                                    :clickable="true"
                                    @click="handleMarkerClick(m)"
                                />
                            </GmapMap>
                        </b-col>

                        <b-modal ref="locationModal" centered hide-footer no-close-on-backdrop no-close-on-esc hide-header-close title="Location is required">
                            <div class="d-block text-center">
                                <p>
                                    We would like to ask you to grant us your location permission to accurately show our branches' location.
                                </p>
                            </div>
                        </b-modal>
                    </b-row>
                </b-container>
            </div>
        </main>
    </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import banner from '@/components/ProductBanner';
import { mapGetters, mapActions } from "vuex";
import { toArabic } from "roman-numerals";
import { split, replace, sortBy } from "lodash";

export default {
    components: {
        banner
    },
    data: () => ({
        selectedGeo: null,
        selectedRegion: null,
        geos: [],
        branches: [],
        regions: [],
        ready: false,
        mapboxWidth: 0,
        
        query: '',
        queryTextEnter: '',
        initHide: true,
        searchResults: [],
        showSuggestions: false,
        debounceTimer: null,
    }),
    mounted() {
        this.initMountedLogic();
    },
    // async mounted() {
    //     this.unsubscribe = this.$store.subscribe((mutation) => {
	// 		if(mutation.type == 'Setup/SET_REGIONAL_BRANCHES' && mutation.payload) {
    //             let regions = Object.keys(mutation.payload).map(el => {
    //                 let rn = split(replace(el, '-', ' '), ' ', 2)
    //                 let val = 0
    //                 try {
    //                     if(rn[0] === 'ARMM') val = -3
    //                     else if(rn[0] === 'CAR') val = -2
    //                     else if(rn[0] === 'NCR') val = -1
    //                     else val = toArabic(rn[1])
    //                 } catch (error) {
    //                     val = 0
    //                 }
                    
    //                 return {
    //                     name: el,
    //                     val
    //                 }
    //             });
                
    //             // this.regions = sortBy(regions, ['val']).map(el => el.name)
    //             this.regions = sortBy(regions.filter(el => typeof el.val === 'number' && !isNaN(el.val)), ['val']).map(el => el.name);
    //             this.selectedRegion = "NCR - NATIONAL CAPITAL REGION";
    //             // console.log(regions);
    //         }
	// 	});
    //     await this.getGroupedBranches_new( this.query );
        
    //     this.mapboxWidth = this.$refs.mapbox.clientWidth;

    //     let self = this;
	// 	navigator.permissions
    //     .query({ name: "geolocation" })
    //     .then(function(result) {
    //         result.onchange = async () => {
    //             self.$refs.locationModal.hide();
    //             self.$router.go()
    //         };
    //     });
    // },
    methods: {
        fetchGeographicalBranches(geo) {
            console.log('fetchGeographicalBranches');
            this.branches = this.geographicalBranches[geo];
            if (this.branches.length) {
                this.ready = true;
            }
            // console.log(this.branches);
        },
        fetchRegionalBranches(region) {
            console.log('fetchRegionalBranches');
            // console.log(region);
            // this.branches = this.regionalBranches[region];
            this.branches = this.regionalBranches[region];
            
            if (this.branches.length) {
                this.ready = true;
                this.initHide = false;
            }
            // console.log(this.branches);
        },
        ...mapActions({
            getGroupedBranches_new: "Setup/defaultGroupedBranches_new",
        }),
        updateSearch() {
            console.log( 'Searching for: ' + this.query )
        },
        
        clearSearch() {
        // This method is called when the "Clear" button is clicked
            this.query = '';
            
            this.ready = false;
            this.getGroupedBranches_new( '' )
            
            // Clear previous timeout to restart the debounce timer
            clearTimeout(this.debounceTimer);

            // Set a new timeout to execute the API call after 3 seconds of idle time
            this.debounceTimer = setTimeout(() => {
                this.initMountedLogic();
                this.fetchRegionalBranches("NCR - NATIONAL CAPITAL REGION");
            }, 1000); // 3000 milliseconds (3 seconds)
        },
        updateQuery() {
            // Fetch suggestions based on the current query
            // For example, you can make an API call here
            // and update the searchResults array.
            // For simplicity, let's assume searchResults is an array of objects with a 'name' property.
            // this.searchResults = this.fetchSuggestions(this.query);
            // this.showSuggestions = this.query.length > 0;
            
            this.ready = false;
            this.queryTextEnter = this.query;
            this.getGroupedBranches_new( this.query )
            
            // Clear previous timeout to restart the debounce timer
            clearTimeout(this.debounceTimer);

            // Set a new timeout to execute the API call after 3 seconds of idle time
            this.debounceTimer = setTimeout(() => {
                this.initMountedLogic();
                this.fetchRegionalBranches("NCR - NATIONAL CAPITAL REGION");
            }, 1000); // 3000 milliseconds (3 seconds)
        },
        handleMarkerClick(marker) {
            // Handle marker click, for example, log the name
            console.log(marker.readableName);
            this.query = marker.readableName;
            this.ready = false;
            this.getGroupedBranches_new( marker.readableName );
            
            // Clear previous timeout to restart the debounce timer
            clearTimeout(this.debounceTimer);

            // Set a new timeout to execute the API call after 3 seconds of idle time
            this.debounceTimer = setTimeout(() => {
                this.initMountedLogic();
                this.fetchRegionalBranches("NCR - NATIONAL CAPITAL REGION");
            }, 1000); // 3500 milliseconds (3.5 seconds)
        },
        async initMountedLogic() {
            this.unsubscribe = this.$store.subscribe((mutation) => {
                if (mutation.type == 'Setup/SET_REGIONAL_BRANCHES' && mutation.payload) {
                let regions = Object.keys(mutation.payload).map((el) => {
                    let rn = split(replace(el, '-', ' '), ' ', 2);
                    let val = 0;
                    try {
                    if (rn[0] === 'ARMM') val = -3;
                    else if (rn[0] === 'CAR') val = -2;
                    else if (rn[0] === 'NCR') val = -1;
                    else val = toArabic(rn[1]);
                    } catch (error) {
                    val = 0;
                    }

                    return {
                    name: el,
                    val,
                    };
                });

                this.regions = sortBy(regions.filter((el) => typeof el.val === 'number' && !isNaN(el.val)), ['val']).map((el) => el.name);
                this.selectedRegion = 'NCR - NATIONAL CAPITAL REGION';
                }
            });

            await this.getGroupedBranches_new(this.query);

            this.mapboxWidth = this.$refs.mapbox.clientWidth;

            let self = this;
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                result.onchange = async () => {
                self.$refs.locationModal.hide();
                self.$router.go();
                };
            });
            
            if (this.branches.length) {
                this.ready = true;
            }
        },
    },
    computed: {
        google: gmapApi,
        ...mapGetters({
            geographicalBranches: "Setup/geographicalBranches",
            regionalBranches: "Setup/regionalBranches",
        }),
    },
    watch: {
        geographicalBranches(value) {
            let list = Object.keys(value);

            list.forEach((geo) => {
                switch (geo) {
                    case "NCR":
                        this.geos[0] = geo;
                        break;
                    case "Luzon":
                        this.geos[1] = geo;
                        break;
                    case "Visayas":
                        this.geos[2] = geo;
                        break;
                    case "Mindanao":
                        this.geos[3] = geo;
                        break;
                }
            });
            this.selectedGeo = this.geos.length ? this.geos[0] : null;
        },
        selectedGeo(value) {
            this.fetchGeographicalBranches(value);
        },
        selectedRegion(value) {
            this.fetchRegionalBranches(value);
        },
    },
};
</script>

<style scoped>
.branches {
    height: 550px;
    overflow-y: scroll;
}
.search-bar-container {
  margin: 0 auto;
  padding-right: 20px;
  position: relative;
  width: calc(100% - 30px);
}

.search-bar-container button {
    position: absolute;
    right: 30px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background: transparent;
}
.noResults {
  margin: 0 auto;
  padding-right: 20px;
  position: relative;
  width: calc(100% - 40px);
}
.noResults.hide,
.noResults.init-hide {
  display: none;
}
</style>
