<template>
    <div class="mb-5">
        <banner title="Page not Found" />
        <main role="main">
            <div class="products single-product">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12 col-md-8 offset-md-2 text-center">
							<p class="lead">The page you're looking for might have been removed, had it's name changed or is temporarily unavailable.</p>
							<div>
								<b-button
										class="rounded-0 text-uppercase"
										variant="danger"
										to="/home"
									>Go to Homepage</b-button>
							</div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	}
}
</script>