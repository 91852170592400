import _ from "lodash";
import { api } from "../config";
import geography from "../philippine_provinces_cities_municipalities_and_barangays_2019v2.json";
export const Setup = {
	namespaced: true,
	state: {
		branches: [],
		regionalBranches: [],
		geographicalBranches: [],
		nearestBranch: {},
		nearestBranchOnDeliveryAddress: {},
		regions: [],
		provinces: [],
		cities: [],
		barangays: [],
		secondaryRegions: [],
		secondaryProvinces: [],
		secondaryCities: [],
		secondaryBarangays: [],
	},
	mutations: {
		SET_BRANCHES(state, branches) {
			state.branches = branches;
		},
		SET_REGIONAL_BRANCHES(state, branches) {
			state.regionalBranches = branches;
		},
		SET_GEOGRAPHICAL_BRANCHES(state, branches) {
			state.geographicalBranches = branches;
		},
		SET_NEAREST_BRANCH(state, nearestBranch) {
			state.nearestBranch = nearestBranch;
		},
		SET_NEAREST_BRANCH_DELIVERY_ADDRESS(state, nearestBranchOnDeliveryAddress) {
			state.nearestBranchOnDeliveryAddress = nearestBranchOnDeliveryAddress;
		},
		SET_REGIONS(state, regions) {
			state.regions = regions;
		},
		SET_PROVINCES(state, provinces) {
			state.provinces = provinces;
		},
		SET_CITIES(state, cities) {
			state.cities = cities;
		},
		SET_BARANGAYS(state, barangays) {
			state.barangays = barangays;
		},
		SET_SECONDARY_REGIONS(state, secondaryRegions) {
			state.secondaryRegions = secondaryRegions;
		},
		SET_SECONDARY_PROVINCES(state, secondaryProvinces) {
			state.secondaryProvinces = secondaryProvinces;
		},
		SET_SECONDARY_CITIES(state, secondaryCities) {
			state.secondaryCities = secondaryCities;
		},
		SET_SECONDARY_BARANGAYS(state, secondaryBarangays) {
			state.secondaryBarangays = secondaryBarangays;
		},
	},
	actions: {
		// async getGroupedBranches({ commit }) {
		// 	let request = await fetch(`${api}/branches/grouped`, {
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 		},
		// 	});
		// 	let response = await request.json();

		// 	navigator.geolocation.getCurrentPosition(function(location) {
		// 		let list = [];
		// 		for (let i = 0; i < response.branches.length; i++) {
		// 			let branch = response.branches[i];
		// 			branch.distance = getDistanceFromLatLonInKm(
		// 				location.coords.latitude,
		// 				location.coords.longitude,
		// 				response.branches[i].latitude,
		// 				response.branches[i].longitude
		// 			);
		// 			list.push(branch);
		// 		}
		// 		let branches = _.orderBy(list, ["distance"], ["asc"]);
		// 		commit("SET_BRANCHES", branches);
		// 		commit("SET_REGIONAL_BRANCHES", response.regionalBranches);
		// 		commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
		// 		commit("SET_NEAREST_BRANCH", branches[0]);
		// 	});
		// },
		// async defaultGroupedBranches({ commit }) {
		// 	let request = await fetch(`${api}/branches/grouped`, {
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 		},
		// 	});
		// 	let response = await request.json();

		// 	const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
		// 	const hasPermission = permissionStatus?.state // Dynamic value

		// 	//console.log(hasPermission);
			
		// 	if(hasPermission=='granted') {
		// 		/* geolocation is available */
		// 		navigator.geolocation.getCurrentPosition(function(location) {
	
		// 			/* geolocation IS NOT available */
		// 			let list = [];
		// 			for (let i = 0; i < response.branches.length; i++) {
		// 				let branch = response.branches[i];
		// 				branch.distance = getDistanceFromLatLonInKm(
		// 					location.coords.latitude,
		// 					location.coords.longitude,
		// 					response.branches[i].latitude,
		// 					response.branches[i].longitude
		// 				);
		// 				list.push(branch);
		// 			}
		// 			let branches = _.orderBy(list, ["distance"], ["asc"]);
		// 			commit("SET_BRANCHES", branches);
		// 			commit("SET_REGIONAL_BRANCHES", response.regionalBranches);
		// 			commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
		// 			commit("SET_NEAREST_BRANCH", branches[0]);
					
		// 			//console.log(location.coords.latitude);
		// 			//console.log(location.coords.longitude);
		// 		});
		// 	} else {

		// 		//TCM PASIG Cordinates
		// 		let coords_latitude = 14.56045000;
		// 		let coords_longitude = 121.08090000;

		// 		/* geolocation IS NOT available */
		// 		let list = [];
		// 		for (let i = 0; i < response.branches.length; i++) {
		// 			let branch = response.branches[i];
		// 			branch.distance = getDistanceFromLatLonInKm(
		// 				coords_latitude,
		// 				coords_longitude,
		// 				response.branches[i].latitude,
		// 				response.branches[i].longitude
		// 			);
		// 			list.push(branch);
		// 		}
		// 		let branches = _.orderBy(list, ["distance"], ["asc"]);
		// 		commit("SET_BRANCHES", branches);
		// 		commit("SET_REGIONAL_BRANCHES", response.regionalBranches);
		// 		commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
		// 		commit("SET_NEAREST_BRANCH", branches[0]);
				
		// 		//console.log(coords_latitude);
		// 		//console.log(coords_longitude);
		// 	}
		// },
		async getGroupedBranches_new({ commit }, searchQuery) {
			console.log('Received payload:', searchQuery);
			let request = await fetch(`${api}/branches/groupedName?name=${searchQuery}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			let response = await request.json();

			navigator.geolocation.getCurrentPosition(function(location) {
				let list = [];
				for (let i = 0; i < response.branches.length; i++) {
					let branch = response.branches[i];
					branch.distance = getDistanceFromLatLonInKm(
						location.coords.latitude,
						location.coords.longitude,
						response.branches[i].latitude,
						response.branches[i].longitude
					);
					list.push(branch);
				}
				let branches = _.orderBy(list, ["distance"], ["asc"]);
				let _tempBranches1 = Object.values(response.regionalBranches).reduce((accumulator, currentArray) => {
					// Check if currentArray is an array before concatenating
					if (Array.isArray(currentArray)) {
						return accumulator.concat(currentArray);
					} else {
						console.error('Not an array:', currentArray);
						return accumulator;
					}
				}, []);
				
				let _tempBranches = {
					"NCR - NATIONAL CAPITAL REGION": _tempBranches1.filter(branch => branch.latitude !== null && branch.longitude !== null)
				};
				commit("SET_BRANCHES", branches);
				commit("SET_REGIONAL_BRANCHES", _tempBranches);
				// commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
				commit("SET_NEAREST_BRANCH", branches[0]);
			});
		},
		async defaultGroupedBranches_new({ commit }, searchQuery) {
			console.log('Received payload:', searchQuery);
			let request = await fetch(`${api}/branches/groupedName?name=${searchQuery}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			let response = await request.json();

			const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'})
			const hasPermission = permissionStatus?.state // Dynamic value

			//console.log(hasPermission);
			console.log(response);
			
			if(hasPermission=='granted') {
				/* geolocation is available */
				navigator.geolocation.getCurrentPosition(function(location) {
	
					/* geolocation IS NOT available */
					let list = [];
					for (let i = 0; i < response.branches.length; i++) {
						let branch = response.branches[i];
						branch.distance = getDistanceFromLatLonInKm(
							location.coords.latitude,
							location.coords.longitude,
							response.branches[i].latitude,
							response.branches[i].longitude
						);
						list.push(branch);
					}
					
					let branches = _.orderBy(list, ["distance"], ["asc"]);
					let _tempBranches1 = Object.values(response.regionalBranches).reduce((accumulator, currentArray) => {
						// Check if currentArray is an array before concatenating
						if (Array.isArray(currentArray)) {
							return accumulator.concat(currentArray);
						} else {
							console.error('Not an array:', currentArray);
							return accumulator;
						}
					}, []);
					
					let _tempBranches = {
						"NCR - NATIONAL CAPITAL REGION": _tempBranches1.filter(branch => branch.latitude !== null && branch.longitude !== null)
					};
					commit("SET_BRANCHES", branches);
					commit("SET_REGIONAL_BRANCHES", _tempBranches);
					// commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
					commit("SET_NEAREST_BRANCH", branches[0]);
					
					// console.log(response.regionalBranches);
					//console.log(location.coords.latitude);
					//console.log(location.coords.longitude);
				});
			} else {

				//TCM PASIG Cordinates
				let coords_latitude = 14.56045000;
				let coords_longitude = 121.08090000;

				/* geolocation IS NOT available */
				let list = [];
				for (let i = 0; i < response.branches.length; i++) {
					let branch = response.branches[i];
					branch.distance = getDistanceFromLatLonInKm(
						coords_latitude,
						coords_longitude,
						response.branches[i].latitude,
						response.branches[i].longitude
					);
					list.push(branch);
				}
				let branches = _.orderBy(list, ["distance"], ["asc"]);
				let _tempBranches1 = Object.values(response.regionalBranches).reduce((accumulator, currentArray) => {
					// Check if currentArray is an array before concatenating
					if (Array.isArray(currentArray)) {
						return accumulator.concat(currentArray);
					} else {
						console.error('Not an array:', currentArray);
						return accumulator;
					}
				}, []);
				
				let _tempBranches = {
					"NCR - NATIONAL CAPITAL REGION": _tempBranches1.filter(branch => branch.latitude !== null && branch.longitude !== null)
				};
				commit("SET_BRANCHES", branches);
				commit("SET_REGIONAL_BRANCHES", _tempBranches);
				// commit("SET_GEOGRAPHICAL_BRANCHES", response.geographicalBranches);
				commit("SET_NEAREST_BRANCH", branches[0]);
				
				//console.log(coords_latitude);
				//console.log(coords_longitude);
			}
		},
		async getRegions({ commit }) {
			let list = [];
			let regions = Object.keys(geography);
			regions.forEach((region) => {
				list.push(region);
			});
			list.sort((a, b) => parseFloat(a) - parseFloat(b));
			commit("SET_REGIONS", list);
		},
		async getProvinces({ commit }, payload) {
			commit("SET_PROVINCES", Object.keys(geography[payload.region].province_list));
		},
		async getCities({ commit }, payload) {
			commit(
				"SET_CITIES",
				Object.keys(geography[payload.region].province_list[payload.province].municipality_list)
			);
		},
		async getBarangays({ commit }, payload) {
			let list = [];
			let barangays =
				geography[payload.region].province_list[payload.province].municipality_list[payload.city].barangay_list;
			barangays.forEach((barangay) => {
				list.push(barangay);
			});

			commit("SET_BARANGAYS", list);
		},
		async getSecondaryRegions({ commit }) {
			let list = [];
			let regions = Object.keys(geography);
			regions.forEach((region) => {
				list.push(region);
			});
			list.sort((a, b) => parseFloat(a) - parseFloat(b));
			commit("SET_SECONDARY_REGIONS", list);
		},
		async getSecondaryProvinces({ commit }, payload) {
			commit("SET_SECONDARY_PROVINCES", Object.keys(geography[payload.region].province_list));
		},
		async getSecondaryCities({ commit }, payload) {
			commit(
				"SET_SECONDARY_CITIES",
				Object.keys(geography[payload.region].province_list[payload.province].municipality_list)
			);
		},
		async getSecondaryBarangays({ commit }, payload) {
			let list = [];
			let barangays =
				geography[payload.region].province_list[payload.province].municipality_list[payload.city].barangay_list;
			barangays.forEach((barangay) => {
				list.push(barangay);
			});

			commit("SET_SECONDARY_BARANGAYS", list);
		},
		// /**
		//  *
		//  *
		//  * @param {deliveryAddress, branches}
		//  * deliveryAddress: LatLong of delivery address
		//  * branches: Array of branches
		//  */
		async getNearestBranch({ commit }, payload) {
			console.log(payload)
			let branches = payload.map(rec => {
				rec.distance = getDistanceFromLatLonInKm(
					payload.lat,
					payload.long,
					rec.latitude,
					rec.longitude
				)
				return rec
			})

			// let branchIds = branches.map((b) => b.id);
			// let itemIds = payload.entries.map((i) => i.objectID);
			
			// let request = await fetch(`${api}/branches/stocks/${branchIds}/${itemIds}`, {
			// 	headers: {
			// 		"Content-Type": "application/json",
			// 	},
			// });
			// let response = await request.json();


			branches = _.orderBy(branches, ["distance"], ["asc"])
			commit("SET_NEAREST_BRANCH_DELIVERY_ADDRESS", branches.length > 0 ? branches[0] : { uid: null, name: null });
		}
	},
	getters: {
		branches: (state) => state.branches,
		regionalBranches: (state) => state.regionalBranches,
		geographicalBranches: (state) => state.geographicalBranches,
		nearestBranch: (state) => state.nearestBranch,
		nearestBranchOnDeliveryAddress: (state) => state.nearestBranchOnDeliveryAddress,
		regions: (state) => state.regions,
		provinces: (state) => state.provinces,
		cities: (state) => state.cities,
		barangays: (state) => state.barangays,
		secondaryRegions: (state) => state.secondaryRegions,
		secondaryProvinces: (state) => state.secondaryProvinces,
		secondaryCities: (state) => state.secondaryCities,
		secondaryBarangays: (state) => state.secondaryBarangays,
	},
};

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
	let R = 6371; // Radius of the earth in km
	let dLat = deg2rad(Number(lat2) - Number(lat1)); // deg2rad below
	let dLon = deg2rad(Number(lon2) - Number(lon1));
	let a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let d = R * c; // Distance in km
	return d;
}

function deg2rad(deg) {
	return deg * (Math.PI / 180);
}
