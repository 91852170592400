d<template>
	<div>
		<banner title="Payment" />
		<main role="main">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="offset-md-3 col-md-6 text-center" v-if="ready">
							<div v-if="payments.length">
								<div v-if="payments[0].type === 'Full Payment'">
									<div v-if="payments[0].status === 'Completed'">
										<p v-if="order.shippingMethod === 'pickup'">{{pickupNote}}</p>
										<p v-if="order.shippingMethod === 'delivery'">{{deliveryNote}}</p>
									</div>

									<div v-if="payments[0].status === 'Pending'">
										<p>{{pendingNote}}</p>
									</div>
								</div>
								<div v-if="payments[0].type === 'Downpayment'">
									<div v-if="order.downpaymentMethod === 'Cash over the Counter'">
										<p>{{cashOverTheCounterNote}}</p>
										<div class="p-3 bg-warning text-dark">
											Please settle payment at your selected branch within 48 hours to avoid a delay in item delivery.
										</div>
									</div>

									<div v-if="order.downpaymentMethod === 'Pay Online' && payments[0].status === 'Completed'">
										<p v-if="order.shippingMethod === 'pickup'">{{pickupNote}}</p>
										<p v-if="order.shippingMethod === 'delivery'">{{deliveryNote}}</p>
									</div>

									<div v-if="payments[0].status === 'Pending' && order.downpaymentMethod === 'Pay Online'">
										<p>{{pendingNote}}</p>
									</div>
								</div>
							</div>
							<div v-else>
								<p>{{pendingNote}}</p>
							</div>

							<hr />
							<router-link
								class="btn btn-primary"
								:to="`/account/orders/${orderId}`"
								role="button"
							>View order</router-link>
						</div>
						<div class="offset-md-3 col-md-6 text-center" v-else>
							<div class="col-12 text-center my-5">
								<img src="/img/spinner.gif" height="128" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "../../config";
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	},
	data: () => ({
		status: null,
		orderId: null,
		payments: [],
		ready: false,
		order: {},
		entries: [],
		deliveryNote:
			"We received your order, ka-Transcycleista! We’re getting your order ready and will let you know once it’s on its way. We wish you enjoy shopping with us and hope to see you again real soon!",
		pickupNote:
			"We received your order, ka-Transcycleista! We’re getting your order ready and will let you know once it’s available for pick-up. We wish you enjoy shopping with us and hope to see you again real soon!",
		pendingNote:
			"Please settle your payment through the selected payment channel. A Reference Number was provided on screen and a payment instruction (with the same Reference Number) was sent via email. To track your order, simply go to your Account and select Orders tab. Thank you.",
		cashOverTheCounterNote: `You have successfully placed your order! Click "View Order" button to check your Order ID no. and present it to the branch for verification of your payment.`,
	}),
	computed: {
		...mapGetters({
			user: "user",
		}),
	},
	async mounted() {
		this.status = this.$route.params.status;
		this.orderId = this.$route.query.orderId;

		await this.getOrder(this.orderId);
		await this.getOrderEntries(this.orderId);
	},

	methods: {
		async getPayment(id) {
			let request = await fetch(`${api}/payments/all/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.payments = await request.json();
			this.payments.reverse();
			console.log(this.payments[0].status);

			console.log(this.payments[0].type);
			this.ready = true;
		},
		async getOrder(id) {
			let request = await fetch(`${api}/order/explicit/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.order = await request.json();
		},
		async getOrderEntries(id) {
			let request = await fetch(`${api}/order/entries/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			let response = await request.json();

			this.entries = response.data

			setTimeout(async () => {
				await this.getPayment(this.orderId);
			}, 3000);
		},
	},
};
</script>

<style></style>
