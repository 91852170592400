<template>
	<div>
		<banner title="Cart" />
		<main role="main">
			<section class="products ptb-5">
				<div class="container">
					<div class="row mb-5" v-if="ready && entries.length">
						<div class="col-md-12">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">&nbsp;</th>
										<th scope="col">Item</th>
										<th scope="col">Price</th>
										<th scope="col">Quantity</th>
										<th scope="col">Total</th>
										<th scope="col">&nbsp;</th>
										<th scope="col">&nbsp;</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="entry in entries" :key="entry.uid">
										<td>
											<img :src="entry.photo" :alt="entry.name" height="50" />
										</td>
										<td>
											<a :href="`/products/${entry.product}`">{{ entry.name }}</a>
										</td>
										<td>{{ entry.price | amountThousandSeparator }}</td>
										<td>
											<div v-if="!entriesReady && activeEntry.uid === entry.uid">
												<img src="/img/spinner.gif" height="32" />
											</div>
											<div v-else>
												<button class="btn btn-sm" @click="updateEntryQuantity(entry, 'decrease')">-</button>
												<span :id="entry.uid">{{ parseInt(entry.quantity) }}</span>
												<button class="btn btn-sm" @click="updateEntryQuantity(entry, 'increase')">+</button>
											</div>
										</td>
										<td>{{ (entry.price - entry.discount) * entry.quantity | amountThousandSeparator }}</td>
										<td>
											<button class="btn btn-sm" @click="addToWishlist(entry)">
												<i class="far fa-heart text-danger"></i>
											</button>
										</td>
										<td>
											<button class="btn btn-sm" @click="removeEntry(entry)">X</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row" v-if="!ready">
						<div class="col-12 text-center my-5">
							<img src="/img/spinner.gif" height="128" />
						</div>
					</div>
					<div class="col-12 text-center my-5" v-if="setChargesReady===0 && order.shippingMethod">
						<b-icon icon="arrow-clockwise" animation="spin-pulse" font-scale="2" />Loading...
					</div>
					<div class="row" v-show="entries.length">
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-12">
									<b-list-group>
											<!-- 
											:active="completeProfile && order.shippingMethod === 'delivery'" @click="checkShippingMethod('delivery')" class="flex-column align-items-start" :disabled="!completeProfile || !deliveryAllowed"-->
										<b-list-group-item
											href="javascript:void(0)" :active="completeProfile && order.shippingMethod === 'delivery' && deliveryLocationCovered" :disabled="!completeProfile ||  !deliveryLocationCovered || !deliveryAllowed" @click="checkShippingMethod('delivery','btn')"
										>
											<div class="d-flex w-100 justify-content-between">
												<h5 class="mb-1">
													<span v-if="deliveryAllowed && deliveryLocationCovered">{{ allowedDeliveryTitle }}</span>
													<span v-else>{{ unallowedDeliveryTitle }}</span>
												</h5>
											</div>

											<small>
												<span v-if="deliveryAllowed && deliveryLocationCovered">{{ allowedDeliveryDescription }}</span>
												<span v-else-if="deliveryAllowed && !deliveryLocationCovered">
													Delivery option is available within NCR only. If you wish to update your delivery address, please click "Account" in the upper portion of the screen, then click "Edit" to update your address information, then click "Submit" to save your changes. Once submitted, go back to your cart to proceed to Checkout.
												</span>
												<span v-else-if="!deliveryAllowed && deliveryLocationCovered">{{ unallowedDeliveryDescription }}</span>
												<span
													v-if="
													deliveryAllowed &&
														order.shippingMethod === 'delivery' &&
															order.deliveryDateStart &&
															order.deliveryDateEnd
													"
												>
													If payment is settled today, you will receive your product 
													between {{ order.deliveryDateStart | readableMonthDay }} 
													and {{ order.deliveryDateEnd | readableMonthDay }} 
													at
												</span>
											</small>

											<b-form-group v-if="deliveryAllowed && order.shippingMethod === 'delivery' && secondaryAddress !== ''">
												<div>
													<input type="radio" name="radio" style="vertical-align: middle" v-model="selectedDeliveryAddress" ref="selectedDeliveryAddress" 
													:value="primaryAddress"/>
													<span class="pl-1">{{ primaryAddress }}</span>
												</div>

												<div>
													<input type="radio" 
													name="radio" style="vertical-align: middle" v-model="selectedDeliveryAddress" ref="selectedDeliveryAddress" :value="secondaryAddress"
													/>
													<span class="pl-1">
														{{ secondaryAddress }}
														<i class="fa fa-pen" v-b-tooltip.hover title="Edit address" @click="$refs.addAddressModal.show()"></i>
													</span>
												</div>
												<div>
													<span class="pl-4"><small>This address is editable</small></span>
												</div>
											</b-form-group>
											
											<p v-else-if="deliveryAllowed && secondaryAddress === '' &&order.shippingMethod === 'delivery'">
												{{this.user.streetAddress}}, {{this.user.barangay}} {{this.user.city}} {{this.user.province}} {{this.user.region}}
												<br>
											</p>
											<b-form-checkbox v-if="deliveryAllowed && secondaryAddress === '' && order.shippingMethod === 'delivery'" ref="addNewAddress" v-model="addNewAddress" @click="$refs.addAddressModal.show()">
												<small>I want my product to be delivered to a different address</small>
											</b-form-checkbox>

											<!-- <small v-if="!deliveryLocationCovered  && deliveryAllowed">
												<br />
												<b>Delivery option is available within NCR only. If you wish to update your delivery address, please click "Account" in the upper portion of the screen, then click "Edit" to update your address information, then click "Submit" to save your changes. Once submitted, go back to your cart to proceed to Checkout.</b>
											</small> -->

											<div v-if="order.shippingMethod === 'delivery'" class="mt-3">
												<span>
													Serving Hub:
													<!-- <span v-if="nearestBranch.name">{{ nearestBranch.name }}</span>
													<span style="color: #ffd400; font-weight: 900;" v-else>Not yet available. The hub will be available in your area soon.</span> -->
													<span v-if="hub.readableName">{{ hub.readableName }}</span>
													<span style="color: #ffd400; font-weight: 900;" v-else>Not yet available. The hub will be available in your area soon.</span>
												</span>
											</div>
										</b-list-group-item>

										<b-list-group-item
											href="javascript:void(0)"
											:active="completeProfile && order.shippingMethod === 'pickup'"
											@click="checkShippingMethod('pickup')"
											class="flex-column align-items-start"
											:disabled="!completeProfile"
										>
											<div class="d-flex w-100 justify-content-between">
												<h5 class="mb-1">Pickup</h5>
											</div>

											<small>Get your order from our dedicated store.</small>
										</b-list-group-item>
										<b-list-group-item v-if="showAvailableBranches">
											<small>Choose your prefered store from our designated branches:</small>
											<b-select 
												id="branches"
												v-model="selectedBranch"
												:state="validSelectedBranch"
											>
												<b-form-select-option 
													v-for="branch in branches"
													:key="branch.uid"
													:value="branch"
												>
													{{branch.readableName}}
												</b-form-select-option>
											</b-select>
										</b-list-group-item>
										<b-list-group-item 
											class="text-center"
											v-if="showEcqNotice"
										>
											<p :class="consentBorderClass">
												I acknowledge that due to the implementation of ECQ regulations in Metro Manila and neighboring areas, the delivery schedule of my purchase may be delayed.
											</p>
											<b-form-checkbox
												v-model="acknowledgeEcq"
												:state="acknowledgeEcq"
											>
												Agree and Proceed to Checkout
											</b-form-checkbox>
										</b-list-group-item>
										<b-list-group-item class="text-center" v-if="payInInstallmentAllowed && (entries[0].freebies != null)">
											<p :class="consentBorderClass" style="color: red; font-weight: bold;">
												<span>For Single Order Only:<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('LTO')">Free LTO Registration for Loan Payment<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
											</p>
										</b-list-group-item>
									</b-list-group>

									

									<div class="mt-3" v-if="!completeProfile">
										<span>
											<a
												href="javascript:void(0)"
												@click="$refs.profileModal.show()"
												class="text-danger"
											>Complete</a> first your account information to select shipping method.
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<table class="table">
								<thead>
									<tr>
										<th scope="col" colspan="2">Summary</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Items</td>
										<td class="text-right">{{ entries | totalQuantity }}</td>
									</tr>
									<tr v-if="entries.length===1">
										<td>SRP</td>
										<td class="text-right">{{ entries[0].price | amountThousandSeparator }}</td>
									</tr>
									<tr v-if="partsOnly === false">
										<td>LTO Registration Fee</td>
										<td class="text-right">{{ order.ltoRegistrationFees | amountThousandSeparator }}</td>
									</tr>
									<tr v-if="order.shippingMethod === 'delivery'">
										<td>Delivery Fee</td>
										<td class="text-right">{{ order.shipping | amountThousandSeparator }}</td>
									</tr>
									<tr>
										<td>
											<b>Subtotal</b>
										</td>
										<td class="text-right">
											<b>{{ order.subtotal | amountThousandSeparator }}</b>
										</td>
									</tr>
					
									<tr>
										<td>
											<b>Total</b>
										</td>
										<td class="text-right">
											<b>{{ order.total | currencyDisplay }}</b>
										</td>
									</tr>
								</tbody>
								<tfoot 
									v-show="entries.length && (selectedBranch.uid || (order.shippingMethod === 'delivery' && order.deliveryAddress))"
								>
									<tr>
										<td colspan="2" class="text-right">
											<b-button 
												class="btn btn-red" 
												
												:disabled="syncing || (hasEcqNotice && showEcqNotice && !acknowledgeEcq) || !validSelectedBranch" 
												@click="syncCart()" 
											>
												Proceed to Checkout
											</b-button>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<div class="row" v-show="!entries.length && ready">
						<div class="col-md-12 d-block text-center">
							<p>Your cart is empty.</p>
						</div>
						<featured-products department="Motorcycles" count="4" />
					</div>
				</div>

				<b-modal ref="locationModal" centered hide-footer title="Location is required">
					<div class="d-block text-center">
						<p>
							We would like to ask for your location details to accurately recommend the nearest branch
							for order pickup.
						</p>
					</div>
				</b-modal>
			</section>

			<b-modal size="xl" ref="profileModal" hide-footer title="Complete your Profile to Checkout">
				<div class="d-block">
					<UpdateProfile />
				</div>
			</b-modal>

			<b-modal ref="addAddressModal" hide-footer title="Add Shipping Address">
				<div class="d-block">
					<AddAddress />
				</div>
			</b-modal>
		</main>
	</div>
</template>

<script>
import _ from "lodash";
import axios from 'axios'
import banner from '@/components/ProductBanner';
import { analytics, db } from "../../main";
import { api } from "../../config";
import { mapGetters, mapState, mapActions } from "vuex";
import UpdateProfile from "../../components/Account/Update";
import AddAddress from "../../components/Account/AddAddress";
import FeaturedProducts from "../../components/Home/FeaturedProducts";
import Noty from "noty";
import {includes} from "lodash";

export default {
	data: () => ({
		selectedBranch: {
			uid: null,
		},
		selectedShippingMethod: null,
		voucherCode: null,
		quantity: 0,
		deliveryAddress: null,
		unallowedDeliveryTitle: "Delivery is not available",
		unallowedDeliveryDescription:
			"For delivery, we cannot proceed with the ordering of motorcycle and spare parts at the same time. You can move other products to your wishlist or remove it from the cart to enable delivery option for your selected product.",
		allowedDeliveryTitle: "Delivery",
		allowedDeliveryDescription: "Get your order delivered directly at your door.",
		syncing: false,
		entriesReady: true,
		acknowledgeEcq: false,
		hasEcqNotice: false,
		showModal: false,
		isEcqDeliveryAddress: false,
		addNewAddress: false,
		primaryAddress: "",
		secondaryAddress: "",
		selectedDeliveryAddress: "",
		region: "",
		packagings: [],
		activePackagings: [],
		withPromoPackagings: [],
		itemPackaging: null,
	}),
	computed: {
		...mapGetters({
			user: "user",
			order: "Cart/order",
			entries: "Cart/entries",
			activeEntry: "Cart/activeEntry",
			deliveryAllowed: "Cart/deliveryAllowed",
			deliveryLocationCovered: "Cart/deliveryLocationCovered",
			entriesQuantity: "Cart/entriesQuantity",
			motorcyclesOnly: "Cart/motorcyclesOnly",
			partsOnly: "Cart/partsOnly",
			paymentMethod: "Cart/paymentMethod",
			branches: "Cart/availableBranches",
			ready: "Cart/ready",
			ncrPolygon: "UserLocation/ncrPolygon",
			// nearestBranch: "Setup/nearestBranchOnDeliveryAddress",
			// getDistanceFromHub: "Setup/nearestBranchOnDeliveryAddress"
			nearestBranch: "Cart/nearestBranchOnDeliveryAddress",
			getDistanceFromHub: "Cart/nearestBranchOnDeliveryAddress",
			hub: "Cart/hub",
			setChargesReady: "Cart/chargesReady"
		}),
		totalQuantity() {
			return _.sumBy(this.entries, (item) => {
				return parseFloat(item.quantity);
			});
		},
		payInInstallmentAllowed() {
			const conditions = (
				(this.totalQuantity === 1) &&
				(this.entries.filter((e) => e.department === "Motorcycles").length > 0)
			)
			return conditions
		},
		...mapState(["completeProfile"]),
		deliveryAddressRecord() {
			// const isPrimary = (this.primaryAddress == this.selectedDeliveryAddress) || this.user.secondaryAddress == null
			// const addrRecord = {
			// 	streetAddress: isPrimary ? this.user.streetAddress : this.user.secondaryAddress.streetAddress,
			// 	barangay: isPrimary ? this.user.barangay : this.user.secondaryAddress.barangay,
			// 	city: isPrimary ? this.user.city : this.user.secondaryAddress.city,
			// 	province: isPrimary ? this.user.province : this.user.secondaryAddress.province,
			// 	region: isPrimary ? this.user.region : this.user.secondaryAddress.region
			// }
			let addrRecord = {}
			if (this.selectedDeliveryAddress === this.primaryAddress) {
				addrRecord = {
					streetAddress: this.user.streetAddress,
					barangay: this.user.barangay,
					city: this.user.city,
					province: this.user.province,
					region: this.user.region
				}
			} else if (this.selectedDeliveryAddress === this.secondaryAddress) {
				addrRecord = {
					streetAddress: this.user.secondaryAddress.streetAddress,
					barangay: this.user.secondaryAddress.barangay,
					city: this.user.secondaryAddress.city,
					province: this.user.secondaryAddress.province,
					region: this.user.secondaryAddress.region
				}
			}
			return addrRecord
		},
		showAvailableBranches() {
			const conditions = [
				this.motorcyclesOnly && this.order.shippingMethod === 'pickup',
				this.partsOnly && this.order.shippingMethod === 'pickup',
				!this.motorcyclesOnly && !this.partsOnly && this.order.shippingMethod === 'pickup',
				this.partsOnly && this.order.shippingMethod === 'pickup'
			]

			return includes(conditions, true)
		},
		showAddAddress() {
			const conditions = [
				this.motorcyclesOnly && this.order.shippingMethod === 'pickup',
				!this.motorcyclesOnly && !this.partsOnly && this.order.shippingMethod === 'pickup',
				this.partsOnly && this.order.shippingMethod === 'pickup'
			]

			return includes(conditions, true)
		},
		validSelectedBranch() {
			return (this.selectedBranch.uid != null && this.showAvailableBranches) || 
				this.order.shippingMethod === 'delivery' &&  this.hub.readableName != null
		},
		consentBorderClass() {
			let cls = 'text-body'
			if(!this.acknowledgeEcq) {
				cls = 'font-weight-bold text-danger'
			}
			return cls
		},
		showProceedToCheckout() {
			const conditions = [
				this.validSelectedBranch,
				this.motorcyclesOnly,
				this.partsOnly && this.order.shippingMethod === 'delivery'
			]

			return includes(conditions, true)
		},
		showEcqNotice() {
			return this.hasEcqNotice && this.order.shippingMethod === 'delivery' && this.isEcqDeliveryAddress
		},
	},
	async mounted() {
		this.$eventBus.$on("changeDeliveryAddress", (data,region) => {
			this.primaryAddress = `${this.user.streetAddress}, ${this.user.barangay} ${this.user.city} ${this.user.province} ${this.user.region}`
			this.selectedDeliveryAddress = data
			this.secondaryAddress = data
			this.region = region
			setTimeout(() => {
				const isPrimary = (this.primaryAddress == this.selectedDeliveryAddress) || this.user.secondaryAddress == null
				const addrRecord = {
					streetAddress: isPrimary ? this.user.streetAddress : this.user.secondaryAddress.streetAddress,
					barangay: isPrimary ? this.user.barangay : this.user.secondaryAddress.barangay,
					city: isPrimary ? this.user.city : this.user.secondaryAddress.city,
					province: isPrimary ? this.user.province : this.user.secondaryAddress.province,
					region: isPrimary ? this.user.region : this.user.secondaryAddress.region
				}
				// let addrRecord = {}
				// if (this.selectedDeliveryAddress === this.primaryAddress) {
				// 	addrRecord = {
				// 		streetAddress: this.user.streetAddress,
				// 		barangay: this.user.barangay,
				// 		city: this.user.city,
				// 		province: this.user.province,
				// 		region: this.user.region
				// 	}
				// } else if (this.selectedDeliveryAddress === this.secondaryAddress) {
				// 	addrRecord = {
				// 		streetAddress: this.user.secondaryAddress.streetAddress,
				// 		barangay: this.user.secondaryAddress.barangay,
				// 		city: this.user.secondaryAddress.city,
				// 		province: this.user.secondaryAddress.province,
				// 		region: this.user.secondaryAddress.region
				// 	}
				// }
				
				this.updateDeliveryDetails({
					orderId: this.order.orderId, 
					region: this.region, 
					deliveryAddress: addrRecord,
					distance: "",
				})
			}, 500);
		});

		this.$eventBus.$on("hideProfileModal", () => {
			this.setNearestBranch()
			this.$refs.profileModal.hide();
		});

		this.$eventBus.$on("hideAddAddressModal", () => {
			this.getOrder(this.order.orderId);
			this.$refs.addAddressModal.hide();
		});

		this.fetchUser(this.user);
		await this.getEcqNotice();
		this.setIsEcqDeliveryAddress(this.order.shippingMethod)
		// await this.fetchPackagings();
		
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === "SET_PROFILE_STATUS") {
				if (state.completeProfile) {
					this.address = `${this.user.streetAddress}, ${this.user.barangay}, ${this.user.city}, ${this.user.province}`;
				} else {
					this.$refs.profileModal.show();
				}
			}
	
			if(mutation.type == 'Cart/SET_SHIPPING_METHOD' && mutation.payload && this.hasEcqNotice) {
                this.setIsEcqDeliveryAddress(mutation.payload)
            }
		});

		setTimeout(() => {
			this.primaryAddress = `${this.user.streetAddress}, ${this.user.barangay} ${this.user.city} ${this.user.province} ${this.user.region}`
			if (this.user.secondaryAddress) {
				this.secondaryAddress = `${this.user.secondaryAddress.streetAddress}, ${this.user.secondaryAddress.barangay} ${this.user.secondaryAddress.city} ${this.user.secondaryAddress.province} ${this.user.secondaryAddress.region}`

				// this.selectedDeliveryAddress = this.secondaryAddress
				// this.region = this.user.secondaryAddress.region
				this.selectedDeliveryAddress = this.primaryAddress
				this.region = this.user.region
			} else {
				this.selectedDeliveryAddress = this.primaryAddress
				this.region = this.user.region
			}
		}, 5000);

		setTimeout(() => {
			if (this.order.shippingMethod === "delivery") {
				if (this.order.deliveryAddress) {
					if (this.order.deliveryAddress === this.secondaryAddress) {
						this.selectedDeliveryAddress = this.secondaryAddress
						this.region = this.user.secondaryAddress.region
						this.getOrder(this.order.orderId);
					} else {
						this.selectedDeliveryAddress = this.primaryAddress
						this.region = this.user.region	
						this.getOrder(this.order.orderId);
					}
				} else {
					this.selectedDeliveryAddress = this.primaryAddress
					this.region = this.user.region
				}
			} else {
				this.setNearestBranch()
			}
		}, 3000);

		setTimeout(() => {
			if (this.order.shippingMethod) {
				this.checkShippingMethod(this.order.shippingMethod)
			}	
		}, 3000);
	},

	methods: {
		...mapActions({
			setShippingMethod: "Cart/setShippingMethod",
			updateDeliveryDetails: "Cart/updateDeliveryDetails",
			getOrder: "Cart/getOrder",
			fetchUser: "fetchUser",
			setAvailableBranches: "Cart/getBranches",
			// validateEcqLocation: "UserLocation/validateEcqLocation"
		}),

		async checkShippingMethod(val,val2) {
			if (val === "delivery") {
				if (this.order.shippingMethod === "delivery") {
					if (val2 === "watch") {
						setTimeout(() => {
							let addrRecord = {}
							if (this.selectedDeliveryAddress === this.primaryAddress) {
								addrRecord = {
									streetAddress: this.user.streetAddress,
									barangay: this.user.barangay,
									city: this.user.city,
									province: this.user.province,
									region: this.user.region
								}
							} else if (this.selectedDeliveryAddress === this.secondaryAddress) {
								addrRecord = {
									streetAddress: this.user.secondaryAddress.streetAddress,
									barangay: this.user.secondaryAddress.barangay,
									city: this.user.secondaryAddress.city,
									province: this.user.secondaryAddress.province,
									region: this.user.secondaryAddress.region
								}
							}
							
							this.updateDeliveryDetails({
								orderId: this.order.orderId, 
								region: this.region, 
								deliveryAddress: addrRecord,
								distance: "",
							})
							this.setShippingMethod({type:val,origin:addrRecord})
							if (this.partsOnly) {
								this.computeEntriesDimension(addrRecord)
							}
						}, 2000);
					}
				} else {
					await this.$store.dispatch("Setup/getNearestBranch", this.branches);
					let addrRecord = {}
					if (this.selectedDeliveryAddress === this.primaryAddress) {
						addrRecord = {
							streetAddress: this.user.streetAddress,
							barangay: this.user.barangay,
							city: this.user.city,
							province: this.user.province,
							region: this.user.region
						}
					} else if (this.selectedDeliveryAddress === this.secondaryAddress) {
						addrRecord = {
							streetAddress: this.user.secondaryAddress.streetAddress,
							barangay: this.user.secondaryAddress.barangay,
							city: this.user.secondaryAddress.city,
							province: this.user.secondaryAddress.province,
							region: this.user.secondaryAddress.region
						}
					}

					setTimeout(() => {
						console.log(addrRecord)
						if (this.selectedDeliveryAddress === "") {
							if (this.order.deliveryAddress !== null || this.order.deliveryAddress !=="") {
								this.selectedDeliveryAddress = this.order.deliveryAddress
								this.setShippingMethod({type:val,origin:addrRecord})
							} else {
								this.selectedDeliveryAddress = this.primaryAddress
								this.region = this.user.region
								this.setShippingMethod({type:val,origin:addrRecord})
							}
						} else {
							this.selectedDeliveryAddress = this.selectedDeliveryAddress
							this.setShippingMethod({type:val,origin:addrRecord})
						}
						if (this.partsOnly) {
							this.computeEntriesDimension(addrRecord)
						}
					}, 5000);
				}
			} else if (val === "pickup") {
				await this.setShippingMethod({type:val,origin:{}})
				// await this.setAvailableBranches()
				await this.setNearestBranch()
			}
		},

		async getEcqNotice() {
			try {
                const request = await fetch(`${api}/setup/DLVRY_ECQ_NOTICE`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                })
    
                let response = await request.json();
                
                this.hasEcqNotice = parseInt(response.data.value) === 1
            } catch (error) {
                console.log(error);
            }
		},
		async setIsEcqDeliveryAddress(deliveryMethod) {
			const self = this

			if(deliveryMethod !== 'delivery') {
				this.isEcqDeliveryAddress = false
				this.acknowledgeEcq = false
				return
			}

			const geocoder = new window.google.maps.Geocoder()
			geocoder.geocode({
				address: this.selectedDeliveryAddress
			}, (result, status) => {
				let valid = false
				if(status !== 'OK' || result.length < 1) {
					self.isEcqDeliveryAddress = valid
				} else {
					const latLng = result[0].geometry.location
					self.isEcqDeliveryAddress = window.google.maps.geometry.poly.containsLocation(latLng, self.ncrPolygon)
				}
			})
		},
		async updateEntryQuantity(entry, mode) {
			this.entriesReady = false
			let index = this.entries.findIndex((e) => e.sku === entry.sku);
			await this.$store.dispatch(
				"Cart/setActiveOrderEntry",
				this.entries[index]
			);

			//Check committed quantity in Netsuite
			let request = await fetch(
				`${api}/products/stocks/${entry.department}/${entry.sku}/${entry.objectID}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			let response = await request.json();
			entry.stock = response.stocks;

			//new quantity
			let quantity = parseInt(entry.quantity);
			let event = "";
			if (mode === "increase") {
				quantity = quantity + 1;
				event = "increased";
			} else if (quantity >= 2) {
				quantity = quantity - 1;
				event = "decreased";
			}

			if((event === "increased" && parseFloat(entry.stock) >= quantity) || event === "decreased") {
				await this.$store.dispatch(
					"Cart/setActiveOrderEntryQuantity",
					quantity
				);
				await this.$store.dispatch("Cart/updateActiveOrderEntryQuantity");

				if(this.order.shippingMethod === "delivery") {
					await this.checkShippingMethod("delivery","watch")
				}
				new Noty({
					type: "info",
					theme: "semanticui",
					layout: "bottomRight",
					text: `${entry.name} quantity updated`,
					timeout: 5000,
				}).show();

				analytics.logEvent(`${event}_cart_entry_quantity`);
			} else {
				new Noty({
					type: "error",
					theme: "semanticui",
					layout: "bottomRight",
					text: `Insufficient stock for ${entry.name}`,
					timeout: 5000,
				}).show();

				analytics.logEvent(`insufficient_cart_entry_stock`);
			}

			this.entriesReady = true
		},
		async removeEntry(entry) {
			let activeEntry = entry;
			this.entries.splice(
				this.entries.findIndex((item) => item.sku === entry.sku),
				1
			);
			await this.$store.dispatch("Cart/removeOrderEntry", activeEntry);
			if(this.order.shippingMethod === "delivery") {
				await this.checkShippingMethod("delivery","watch")
			}
			analytics.logEvent("removed_cart_entry");
		},
		async addToWishlist(entry) {
			await fetch(`${api}/customer/wishlists`, {
				method: "POST",
				body: JSON.stringify({
					sku: entry.sku,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			await this.removeEntry(entry);

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${entry.name} added to wishlist`,
				timeout: 5000,
			}).show();

			analytics.logEvent("added_wishlist_entry");
		},
		async syncCart() {
			this.syncing = true;
			await fetch(`${api}/cart/sync`, {
				method: "POST",
				body: JSON.stringify({
					orderId: this.order.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.$router.push(`/cart/checkout/${this.order.orderId}`);
		},

		async updateDeliveryAddress() {
			await fetch(`${api}/cart/update/delivery-address`, {
				method: "PUT",
				body: JSON.stringify({
					orderId: this.order.orderId,
					deliveryAddress: this.selectedDeliveryAddress
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			})
			this.syncCart()
		},

		async checkStocks() {
			try {
                // const request = await fetch(`${api}/order/stocks/${this.order.orderId}`, {
                //     headers: {
                //         "Content-Type": "application/json",
                //         Authorization: `Bearer ${this.$store.getters.token}`,
                //     }
                // })
    
                // let response = await request.json();
                
            } catch (error) {
                console.log(error);
            }
		},
		async setNearestBranch() {
			const isNCR = this.deliveryAddressRecord.region === 'NCR'
			const geocoder = new window.google.maps.Geocoder()
			const branchDeliveryFilter = isNCR ? { region: this.deliveryAddressRecord.region } : { city: this.deliveryAddressRecord.city }

			this.setIsEcqDeliveryAddress("delivery")
			this.region = this.deliveryAddressRecord.region

			await this.setAvailableBranches(branchDeliveryFilter)

			geocoder.geocode({
				address: Object.values(this.deliveryAddressRecord).toString()
			}, (result, status) => {
				let payload = {
					isValid: status,
					lat: result[0].geometry.location.lat(),
					long: result[0].geometry.location.lng(),
					branches: this.branches,
					entries: this.entries
				}
				this.$store.dispatch("Setup/getNearestBranch", payload)
				// this.$store.dispatch("Cart/getNearestBranch", payload)
			})
		},

		// async fetchPackagings() {
		// 	let self = this
		// 	self.packagings = []
		// 	self.withPromoPackagings = []
		// 	self.activePackagings = []
		// 	let request = await fetch(`${api}/setup/packaging-type/fetch`, {
		// 		method: "GET",
		// 		headers: {
		// 			"Content-Type": "application/json",
		// 			Authorization: `Bearer ${self.$store.getters.token}`,
		// 		},
		// 	})
			
		// 	let response = await request.json();
		// 	self.packagings = response.data
		// 	if (self.packagings.length) {
		// 		self.packagings.forEach(function(res) {
		// 			if (res.active === 1) {
		// 				self.withPromoPackagings.push(res)
		// 				if (res.tag === 0) {
		// 					self.activePackagings.push(res)
		// 				}
		// 			}
		// 		})
		// 	}

		// 	self.withPromoPackagings = _.sortBy(self.withPromoPackagings, ['dimension.volume']);
		// 	self.activePackagings = _.sortBy(self.activePackagings, ['dimension.volume']);
		// },

		async computeEntriesDimension(addrRecord) {
			await fetch(`${api}/order/entries-dimensions`, {
				method: "POST",
				body: JSON.stringify({
					entries: this.entries,
					orderId: this.order.orderId,
					address: addrRecord
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			})
			// let self = this
			// var multiParts = []
			// let singlePartsVolume = 0
			// var totalVolumeMultiParts = 0
			// var partsVolume = 0
			// var helmetVolume = 0
			// var topBoxVolume = 0
			// var helmetCount = 0
			// var topBoxCount = 0
			// var carrierCount = 0
			// var carrierVolume = 0
			// var promoCount = 0
			// var promoVolume = 0
			// if (self.entries.length > 1) {
			// 	self.entries.forEach(function(res) {
			// 		console.log(res.department)
			// 		if (res.department === "Parts" || res.department === "Engine Oil / Lubricants" || res.department === "Apparel") {
			// 			if (res.quantity > 1) {
			// 				partsVolume = res.dimensions.Height * res.dimensions.Widht * res.dimensions.Lenght
			// 				partsVolume = partsVolume * res.quantity
			// 				multiParts.push(partsVolume)
			// 				console.log(res.dimensions.Height* res.dimensions.Widht* res.dimensions.Lenght)
			// 			} else {
			// 				let width = (res.packaging.Widht === null) ? 1 :res.packaging.Widht;
			// 				singlePartsVolume = Math.ceil(res.packaging.Height * width * res.packaging.Lenght)
			// 				partsVolume = res.dimensions.Height * res.dimensions.Widht * res.dimensions.Lenght
			// 				multiParts.push(partsVolume)
			// 				console.log(res.dimensions.Height* res.dimensions.Widht* res.dimensions.Lenght)
			// 			}
			// 		} else if (res.department === "Helmet") {
			// 			if (res.quantity > 1) {
			// 				helmetCount = helmetCount + res.quantity
			// 				helmetVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 				console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 				console.log(helmetVolume)
			// 			} else {
			// 				helmetCount ++
			// 				helmetVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 				console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 				console.log(helmetVolume)
			// 			}
			// 		} else if (res.department === "Accessories") {
			// 			console.log(res.itemGroup)
			// 			if (res.itemGroup === "Carrier") {
			// 				if (res.quantity > 1) { 
			// 					carrierCount = carrierCount + res.quantity
			// 					carrierVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 					console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 					console.log(carrierVolume)
			// 				} else {
			// 					carrierCount ++
			// 					carrierVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 					console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 					console.log(carrierVolume)
			// 				}
			// 			} else {
			// 				if (res.quantity > 1) { 
			// 					topBoxCount = topBoxCount + res.quantity
			// 					topBoxVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 					console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 					console.log(topBoxVolume)
			// 				} else {
			// 					topBoxCount ++
			// 					topBoxVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 					console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 					console.log(topBoxVolume)
			// 				}
			// 			}
			// 		} else if (res.department === "Promos") {
			// 			if (res.quantity > 1) { 
			// 				promoCount = promoCount + res.quantity
			// 				promoVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 				console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 				console.log(promoVolume)
			// 			} else {
			// 				promoCount ++
			// 				promoVolume = Math.ceil(res.packaging.Height * res.packaging.Widht * res.packaging.Lenght)
			// 				console.log(res.packaging.Height, res.packaging.Widht, res.packaging.Lenght)
			// 				console.log(promoVolume)
			// 			}
			// 		}
			// 	})
			// 	if (multiParts.length === 1) {
			// 		totalVolumeMultiParts = singlePartsVolume
			// 	} else {
			// 		totalVolumeMultiParts = multiParts.reduce((a, b) => a + b, 0)
			// 		if (totalVolumeMultiParts > 0) {
			// 			totalVolumeMultiParts = totalVolumeMultiParts + 500
			// 		}
			// 	}
			// 	await self.packagingForMultiEntries(totalVolumeMultiParts,helmetVolume,topBoxVolume,carrierVolume,promoVolume,helmetCount,topBoxCount,carrierCount,promoCount)
			// } else if (self.entries.length === 1) {
			// 	let width = 1
			// 	let volume = 0
			// 	let qty = 0
			// 	let itemDetails,packageDetails = {}
			// 	self.entries.forEach(function(res) {
			// 		if (res.department !== "Motorcycles") {
			// 			if (res.department === "Parts" || res.department === "Engine Oil / Lubricants" || res.department === "Apparel") {
			// 				if (res.quantity > 1) {
			// 					volume = res.dimensions.Height * res.dimensions.Widht * res.dimensions.Lenght
			// 					volume = volume * res.quantity
			// 					volume + volume + 500
			// 					qty = 1
			// 				} else {
			// 					width = (res.packaging.Widht === null) ? 1 :res.packaging.Widht;
			// 					volume = Math.ceil(res.packaging.Height * width * res.packaging.Lenght)
			// 					volume = volume * res.quantity
			// 					qty = res.quantity
			// 				}	
			// 				itemDetails = res.dimensions
			// 				packageDetails = res.packaging
			// 			} else {
			// 				width = (res.packaging.Widht === null) ? 1 :res.packaging.Widht;
			// 				volume = Math.ceil(res.packaging.Height * width * res.packaging.Lenght)
			// 				qty = res.quantity
			// 				itemDetails = res.dimensions
			// 				packageDetails = res.packaging
			// 			}
			// 		}
			// 	})
			// 	console.log(volume)
			// 	await self.packagingForSingleEntries(volume,qty,itemDetails,packageDetails)
			// }
		},

		async packagingForMultiEntries(partsVolume,helmetVolume,topBoxVolume,carrierVolume,promoVolume,helmetCount,topBoxCount,carrierCount,promoCount) {
			let self = this
			let matchPackages = []
			let count = 0
			console.log(partsVolume,helmetVolume,topBoxVolume,promoVolume,helmetCount,topBoxCount,promoCount)
			if (self.activePackagings) {
				self.activePackagings.forEach(function(res) {
					if (partsVolume === 0 && helmetVolume === 0 && topBoxVolume === 0 && promoVolume === 0 && carrierVolume === 0) {
						matchPackages = []
					} else if (partsVolume > 0) {
						if (partsVolume <= res.dimension.volume) {
							matchPackages.push(res)
						}
					} 
					count ++
					if (count === self.activePackagings.length) {
						if (matchPackages.length > 0) {
							matchPackages.length = 1	
						} else {
							matchPackages = []
						}
						console.log(matchPackages)
						let count2 = 0
						self.activePackagings.forEach(function(result) {
							if (partsVolume === 0 && helmetVolume === 0 && topBoxVolume === 0 && promoVolume === 0 && carrierVolume === 0) {
								matchPackages = []
							} else if (helmetVolume > 0) {
								if (helmetVolume == result.dimension.volume) {		
									for (let i = 0; i < helmetCount; i ++) {
										matchPackages.push(result)
									}
								}
							}
							count2 ++
							if (count2 === self.activePackagings.length) {
								if (partsVolume === 0 && helmetVolume === 0 && topBoxVolume === 0 && promoVolume === 0 && carrierVolume === 0) {
									matchPackages = []
								} else if (topBoxVolume > 0) {
									let functionResult = null
									functionResult = self.getNextHighestNumber(self.activePackagings, topBoxVolume)
									for (let i = 0; i < topBoxCount; i ++) {
										matchPackages.push(functionResult)
									}
									console.log(matchPackages)
								}

								if (carrierVolume > 0) {
									let functionResult = null
									functionResult = self.getNextHighestNumber(self.activePackagings, carrierVolume)
									for (let i = 0; i < carrierCount; i ++) {
										matchPackages.push(functionResult)
									}
									console.log(matchPackages)
								}

								if (promoVolume > 0) {
									console.log(promoVolume, promoCount)
									let functionResult = null
									functionResult = self.getNextHighestNumber(self.withPromoPackagings, promoVolume)
									for (let i = 0; i < promoCount; i ++) {
										matchPackages.push(functionResult)
									}
									console.log(matchPackages)
								}
							}
						})
					}
				})
				
				if (matchPackages.length > 0) {
					self.itemPackaging = matchPackages
					await db.collection("cart").doc(self.order.orderId).update({
						packaging: self.itemPackaging
					});
					//await self.syncCart()
				} else {
					await db.collection("cart").doc(self.order.orderId).update({
						packaging: null
					});
					//await self.syncCart()
				}
			}
		},

		getNextHighestNumber(arr, number) {
			var max = 0;
			for (var i = 0; i < arr.length; i ++) {
				if (arr[i].dimension.volume >= number) {
					return arr[i];
				}
				if (arr[i].dimension.volume >= max) {
					max = arr[i];
				}
			}
			return max;
		},

		// async packagingForSingleEntries(volume, qty, itemDetails, packageDetails) {
		// 	let self = this
		// 	let matchPackages = []
		// 	let res = []
		// 	console.log(volume)
		// 	if (self.withPromoPackagings) {
		// 		self.withPromoPackagings.forEach(function(res) {
		// 			if (volume === 0) {
		// 				matchPackages = []
		// 			} else if (volume <= res.dimension.volume) {
		// 				matchPackages.push(res)
		// 			}
		// 		})	
				
		// 		if (matchPackages.length > 0) {
		// 			console.log(matchPackages)
		// 			self.itemPackaging = matchPackages[0]
		// 			for (let i = 0; i < qty; i++) {
		// 				res.push(self.itemPackaging)
		// 			}
		// 			console.log(res)
		// 			// await fetch(`${api}/order/packaging-details`, {
		// 			// 	method: "POST",
		// 			// 	body: JSON.stringify({
		// 			// 		type: "single",
		// 			// 		packagingDimension: volume,
		// 			// 		packaging: res,
		// 			// 		packaging_weight: volume,
		// 			// 		itemDetails: itemDetails,
		// 			// 		packageDetails: packageDetails
		// 			// 	}),
		// 			// 	headers: {
		// 			// 		"Content-Type": "application/json",
		// 			// 		Authorization: `Bearer ${this.$store.getters.token}`,
		// 			// 	},
		// 			// })
		// 			await db.collection("cart").doc(self.order.orderId).update({
		// 				packaging: res
		// 			});
		// 			//await self.syncCart()
		// 		} else {
		// 			await db.collection("cart").doc(self.order.orderId).update({
		// 				packaging: null
		// 			});
		// 			//await self.syncCart()
		// 		}
		// 	}
		// },
		async geoCodeDeliveryAddress() {
			let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${this.selectedDeliveryAddress}&key=AIzaSyDHAjcezNBpAbyyj9PwSnH5fplYE1o9cOE`
			let geoCodes = null
			await axios.get(url).then((res)=>{
				geoCodes = res.data.results[0].geometry.location
				console.log(geoCodes)
			})
		},
	},
	watch: {
		hub: {
            handler:async function(val) {
				if (val) {
					await db.collection("cart").doc(this.order.orderId).update({
						branch: val.uid,
						branchName: val.readableName,
					});
				}
            },
            deep: true
        },
		async selectedBranch(value) {
			await db.collection("cart").doc(this.order.orderId).update({
				branch: value.uid,
				branchName: value.readableName,
			});
		},
		acknowledgeEcq(value) {
			db.collection("cart").doc(this.order.orderId).update({
				acknowledgeEcq: value
			});
		},

		addNewAddress(value) {
			if (value === true) {
				this.$refs.addAddressModal.show();
			} else if (value === false) {
				this.$refs.addAddressModal.hide();
			}
		},
		
		async selectedDeliveryAddress() {
			if(this.order.shippingMethod === "delivery") {
				await this.checkShippingMethod("delivery","watch")
			}
		},

		async nearestBranch(value) {
			await db.collection("cart").doc(this.order.orderId).update({
				branch: value.uid,
				branchName: value.name,
			});

			await fetch(`${api}/cart/sync`, {
				method: "POST",
				body: JSON.stringify({
					orderId: this.order.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.checkStocks();
		}
	},
	components: {
		AddAddress,
		UpdateProfile,
		FeaturedProducts,
		banner
	},
	beforeDestroy() {
		this.unsubscribe();
	},
};
</script>

<style scoped>
.hidden-text {
	display: none;
}

input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
input[type="radio"]:checked {
  background-color: #73ff00;
}

.flex {
  display: flex;
  align-items: center;
}

.branches {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 6 2'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.25rem center/24px 16px;
}
#branches.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 6 2'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.25rem center/24px 16px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2.25rem/calc(.75em + .375rem) calc(.75em + .375rem);
}
#branches.is-invalid {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 3 6 2'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.25rem center/24px 16px,url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2.25rem/calc(.75em + .375rem) calc(.75em + .375rem);
}

.branches::after {
	content: '⯆	';
	font-size: 14px;
	color: #000;
	position:absolute;
	top: 12px;
	right: 10px;
}

</style>
