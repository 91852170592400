export const channels = [
    // {
    // 	label: "Credit Card / Debit Card",
    // 	options: [{ value: "cc", text: "Credit Card / Debit Card" }],  DISABLED
    // },
    {
        label: "Online Bills Payment",
        options: [
            {
                value: "bdoobp",
                text: "BDO Online Bills Payment"
            },
            {
                value: "pnbobp",
                text: "Philippine National Bank Online Bills Payment",
            },
            {
                value: "ucpbobp",
                text: "United Coconut Planters Online Bills Payment",
            },
            {
                value: "sbcobp",
                text: "Security Bank Online Bills Payment",
            },
        ],
    },
    {
        label: "Wallet",
        options: [
			//{ value: "pp", text: "Paypal" }, DISABLED
            // { value: "vcard", text: "Virtual Card" }, DISABLED
            {
                value: "gc",
                text: "Gcash",
                max: 100000
            },
            // {
            //     value: "coins",
            //     text: "Coins PH",
            //     max: 10000
            // }, DISABKED
            {

                value: "grabpay",
                text: "Grabpay PH",
                max: 100000
            },
        ],
    },
    {
        label: "Online Bank Transfer",
        options: [
            {
                value: "bn",
                text: "Bancnet Philippines",
                max: 20000
            },
            // { value: "ents", text: "Enets Singapore" }, DISABLED
            // { value: "eghl", text: "E-GHL Thailand and Malaysia" }, DISABLED
            // {
            //     value: "poli",
            //     text: "Polipayments Australia and New Zealand", DISABLED
            // },
            {
                value: "bpionline",
                text: "Bank of the Philippine Islands",
                max: 50000
            },
            {
                value: "ubponline",
                text: "Unionbank of the Philippines",
                max: 50000
            },
        ],
    },
    {
        label: "Bank Over the Counter",
        options: [
			//{ value: "bdootc", text: "Banco De Oro Bank Philippine Branches", }, DISABLED
            {
                value: "pnbotc",
                text: "Philippine National Bank Branches",
                max: 50000
            },
            {
                value: "ucpbotc",
                text: "United Coconut Planters Bank Branches",
            },
            // { value: "sbcotc", text: "Security Bank Branches" }, DISABLED
        ],
    },
    {
        label: "Non Bank Over the Counter",
        options: [
            {
                value: "ml",
                text: "Mlhuillier Pawnshop Network",
                max: 150000
            },
            {
                value: "ceb",
                text: "Cebuana Pawnshop Network"
            },
            {
                value: "sm",
                text: "SM Bills Payment Network"
            },
            { value: "ecpay", text: "Ecpay Network Philippines" },
            // {
            //     value: "da5",
            //     text: "Direct Agents 5 Network Philippines",
            // },DISABLED
            // {
            //     value: "expresspay",
            //     text: "Expresspay Network Philippines",
            // },DISABLED
            // { value: "dp", text: "DragonPay Philippines" }, DISABLED
            // {
            //     value: "truemoney",
            //     text: "True Money Network"
            // },DISABLED
            // {
            //     value: "posible",
            //     text: "Posible.net Network",
            //     max: 50000
            // },DISABLED
            // { value: "etap", text: "Etap Network" }, DISABLED
        ],
    },
];
