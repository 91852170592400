<template>
    <div>
        <main role="main" id="app">
            <section class="products ptb-5">
                <div class="container">
                    <div class="row">
                        <div
                            class="col-12 d-flex flex-row justify-content-center align-items-center"
                            style="height: 600px;"
                        >
                            <img src="/img/spinner.gif" height="100" alt />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import firebase from "firebase";
import { analytics } from "../main";

export default {
    mounted() {
        this.$store.dispatch("loggedOut");
        this.$store.dispatch("logout");
        firebase
            .auth()
            .signOut()
            .then(() => {
                analytics.logEvent("logged_out");
                setTimeout(() => {
                    this.$router.push({
                        path: "/home",
                    });
                }, 3000);
            });
    },
};
</script>

<style></style>
