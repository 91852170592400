<template>
    <b-row class="text-center mt-5">
        <b-col cols="12"><b-icon :font-scale="size" icon="arrow-clockwise" animation="spin-pulse"></b-icon></b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        size: {
            default: 7.5
        }
    }
}
</script>>