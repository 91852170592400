<template>
    <b-row>
        <b-col col cols="12">
            <spinner v-if="loading"></spinner>
            <b-row class="products-list" v-else>
                <b-col col cols="12" sm="6" md="6" lg="4" 
                    class="text-center products-list-item"
                    v-for="record in records"
                    :key="record.id"
                >
                    <router-link :to="'/products/'+record.slug">
                        <div class="product-image">
                            <img :src="record.photo" class="img-fluid" />
                        </div>
                        <h4 class="product-name text-uppercase">{{record.name}}</h4>
                        <p class="product-description">
                            <span v-if="record.department == 'Parts'">{{record.model}}</span>
                            <span v-if="record.department == 'Accessories'">{{record.model}}</span>
                            <span v-else>{{record.shortDescription}}</span>
                        </p>
                        <h3 class="product-price">{{ record.price | currencyDisplay }}</h3>
                        <p class="thumbnail-promos" v-if="record.department == 'Motorcycles' && (record.LTOFee != 1 || record.freebies != null)">
                            <span v-if="record.LTOFee != 1">Free LTO Registration for Loan Payment<br></span>
                            <span v-if="record.freebies != null && record.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
                            <span v-if="record.freebies != null && record.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
                        </p>
                    </router-link>
                </b-col>
            </b-row>
            <noSearchFound v-if="emptySearch" :message="emptySearchMessage"></noSearchFound>
        </b-col>
    </b-row>
</template>

<script>
import _ from 'lodash'
import noSearchFound from '@/components/NoSearchFound'
import spinner from '@/components/LoadingSpinner'

export default {
    components: {
        spinner,
        noSearchFound
    },
    props: {
        records: {
            type: Array
        },
        loading: {
            type: Boolean,
            default: false
        },
        emptySearchMessage: {
            type: String,
            default: "No item(s) found"
        }
    },
    computed: {
        emptySearch() {
            return _.isEmpty(this.records)
        }
    }
}
</script>

<style>

    .products-list-item a {
        min-height: 410px;
        min-height: 0;
    }

    .thumbnail-promos {
        margin-top: -25px;
        line-height: 1;
    }
    .thumbnail-promos span {
        color: red;
        font-size: 10px;
    }
</style>