export const primary = [
	"Voters ID",
	"TIN ID",
	"Driver's License",
	"Pag-ibig Loyalty Card",
	"SSS ID",
	"Professional Regulatory Commission (PRC) ID",
	"Passport",
	"PhilHealth ID",
	"Postal ID",
	"GSIS eCard",
	"Integrated Bar of the Philippines (IBP) ID",
	"OWWA ID",
	"OFW ID Card",
	"Senior Citizen ID",
	"ACR I-CARD (Alien Certificate of Registration)"
];

export const secondary = [
	"School ID",
	"Employment ID",
	"DSWD Solo Parents ID",
	"Firearms License Card",
	"Alumni ID",
	"Seaman's Book",
	"PWD ID"
];
