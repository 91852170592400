<template>
	<b-form @submit="updateProfile" novalidate v-on:submit.prevent>
		<div class="row">
			<div class="col-md-6 col-sm-12">
				<div class="form-group">
					<label>Firstname</label>
					<input type="text" class="form-control" placeholder v-model.trim.lazy="$v.firstname.$model" />
					<small class="error text-danger" v-if="!$v.firstname.required">Firstname is required</small>
				</div>
				<div class="form-group">
					<label>Lastname</label>
					<input type="text" class="form-control" placeholder v-model.trim.lazy="$v.lastname.$model" />
					<small class="error text-danger" v-if="!$v.lastname.required">Lastname is required</small>
				</div>
				<div class="form-group">
					<label>Middle Name</label>
					<input type="text" class="form-control" placeholder v-model.trim.lazy="$v.middlename.$model" />
					<small class="error text-danger" v-if="!$v.middlename.required">Middle name is required</small>
				</div>
				<div class="form-group">
					<label>Suffix</label>
					<input type="text" class="form-control" placeholder v-model.trim.lazy="$v.suffix.$model" />
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="form-group">
							<label>Contact number (09xxxxxxxxx)</label>
							<input
								type="text"
								class="form-control"
								name="contactNumber"
								placeholder="Your contact number"
								v-model.trim.lazy="$v.contactNumber.$model"
							/>
							<small class="error text-danger" v-if="$v.contactNumber.$invalid"
								>Contact number is invalid</small
							>
						</div>
					</div>
					<!-- <div class="col-md-6">
						<div class="form-group">
							<label>Birth date</label>
							<input
								type="date"
								class="form-control"
								name="birthday"
								placeholder="Your date of birth"
								v-model.trim.lazy="$v.birthday.$model"
							/>
							<small class="error text-danger" v-if="!$v.birthday.required">Birth date is required</small>
						</div>
					</div>-->
				</div>

				<!-- <b-form-group label="Gender">
					<b-form-radio-group
						v-model="gender"
						:options="genderOptions"
						name="radio-inline"
					></b-form-radio-group>
				</b-form-group>-->
			</div>

			<div class="col-md-6 col-sm-12">
				<label>Registered Address</label>
				<div class="row">
					<div class="form-group col-md-6">
						<label>Region</label>

						<select v-model.trim.lazy="$v.region.$model" class="form-control">
							<option v-for="r in regions" :key="r" :selected="region === r" :value="r">{{ r }}</option>
						</select>
						<small class="error text-danger" v-if="!$v.region.required">Region is required</small>
					</div>

					<div class="form-group col-md-6">
						<label>Province</label>

						<select v-model.trim.lazy="$v.province.$model" class="form-control">
							<option v-for="p in provinces" :key="p" :selected="province === p" :value="p">
								{{ p }}
							</option>
						</select>
						<small class="error text-danger" v-if="!$v.province.required">Province is required</small>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md-6">
						<label>City</label>

						<select v-model.trim.lazy="$v.city.$model" class="form-control">
							<option v-for="c in cities" :key="c" :selected="city === c" :value="c">{{ c }}</option>
						</select>
						<small class="error text-danger" v-if="!$v.city.required">City is required</small>
					</div>

					<div class="form-group col-md-6">
						<label>Barangay</label>

						<select v-model.trim.lazy="$v.barangay.$model" class="form-control">
							<option v-for="b in barangays" :key="b" :selected="barangay === b" :value="b">
								{{ b }}
							</option>
						</select>
						<small class="error text-danger" v-if="!$v.barangay.required">Barangay is required</small>
					</div>
				</div>

				<div class="form-group">
					<label>Street Address</label>
					<textarea class="form-control" v-model.trim.lazy="$v.streetAddress.$model"></textarea>
					<small class="error text-danger" v-if="!$v.streetAddress.required"
						>Street Address is required</small
					>
				</div>
	
				<div v-if="user.secondaryAddress">
					<label>Secondary Address</label>
					<div class="row">
						<div class="form-group col-md-6">
							<label>Region</label>

							<select v-model="secondaryAddress.region" class="form-control">
								<option v-for="r in secondaryRegions" :key="r" :selected="secondaryAddress.region === r" :value="r">{{ r }}</option>
							</select>
							<small class="error text-danger" v-if="!secondaryAddress.region">Region is required</small>
						</div>

						<div class="form-group col-md-6">
							<label>Province</label>

							<select v-model="secondaryAddress.province" class="form-control">
								<option v-for="p in secondaryProvinces" :key="p" :selected="secondaryAddress.province === p" :value="p">
									{{ p }}
								</option>
							</select>
							<small class="error text-danger" v-if="!secondaryAddress.province">Province is required</small>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-md-6">
							<label>City</label>

							<select v-model="secondaryAddress.city" class="form-control">
								<option v-for="c in secondaryCities" :key="c" :selected="secondaryAddress.city === c" :value="c">{{ c }}</option>
							</select>
							<small class="error text-danger" v-if="!secondaryAddress.city">City is required</small>
						</div>

						<div class="form-group col-md-6">
							<label>Barangay</label>

							<select v-model="secondaryAddress.barangay" class="form-control">
								<option v-for="b in secondaryBarangays" :key="b" :selected="secondaryAddress.barangay === b" :value="b">
									{{ b }}
								</option>
							</select>
							<small class="error text-danger" v-if="!secondaryAddress.barangay">Barangay is required</small>
						</div>
					</div>

					<div class="form-group">
						<label>Street Address</label>
						<textarea class="form-control" v-model="secondaryAddress.streetAddress"></textarea>
						<small class="error text-danger" v-if="!secondaryAddress.streetAddress"
							>Street Address is required</small
						>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12 text-center">
				<b-form-checkbox
					id="checkbox-1"
					v-model="accepted"
					name="checkbox-1"
					:value="true"
					:unchecked-value="false"
				>
					In compliance with the Data Privacy Act (DPA) of 2012; and its Implementing Rules & Regulations
					(IRR). We will collect, use, process, stores and retain your personal information for the purpose of
					providing valuable and useful services that we believe you might find interesting and beneficial.
					Where we consider it necessary or appropriate for the purposes of data storage or processing or
					providing any service on our behalf to you, we may transfer/share your personal information within
					SCGC, under conditions of confidentiality and similar levels of security safeguards.
				</b-form-checkbox>
				<hr />
				<button
					type="submit"
					class="btn btn-primary"
					:disabled="submitStatus === 'PENDING' || $v.$invalid || !accepted"
				>
					Submit
				</button>
			</div>
		</div>
	</b-form>
</template>

<script>
import { api } from "../../config";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators";

const mobileNumberRule = (value) => /^(09)\d{9}$/.test(value);

export default {
	data: () => ({
		uid: "",
		userId: "",
		firstname: "",
		lastname: "",
		middlename: "",
		suffix: "",
		gender: "",
		birthday: "",
		contactNumber: "",
		name: "",
		streetAddress: "",
		barangay: "",
		city: "",
		province: "",
		region: "",
		secondaryAddress: null,
		submitStatus: null,
		genderOptions: ["Female", "Male", "unspecified"],
		accepted: false,
		//secondaryRegions: ["NCR"]
		secondaryRegions: ["NCR","09","11"]
	}),
	computed: {
		...mapGetters({
			user: "user",
			regions: "Setup/regions",
			provinces: "Setup/provinces",
			cities: "Setup/cities",
			barangays: "Setup/barangays",
			// secondaryRegions: "Setup/secondaryRegions",
			secondaryProvinces: "Setup/secondaryProvinces",
			secondaryCities: "Setup/secondaryCities",
			secondaryBarangays: "Setup/secondaryBarangays",
		}),
	},
	mounted() {
		this.uid = this.user.uid;
		this.userId = this.user.userId;
		this.firstname = this.user.firstname;
		this.lastname = this.user.lastname;
		this.middlename = this.user.middlename;
		this.suffix = this.user.suffix;
		this.gender = this.user.gender;
		this.birthday = this.user.birthday;
		this.contactNumber = this.user.contactNumber;
		this.name = this.user.name;
		this.streetAddress = this.user.streetAddress;
		this.barangay = this.user.barangay;
		this.city = this.user.city;
		this.province = this.user.province;
		this.region = this.user.region;
		this.secondaryAddress = this.user.secondaryAddress
	},
	methods: {
		async updateProfile() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				this.submitStatus = "ERROR";
			} else {
				this.submitStatus = "PENDING";

				await fetch(`${api}/users/update`, {
					method: "POST",
					body: JSON.stringify({
						id: this.user.id,
						userId: this.userId,
						perxId: this.user.perxId,
						email: this.user.email,
						firstname: this.firstname,
						lastname: this.lastname,
						middlename: this.middlename,
						suffix: this.suffix,
						gender: this.gender,
						birthday: this.birthday,
						contactNumber: this.contactNumber,
						name: this.name,
						streetAddress: this.streetAddress,
						barangay: this.barangay,
						city: this.city,
						province: this.province,
						region: this.region,
						secondaryAddress: this.secondaryAddress
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${this.$store.getters.token}`,
					},
				});

				this.submitStatus = "OK";

				await this.$store.dispatch("fetchUser", this.user);
				await this.$store.dispatch("Cart/getOrder");
				this.$eventBus.$emit("hideProfileModal");
			}
		},
	},
	validations: {
		firstname: {
			required,
		},
		lastname: {
			required,
		},
		middlename: {
			required,
		},
		suffix: {},
		contactNumber: {
			required,
			mobileNumberRule,
			minLength: minLength(11),
		},
		streetAddress: {
			required,
		},
		barangay: {
			required,
		},
		city: {
			required,
		},
		province: {
			required,
		},
		region: {
			required,
		},
	},
	watch: {
		contactNumber() {
			this.$v.contactNumber.$touch();
		},
		region(value) {
			if (value) {
				this.$store.dispatch("Setup/getProvinces", {
					region: value,
				});
			}
		},
		province(value) {
			if (value) {
				this.$store.dispatch("Setup/getCities", {
					region: this.region,
					province: value,
				});
			}
		},
		city(value) {
			if (value) {
				this.$store.dispatch("Setup/getBarangays", {
					region: this.region,
					province: this.province,
					city: this.city,
				});
			}
		},
		secondaryAddress: {
            handler: function(value) {
				if (value.region) {
					this.$store.dispatch("Setup/getSecondaryProvinces", {
						region: value.region,
					});
				}

				if (value.province) {
					this.$store.dispatch("Setup/getSecondaryCities", {
						region: this.secondaryAddress.region,
						province: value.province,
					});
				}

				if (value.city) {
					this.$store.dispatch("Setup/getSecondaryBarangays", {
						region: this.secondaryAddress.region,
						province: this.secondaryAddress.province,
						city: this.secondaryAddress.city,
					});
				}  
            },
            deep: true
        }
	},
};
</script>

<style></style>
