<template>
	<div class="home">
		<section class="banner">
			<slider />
		</section>
		<main role="main">
			<featured-products department="Motorcycles" count="4" />
			<featured-products department="Parts" count="4" />
			<section class="tagline ptb-7">
				<div class="container">
					<div class="row">
						<div class="col-md-8 offset-md-2">
							<h3 class="text-center">
								PWEDE NA BUMILI NG MOTOR, PARTS AT ACCESSORIES ONLINE!
							</h3>
						</div>
						<div class="col-md-8 offset-md-2">
							<h6 class="text-white text-center">
								Mas pinadali at pinabilis na ang proseso dahil prayoridad namin ang inyong safety at convenience!
							</h6>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import Slider from "../components/Home/Slider";
import FeaturedProducts from "../components/Home/FeaturedProducts";
export default {
	name: "home",
	components: {
		Slider,
		FeaturedProducts,
	},
};
</script>
