<template>
	<div>
		<banner title="Parts"></banner>
		<main>
			<section class="pb-5 pt-3">
				<b-container>
					<b-row>
						<b-col md="3" sm="12">
							<div class="sidebar products-sidebar mb-3">
								<div class="input-group md-form form-sm form-2 pl-0 mb-3 searchbar">
									<input
										class="form-control my-0 py-1 red-border rounded-0"
										type="text"
										placeholder="Search"
										aria-label="Search"
										v-model="filters.term"
									/>
									<div class="input-group-append">
										<span class="input-group-text red lighten-3 rounded-0" id="basic-text1">
											<i class="fas fa-search text-grey" aria-hidden="true"></i>
										</span>
									</div>
								</div>
								<h6 class="text-uppercase mt-3"><b>Parts Group</b></h6>
								<b-card no-body v-for="(group, groupIndex) in sideBarGroups.itemGroups" :key="group.id">
									<b-card-header>
										<b-row>
											<b-col col md="9">{{group.name}}</b-col>
											<b-col col md="1">
												<b-button 
													pill light 
													size="sm" 
													variant="outline-secondary" 
													v-b-toggle="'accordion-'+group.id"
												>
													<!-- @click="loadCategories(index)" -->
													<b-icon icon="chevron-down" />
												</b-button>
											</b-col>
										</b-row>
									</b-card-header>
									<b-collapse 
										visible accordion="my-accordion" 
										role="tabpanel" 
										:id="'accordion-'+group.id"
										@input="loadCategories(groupIndex)"
									>
										<b-form-checkbox-group class="m-3" stacked :options="categories[groupIndex]" v-model="filters.categories" />
									</b-collapse>
								</b-card>
								<b-button mb-3 block variant="danger" @click="clearFilters">Clear All</b-button>
							</div>
						</b-col>
						<b-col md="9" sm="12">
							<b-row>
								<b-col col md="12">
									<b-input-group>
										<b-form-select v-model="filters.manufacturers" :options="brandOptions">
											<template v-slot:first>
												<b-form-select-option :value="null">-- Filter By Brand --</b-form-select-option>
											</template>
										</b-form-select>
										<b-form-select v-model="filters.model" :options="modelOptions" value-field="text">
											<template v-slot:first>
												<b-form-select-option :value="null">-- Filter By Model --</b-form-select-option>
											</template>
										</b-form-select>
										<b-input-group-append>
											<b-button size="sm" text="Button" variant="danger"><b-icon-filter /> Filter</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-col>
							</b-row>

							<productList class="mt-4" :loading="searching" :records="hits"> </productList>
							
							<b-row>
								<b-col>
									<b-pagination :total-rows="pagination.totalRows" :items="pagination.items" :per-page="filters.hitsPerPage" v-model="filters.page" />
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</b-container>
			</section>
		</main>
	</div>
</template>

<script>
import _ from 'lodash'
import {api} from '@/config.js'
import banner from '@/components/ProductBanner'
import productList from './Lists'
import search from '@/libraries/Search'

export default {
	components: {
		banner,
		productList
	},
	data() {
		let filters = search.setDefaultFilter(this.getDefaultFilters())

		return {
			searching: false,
			emptySearch: true,
			departmentId: 2, //for Parts
			searchText: null,
			emptySearchMessage: null,
			hits: [],
			pagination: {
				totalRows: null,
				items: []
			},
			categories: [],
			brandOptions: [],
			modelOptions: [],
			sideBarGroups: [],
			filters
		}
	},
	computed: {
		searchFilters() {
			let filters = {...this.filters}
			filters.manufacturers = [filters.manufacturers]
			filters.model = filters.model == null || filters.model == undefined ? null : _.find(this.modelOptions, { text: filters.model}).value
			return search.getURLString(filters)
		}
	},
	watch: {
		filters: {
			handler(value) {
				search.setURLParameters(value)
				this.doSearch()
			},
			deep: true
		},
		"filters.manufacturers": {
			handler(value) {
				this.getModels(value)
			}
		}
	},
	async mounted() {
		let self = this
		await this.getGroupings()
		this.getBrands()
		this.getModels()
		this.doSearch()

		window.onpopstate = function() { self.doSearch() }
	},
	methods: {
		getDefaultFilters() {
			return {
				page: 1,
				hitsPerPage: 20,
				replica: 'products_timestamp_desc',
				departments: 'Parts',
				manufacturers: null,
				model: null,
				categories: null,
				term: null,
			}
		},
		async doSearch() {
			let self = this
			let filters = this.searchFilters
			this.searching = true
			fetch(`${api}/products/search?${filters}`, {
				headers: {
					"Content-Type": "application/json",
				},
			}).then(async response => {
				let responseBody = await response.json()
				let _tempPageItems = []
				self.hits = responseBody.hits
				self.searching = false
				self.pagination.totalRows = responseBody.nbHits
				for (let index = 0; index < responseBody.nbPages; index++) {
					_tempPageItems.push(index)
				}
				self.pagination.items = _tempPageItems
			})
		},
		loadCategories(groupIndex) {
			let self = this
			let group = this.sideBarGroups.itemGroups[groupIndex]

			if(!_.isUndefined(this.categories[groupIndex]) && !_.isEmpty(this.categories[groupIndex])) return;

			fetch(`${api}/parts/distinct/category?itemGroup=${group.id}`, {
				headers: {
					"Content-Type": "application/json",
				}
			}).then(async request => {
				let responseBody = await request.json()
				self.$set(self.categories, groupIndex, responseBody.data.map(rec => rec.DISTINCT))
			})
		},
		getBrands() {
			let self = this
			fetch(`${api}/parts/distinct/brand?department=${self.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
				}
			}).then(async request => {
				let responseBody = await request.json()
				self.brandOptions = responseBody.data.map(rec => rec.DISTINCT)
			})
		},
		getModels(manufacturer) {
			let self = this
			let sourceStr = `${api}/products/top_selling/model?department[]=${self.departmentId}&limit=100`

			if(manufacturer) sourceStr += `&brand=${manufacturer}`

			fetch(sourceStr, {
				headers: {
					"Content-Type": "application/json",
				}
			}).then(async request => {
				let responseBody = await request.json()
				let models = responseBody.rows.map(rec => rec.model)
				let _tempModels = []
				models.forEach(rec => {
					rec.split("/").forEach((val) => {
						let identifier = val.trim()
						const ind = _.findIndex(_tempModels, { text: identifier })
						
						// for having duplicate model in different parts
						if(ind < 0) {
							_tempModels.push({
								value: [rec],
								text: val.trim()
							})
						} else {
							_tempModels[ind].value.push(rec)
						}
					})
				});
				// console.log(_temp);
				// self.modelOptions = responseBody.data.map(rec => rec.DISTINCT)
				self.modelOptions = _tempModels
			})
		},
		async getGroupings() {
			let request = await fetch(`${api}/item-groups/reverse?department=${this.departmentId}`, {
				headers: {
					"Content-Type": "application/json",
				}
			});
			
			let responseBody = await request.json()
			this.sideBarGroups = responseBody.data[0]
		},
		clearFilters() {
			this.filters = this.getDefaultFilters()
		}
	}
}
</script>