<template>
	<div>
		<banner title="Apply for a Loan" />
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-md-6 offset-md-3">
							<h3 class="mb-3 text-center">
								Thank you for applying
								<br />a loan with us.
							</h3>
							<p
								class="text-center"
							>Thank you for applying a loan with us! Our team will process your application. Kindly wait for an email and SMS notification on the status of your application. For any concern or follow up on your application, you may contact our Customer Service Hotline at 0917 856 4207 or 0998 848 1992, or send us a message thru our Live Chat!</p>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	}
}
</script>
