<template>
    <div class="mb-5">
        <banner title="View Loan" />
        <main role="main">
            <div class="products single-product pt-5">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12 col-md-5">
                            <div class="product-image mb-3 zoom">
                                <img class="img-fluid" :src="details.photo" />
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h2 class="product-name text-uppercase mb-3">{{ details.name }}</h2>
                                    <h5>{{ details.variantValue }}</h5>
                                </div>

								<div class="col-12" v-if="application.downpayment">
									<table class="table">
										<thead>
											<tr>
												<th scope="col" colspan="2">Summary</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Downpayment</td>
												<td class="text-right">{{ application.downpayment | amountThousandSeparator }}</td>
											</tr>
                                            <tr>
												<td>LTO Registration Fee</td>
												<td class="text-right">{{ application.ltoRegistrationFees | amountThousandSeparator }}</td>
											</tr>
											<tr>
												<td>Delivery Fee</td>
												<td class="text-right">{{ application.shipping | amountThousandSeparator }}</td>
											</tr>
                                            <tr>
												<td>
													<b>Subtotal</b>
												</td>
												<td class="text-right">
													<b>{{ subtotal | currencyDisplay }}</b>
												</td>
											</tr>
                                            <tr v-if="paymentMethod === 'Pay Online' || application.orderStatus == 'To Pay'">
												<td>Online Transaction Fee</td>
												<td class="text-right">{{ application.onlineTransactionFee | amountThousandSeparator }}</td>
											</tr>
											<tr>
												<td>
													<b>Total</b>
												</td>
												<td class="text-right">
													<b>{{ total | currencyDisplay }}</b>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
                            </div>
                            <div class="row">
                                <p class="product-description text-danger" v-if="(application.freebies != null) ">
                                    <span v-if="application.freebies != null">For Single Order Only:<br></span>
                                    <span v-if="application.freebies != null && application.freebies.includes('LTO')">Free LTO Registration for Loan Payment<br></span>
                                    <span v-if="application.freebies != null && application.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
                                    <span v-if="application.freebies != null && application.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="d-block">
                                <div class="text-right" v-if="editingAllowed">
                                    <button
                                        class="btn btn-danger"
                                        @click="confirmApplicationCancellation"
                                    >Cancel application</button>
                                    <hr />
                                </div>
                                <form
                                    @submit="$refs.confirmSubmissionModal.show()"
                                    v-on:submit.prevent
                                >
                                    <h4 v-show="application.applicationId">
                                        Application ID:
                                        <b>{{ application.applicationId }}</b>
                                    </h4>
                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.userName"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Contact No.</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.userContactNumber"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>Registered Address</label>
                                            <textarea
                                                class="form-control"
                                                v-model="application.userAddress"
                                                readonly
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>Shipping Address</label>
                                            <textarea
                                                class="form-control"
                                                v-model="order.deliveryAddress"
                                                readonly
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Age</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.age"
                                                :readonly="!editingAllowed"
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Property Status</label>
                                            <select
                                                v-model="application.propertyStatus"
                                                required
                                                :readonly="!editingAllowed"
                                                class="form-control"
                                            >
                                                <option
                                                    v-for="status in propertyStatusOptions"
                                                    :key="status"
                                                    :value="status"
                                                >{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Source of Income</label>
                                            <select
                                                v-model="application.incomeSource"
                                                required
                                                :readonly="!editingAllowed"
                                                class="form-control"
                                            >
                                                <option
                                                    v-for="source in incomeSourceOptions"
                                                    :key="source"
                                                    :value="source"
                                                >{{ source }}</option>
                                            </select>
                                        </div>
                                        <div
                                            class="col form-group"
                                            v-if="application.incomeSource === 'Salaried'"
                                        >
                                            <label>Monthly Income</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.monthlyIncome"
                                                :readonly="!editingAllowed"
                                            />
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Salaried'">
                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Company</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentCompany"
                                                    :readonly="!editingAllowed"
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Address</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentAddress"
                                                    :readonly="!editingAllowed"
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Landline No.</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentLandlineNumber"
                                                    :readonly="!editingAllowed"
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Designation</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentDesignation"
                                                    :readonly="!editingAllowed"
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Status</label>
                                                <select
                                                    v-model="application.employmentStatus"
                                                    required
                                                    class="form-control"
                                                    :readonly="!editingAllowed"
                                                >
                                                    <option
                                                        v-for="status in employmentStatusOptions"
                                                        :key="status"
                                                        :value="status"
                                                    >{{ status }}</option>
                                                </select>
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Work Tenure</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentWorkTenure"
                                                    :readonly="!editingAllowed"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Self-employed'">
                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Business Name</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessName"
                                                    readonly
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Business Address</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessAddress"
                                                    readonly
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Business Contact No.</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessContactNumber"
                                                    readonly
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Business Operation in Years</label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    v-model="application.businessOperationYears"
                                                    readonly
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Local Remittance'">
                                        <div class="row">
                                            <div class="col-12 form-group">
                                                <label>Originating Country</label>
                                                <select
                                                    v-model="application.originatingCountry"
                                                    :readonly="!editingAllowed"
                                                    class="form-control"
                                                >
                                                    <option
                                                        v-for="country in countries"
                                                        :key="country"
                                                        :value="country"
                                                        required
                                                    >{{ country }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
 
                                    <div class="row" v-if="editingAllowed">
                                        <div class="col-12 form-group">
                                            <label>
                                                Valid ID with Signature Specimen
                                                <a
                                                    :href="application.existingAttachment1"
                                                >(View attached file)</a> -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.validIDModal.show()"
                                                    >Click here for accepted valid IDs</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment1"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row" v-if="editingAllowed">
                                        <div class="col-12 form-group">
                                            <label>
                                                Proof of Income
                                                <a
                                                    :href="application.existingAttachment2"
                                                >(View attached file)</a> -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.proofOfIncomeModal.show()"
                                                    >Click here for accepted proof of income</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment2"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row" v-if="editingAllowed">
                                        <div class="col-12 form-group">
                                            <label>
                                                Proof of Billing
                                                <a
                                                    :href="application.existingAttachment3"
                                                >(View attached file)</a> -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.proofOfBillingModal.show()"
                                                    >Click here for accepted proof of billing</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment3"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row" v-if="editingAllowed">
                                        <div class="col-12 form-group">
                                            <label>
                                                Latest Photo
                                                <a
                                                    v-if="application.existingPhoto"
                                                    :href="application.existingPhoto"
                                                >(View attached file)</a>
                                            </label>
                                            <b-form-file
                                                v-model="application.photo"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                accept="image/jpeg, image/png"
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <h6>Below fields will be filled up by our Credit Analyst</h6>
                                            <hr />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Application Date</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.applicationDate"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Financed Amount</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.financedAmount"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Term (months)</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.term"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Monthly Amortization</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.monthlyAmortization"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>PN Amount</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.PNAmount"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>NOCD Reference No.</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.NOCDReferenceNo"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Branch</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.branchName"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Remarks</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.remarks"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group text-center" v-if="editingAllowed">
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="accepted"
                                            name="checkbox-1"
                                            :value="true"
                                            :unchecked-value="false"
                                        >
                                            In compliance with the Data Privacy Act (DPA) of 2012; and its Implementing
                                            Rules & Regulations (IRR). We will collect, use, process, stores and retain
                                            your personal information for the purpose of providing valuable and useful
                                            services that we believe you might find interesting and beneficial. Where we
                                            consider it necessary or appropriate for the purposes of data storage or
                                            processing or providing any service on our behalf to you, we may
                                            transfer/share your personal information within SCGC, under conditions of
                                            confidentiality and similar levels of security safeguards.
                                        </b-form-checkbox>
                                        <hr />

                                        <router-link
                                            class="btn btn-black rounded-0 text-uppercase mr-2"
                                            :to="`/products/${$route.params.slug}`"
                                        >Go back</router-link>
                                        <button
                                            type="submit"
                                            :disabled="!accepted"
                                            class="btn btn-red rounded-0 text-uppercase"
                                        >Submit</button>
                                    </div>
                                </form>
                            </div>

                            <div
                                class="d-block"
                                v-if="application.orderStatus === 'To Pay' || (application.orderStatus === 'Offer Accepted' && ['Pending', null].includes(order.paymentStatus))"
                            >
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <h4>Pay downpayment</h4>
                                        <hr />
                                        <b-list-group>
                                            <b-list-group-item
                                                href="javascript:void(0)"
                                                @click="paymentMethod = 'Pay Online'"
                                                class="flex-column align-items-start"
                                            >
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">Pay Online</h5>
                                                </div>

                                                <small>Please select your preferred payment option by clicking the empty box below. By selecting your payment option, you will be able to pay online, pay using e-Wallet, or use other payment channel to complete your purchase securely.</small>
                                                <b-form-select
                                                    @change="payOnline"
                                                    v-if="paymentMethod === 'Pay Online'"
                                                    v-model="channel"
                                                    :options="allowedChannels"
                                                ></b-form-select>
                                            </b-list-group-item>

                                            <b-list-group-item
                                                href="javascript:void(0)"
                                                @click="cashOverTheCounter"
                                                class="flex-column align-items-start"
                                            >
                                                <div class="d-flex w-100 justify-content-between">
                                                    <h5 class="mb-1">Cash over the Counter</h5>
                                                </div>

                                                <small>Pay on our branches upon pickup.</small>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <div class="col-md-12 text-center">
                                        <PaymentRequestForm
                                            :paymentRequestValue="paymentRequestValue"
                                            v-if="ready"
                                        />
                                        <div v-if="paymentRequestValue && !ready">
                                            <img src="/img/spinner.gif" height="64" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal
                ref="confirmCancellationModal"
                centered
                hide-footer
                title="Confirm Loan Cancellation"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Are you sure you want to cancel your loan application?</h6>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-right col-md-12">
                            <button
                                class="btn btn-info mr-3"
                                @click="$refs.confirmCancellationModal.hide()"
                            >No</button>
                            <button class="btn btn-danger" @click="cancelApplication">Yes</button>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal
                ref="confirmSubmissionModal"
                centered
                hide-footer
                title="Confirm Loan Application Update"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Are you sure you want to update your loan application?</h6>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-right col-md-12">
                            <button
                                class="btn btn-info mr-3"
                                @click="$refs.confirmSubmissionModal.hide()"
                            >No</button>
                            <button class="btn btn-danger" @click="updateApplication">Yes</button>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal ref="proofOfIncomeModal" centered hide-footer title="Accepted Proof of Income">
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <b>Salaried</b>
                            <ul>
                                <li>Latest One (1) Month Payslip or Certificate of Employment with Compensation</li>
                            </ul>
                            <b>Business (Self-Employed)</b>
                            <ul>
                                <li>Latest ITR/Bank Statement, Sales Receipts</li>
                                <li>Proof of Business Existence</li>
                                <li class="ml-3">
                                    <ul>
                                        <li>Updated Business Permit/Barangay Permit</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal ref="validIDModal" centered hide-footer title="Valid IDs">
                <span style="font-size: 14px; display: inline-block; padding-top: 0px; padding-bottom: 0px;">Sample Photo Reference</span>
                <b-img src="https://firebasestorage.googleapis.com/v0/b/scinnova-imghost.appspot.com/o/800x600-1618886670733.jpg?alt=media&token=d3e4914c-33bf-4d61-a8b8-8db07517dd05" fluid alt="Responsive image"></b-img>
                <span style="color:red;font-style:italic;font-size: 14px; display: inline-block; padding-top: 0px; padding-bottom: 0px;">Note: Please provide 3 signature specimens on each valid ID.
                </span>
                <span style="font-size: 14px; display: inline-block;padding-top: 10px; padding-bottom: 10px;">
                    Please select from any Valid IDs listed below.
                </span>
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <b>Primary Valid IDs</b>
                            <ul>
                                <li
                                    v-for="(validID, index) in primary"
                                    :key="validID"
                                >{{index + 1}}. {{validID}}</li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <b>Secondary Valid IDs</b>
                            <ul>
                                <li
                                    v-for="(validID, index) in secondary"
                                    :key="validID"
                                >{{index + 1}}. {{validID}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal
                ref="proofOfBillingModal"
                centered
                hide-footer
                title="Accepted Proof of Billing"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <ul>
                                <li>Latest Electric Bill</li>
                                <li>Latest Telephone/Internet Bill</li>
                                <li>Latest Water Bill</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal>
        </main>
    </div>
</template>

<script>
import { mapGetters,mapActions } from "vuex";
import { analytics } from "../../main";
import { api } from "../../config";
import PaymentRequestForm from "../../components/Cart/PaymentRequestForm";
import { channels } from "../../channels";
import { countries } from "../../countries";
import { primary,secondary } from "../../validIDs";
import { propertyStatusOptions } from "../../propertyStatusOptions";
import { incomeSourceOptions } from "../../incomeSourceOptions";
import { employmentStatusOptions } from "../../employmentStatusOptions";
import banner from '@/components/ProductBanner';
export default {
    data: () => ({
		application: {},
		order: {},
        details: {},
        ready: false,
        channels,
        allowedChannels: [],
        channel: null,
        paymentRequestValue: null,
        paymentMethod: null,
        propertyStatusOptions,
        incomeSourceOptions,
        employmentStatusOptions,
        countries,
        accepted: false,
        primary,
        secondary
    }),
    computed: {
        ...mapGetters({
            user: "user",
            // paymentMethod: "Cart/paymentMethod"
        }),
        editingAllowed() {
            return [
                "For Document Checking",
                "For Loan Application Update",
            ].includes(this.application.orderStatus);
        },
        subtotal() {
            return parseFloat(parseFloat(this.application.downpayment) + parseFloat(this.application.shipping) + parseFloat(this.application.ltoRegistrationFees))
        },
        total() {
            return parseFloat(this.subtotal + parseFloat(this.application.onlineTransactionFee))
        }
    },
    async mounted() {
        await this.getApplication(this.$route.params.orderId);
    },
    methods: {
        async getApplication(orderId) {
            let request = await fetch(`${api}/applications/${orderId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            let response = await request.json();
            this.application = response.application;

            this.application.existingAttachment1 = this.application.attachment1;
            this.application.existingAttachment2 = this.application.attachment2;
            this.application.existingAttachment3 = this.application.attachment3;
            this.application.existingPhoto = this.application.photo;

            this.application.attachment1 = null;
            this.application.attachment2 = null;
            this.application.attachment3 = null;
            this.application.photo = null;

            await this.getProductVariant(this.application.sku);
            await this.getOrder(orderId);

            //Set allowed payment channels
            this.allowedChannels = [];

            this.channels.forEach((channel, index) => {
                this.allowedChannels.push({
                    label: channel.label,
                    options: [],
                });

                channel.options.forEach((option) => {
                    let data = {
                        value: option.value,
                        text: option.text,
                        max: option.max,
                        disabled: true,
                    };

                    if (
                        data.max === undefined ||
                        (data.max !== undefined &&
                            data.max >=
                                parseFloat(this.application.downpayment) +
                                    parseFloat(this.application.shipping))
                    ) {
                        data.disabled = false;
                    } else {
                        data.html = `<s>${data.text}</s> (Max: ${data.max})`;

                        delete data.text;
                    }

                    this.allowedChannels[index].options.push(data);
                });
            });
		},
		async getOrder(id) {
			let request = await fetch(`${api}/order/explicit/${id}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.order = await request.json();
		},
        async getProductVariant(sku) {
            let request = await fetch(`${api}/products/details/sku/${sku}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            this.details = await request.json();
        },
        async payOnline() {
            this.ready = false;
            this.paymentMethod = "Pay Online"
            await this.addOnlineTransactionFee(this.$route.params.orderId);

            let request = await fetch(`${api}/payments/initialize`, {
                method: "POST",
                body: JSON.stringify({
                    orderId: this.$route.params.orderId,
                    mode: this.paymentMethod,
                    type: "Downpayment",
                    channel: this.channel,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });

            let response = await request.json();
            this.paymentRequestValue = response.base64String;
            this.ready = true;
            await this.getApplication(this.$route.params.orderId)
        },
        async cashOverTheCounter() {
            this.ready = false;

            this.paymentMethod = "Cash over the Counter";
            await this.removeOnlineTransactionFee(this.$route.params.orderId)
            await fetch(`${api}/payments/downpayment`, {
                method: "POST",
                body: JSON.stringify({
                    orderId: this.$route.params.orderId,
                    mode: this.paymentMethod,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            this.$router.push({
                path: '/cart/status/completed',
                query: {
                    orderId: this.$route.params.orderId
                }
            });
        },
        async updateApplication() {
            let data = new FormData();
            this.accepted = false;

            let keys = Object.keys(this.application);
            keys.forEach((k) => {
                data.append(k, this.application[k]);
            });
            data.append("orderId", this.application.orderId);

            await fetch(`${api}/applications`, {
                method: "PUT",
                body: data,
                headers: {
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            analytics.logEvent("updated_loan_application");

            this.$router.push(`/account`);
        },
        async confirmApplicationCancellation() {
            this.$refs.confirmCancellationModal.show();
        },
        async cancelApplication() {
            await fetch(`${api}/applications/cancel`, {
                method: "PUT",
                body: JSON.stringify({
                    orderId: this.application.orderId,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            analytics.logEvent("cancelled_loan_application");

            this.$router.push(`/account`);
        },
        ...mapActions({
            // setPaymentMethod: "Cart/setPaymentMethod",
            addOnlineTransactionFee: "Cart/addOnlineTransactionFee",
            removeOnlineTransactionFee: "Cart/removeOnlineTransactionFee"
        })
    },
    components: {
        PaymentRequestForm,
        banner
    },
};
</script>

<style>
.description {
    margin-top: 50px;
}

image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
}

.gallery li {
    display: inline;
}

.button {
    background-color: rgb(179, 5, 34);
    margin-left: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.textarea {
    margin-left: 10px;
    margin-right: 20px;
}

.btn-margin {
    margin-left: 30px;
    margin-bottom: 20px;
}

.review-sub {
    margin-left: 60px;
    display: block;
}

.review-main {
    margin-left: 0px !important;
}

.query-sub {
    margin-left: 60px;
    display: block;
}

.query-main {
    margin-left: 0px !important;
}

ul:first-child {
    margin-left: 0px !important;
}

ul {
    margin-left: 60px;
    display: block;
}

textarea {
    display: block;
    box-sizing: padding-box;
    overflow: hidden;
    padding: 5px;
    width: 250px;
    font-size: 14px;
    margin: 10px auto;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(black, 0.3);
    border: 0;
}
</style>
