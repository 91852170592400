<template>
	<section class="recent-products products-list products ptb-5">
		<div class="container">
			<h1 class="title text-center text-uppercase">Featured {{ department }}</h1>
			<div v-if="products.length">
				<div class="row mt-5 mb-5">
					<div class="col-12 col-sm-6 col-md-3 text-center" v-for="(product, index) in products" :key="index">
						<router-link :to="`/products/${product.slug}`">
							<div class="product-image">
								<img :src="product.photo" class="img-fluid" />
							</div>
							<h4 class="product-name text-uppercase">{{ product.name }}</h4>
							<p class="product-description">{{ product.shortDescription }}</p>
							<h3 class="product-price">{{ product.price | currencyDisplay }}</h3>
							<p class="thumbnail-promos" v-if="product.department == 'Motorcycles' && (product.LTOFee != 1 || product.freebies != null)">
								<span v-if="product.LTOFee != 1">Free LTO Registration for Loan Payment<br></span>
								<span v-if="product.freebies != null && product.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
								<span v-if="product.freebies != null && product.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
							</p>
						</router-link>
					</div>
				</div>
				<div class="row">
					<div v-if="department == 'Motorcycles'" class="col-md-4 offset-md-4">
						<a :href="`/products/${department.toLowerCase()}`" class="btn btn-block btn-red rounded-0 text-uppercase"
							>View More {{ department }}</a
						>
					</div>
					<div v-else class="col-md-4 offset-md-4">
						<a href="/products/parts&accessories" class="btn btn-block btn-red rounded-0 text-uppercase"
							>View More Parts & Accessories</a
						>
					</div>
				</div>
			</div>
			<div class="container" v-else>
				<div class="row">
					<div
						class="col-12 d-flex flex-row justify-content-center align-items-center"
						style="height: 487px;"
					>
						<img src="/img/spinner.gif" height="100" alt />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import { api } from "../../config";
export default {
	data: () => ({
		products: [],
	}),
	props: ["department", "count"],
	async mounted() {
		await this.getFeaturedProducts();
	},
	methods: {
		async getFeaturedProducts() {
			let request = await fetch(`${api}/products/featured/${this.department}/${this.count}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			let response = await request.json();
			this.products = response.hits;
		},
	},
};
</script>


<style>

    .products-list-item a {
        min-height: 410px;
        min-height: 0;
    }

    .thumbnail-promos {
        margin-top: -25px;
        line-height: 1;
    }
    .thumbnail-promos span {
        color: red;
        font-size: 10px;
    }
</style>
