<template>
	<b-container style="background-color: rgba(0,0,0,.05);">
		<b-row><div class="title p-2">Reviews for {{ product.name }} ({{ reviews.length }})</div></b-row>
		<b-row class="mt-3">
			<b-col col cols="12">
				<b-media v-for="review in paginatedReviews" :key="review.id">
					<template v-slot:aside>
						<b-img blank-color="#ccc" width="64" :alt="review.name" :src="review.photo"></b-img>
					</template>

					<b-media-body class="alert alert-light review-main">
						<h5 class="mt-0">{{ review.name }}</h5>
						<h6 class="mt-0">{{ review.message }}</h6>
						<small class="text-muted">{{ review.createdAt | readableDateTime }}</small>
						<br/>
						<b-button 
							v-show="review.replies.length >= 5"
							variant="outline-secondary" 
							v-b-toggle="'collapse-'+review.reviewId"
						>
							See more replies...
						</b-button>

						<b-collapse :visible="review.replies.length < 5" role="tabpanel" :id="'collapse-'+review.reviewId">
							<b-media v-for="(reply, index) in review.replies" :key="index" class="mt-3">
								<template v-slot:aside>
									<b-img blank-color="#ccc" width="64" :alt="review.name" :src="reply.photo"></b-img>
								</template>
								<b-media-body class="alert alert-light" >
									<h5 class="mt-0">{{ reply.name }}</h5>
									<h6 class="mt-0">{{ reply.message }}</h6>
									<small class="text-muted">
										{{ reply.createdAt | readableDateTime }}
									</small>
								</b-media-body>
							</b-media>
							<b-media v-show="user.userId !== review.user || (review.replies.length && user.userId === review.user)">
								<b-media-body class="alert alert-light">
									<input
										v-model="newReplyMessage"
										placeholder="Enter your reply here"
										class="col-10"
										type="text"
									/>
									<b-button 
										block
										variant="danger"
										class="mt-3 rounded-0 text-uppercase col-2"
										@click="addReply(review.reviewId)"
									>
										Submit
									</b-button>
								</b-media-body>
							</b-media>
						</b-collapse>
					</b-media-body>
				</b-media>

				<div v-show="!reviews.length || reviews.length < 1" class="alert alert-light" role="alert">
					No reviews available
				</div>
			</b-col>
		</b-row>
		<b-row v-if="purchased && unreviewed">
			<b-col col cols="12">
				<b-button block variant="danger" class="btn-marging" id="review-btn"
					v-b-modal="'reviewFormModal'"
				> Post a Review </b-button>

				<b-modal id="reviewFormModal" title="We'd love to hear from you!"
					@ok="addReview()"
				>
					<h6>Message:</h6>
					<textarea
						class="col-12 autoExpand"
						rows="3"
						data-min-rows="3"
						placeholder="What do you think of this product?"
						v-model="newReviewMessage"
					></textarea>
				</b-modal>
			</b-col>
		</b-row>
		<b-row>
			<b-col col cols="12">
				<paginate
						v-show="reviews.length"
						class="pagination justify-content-end mr-5"
						style="margin-bottom: 10px; margin-top: 10px;"
						:page-count="pageReviewCount"
						container-class="pagination"
						page-class="page-item"
						page-link-class="page-link"
						prev-text="Previous"
						prev-class="page-item"
						prev-link-class="page-link"
						next-text="Next"
						next-class="page-item"
						next-link-class="page-link"
						:click-handler="paginateReviews"
					></paginate>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
import Noty from "noty";
import { api } from "../../config";
import Paginate from "vuejs-paginate"
import { mapGetters } from "vuex"
import { isEmpty } from "lodash"

export default {
	components: {
		Paginate,
	},
	props: {
		product: Object
	},
	data() {
		return {
			newReplyMessage: null,
			newReviewMessage: null,
			reviews: [],
			paginatedReviews: [],
			purchased: false,
			unreviewed: true,
			pageReviewCount: 0,
			pageReviewSize: 5
		}
	},
	computed: {
		...mapGetters({
			user: "user",
			token: "token"
		})
	},
	watch: {
		product: {
			immediate: true,
			handler: function(value) {
				if(isEmpty(value)) return

				this.fetchReviews();
			}
		}
	},
	methods: {
		async fetchReviews() {
			let request = await fetch(`${api}/products/reviews/${this.product.sku}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.token}`
				},
			});
			let response = await request.json();
			this.reviews = response.reviews;
			this.purchased = response.completedPurchase;
			this.unreviewed = response.unreviewed;

			this.pageReviewCount = Math.floor(this.reviews.length / this.pageReviewSize) + 1;
			this.paginateReviews(1);
		},
		async addReview() {
			let self = this
			let response = await fetch(`${api}/products/reviews`, {
				method: "POST",
				body: JSON.stringify({
					user: this.user.userId,
					name: this.user.socialName,
					photo: this.user.photo,
					sku: this.product.sku,
					product: this.product.name,
					message: this.newReviewMessage,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.token}`
				},
			});

			if (response.status === 200) {
				new Noty({
					type: "success",
					theme: "semanticui",
					layout: "bottomRight",
					text: "comment was posted",
					timeout: 5000,
				}).show();
				setTimeout(() => {
					self.fetchReviews()
				}, 1000);
			} else {
				alert("You have already posted a review!");
				self.fetchReviews()
			}

			this.newReviewMessage = null
		},
		async addReply(reviewId) {
			let self = this
			let response = await fetch(`${api}/products/reviews/feedback/reply`, {
				method: "POST",
				body: JSON.stringify({
					reviewId,
					user: this.user.userId,
					name: this.user.socialName,
					photo: this.user.photo,
					sku: this.product.sku,
					product: this.product.name,
					message: this.newReplyMessage,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.token}`
				},
			});

			if (response.status === 200) {
				new Noty({
					type: "success",
					theme: "semanticui",
					layout: "bottomRight",
					text: "Reply was successfully posted.",
					timeout: 5000,
				}).show();
				setTimeout(() => {
					self.newReplyMessage = null
					self.fetchReviews()
				}, 1000);
			}
		},
		paginateReviews(page) {
			let currentPage = page;
			let size = 5;
			let start = currentPage * size - size;
			let end = start + size;

			this.paginatedReviews = this.reviews.slice(start, end);
		},
	}
}
</script>