<template>
    <div class="mb-5">
        <banner title="Apply for a loan" />
        <main role="main">
            <div class="products single-product pt-5">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12 col-md-5">
                            <div class="product-image mb-3 zoom">
                                <img class="img-fluid" :src="details.photo" />
                            </div>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <h2 class="product-name text-uppercase mb-3">{{ details.name }}</h2>
                                    <h5>{{ details.variantValue }}</h5>
                                    <p>{{ details.category }}</p>

                                    <h3
                                        class="product-price mb-3"
                                    >{{ details.price | currencyDisplay }}</h3>
                                </div>
                            </div>
                            <div class="row">
                                <p class="product-description text-danger" v-if="details.LTOFee != 1 || details.freebies != null">
                                    <span v-if="details.LTOFee != 1 || details.freebies != null">For Single Order Only:<br></span>
                                    <span v-if="details.LTOFee != 1">Free LTO Registration for Loan Payment<br></span>
                                    <span v-if="details.freebies != null && details.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
                                    <span v-if="details.freebies != null && details.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="d-block">
                                <form
                                    @submit="$refs.confirmSubmissionModal.show()"
                                    v-on:submit.prevent
                                >
                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Firstname</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.firstname"
                                                readonly
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Lastname</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.lastname"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Middle Name</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                required
                                                v-model="application.middlename"
                                                :readonly="application.middlename && application.middlename.length"
                                            />
                                        </div>
                                        <div class="col-6 form-group">
                                            <label>Suffix</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.suffix"
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                class="form-control"
                                                v-model="application.email"
                                                readonly
                                            />
                                        </div>

                                        <div class="col-6 form-group">
                                            <label>Contact No.</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.contactNumber"
                                                readonly
                                            />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>Registered Address</label>
                                            <textarea
                                                class="form-control"
                                                v-model="application.address"
                                                readonly
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>Shipping Address</label>
                                            <textarea
                                                class="form-control"
                                                v-model="order.deliveryAddress"
                                                readonly
                                            ></textarea>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6 form-group">
                                            <label>Age</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                v-model="application.age"
                                            />
                                        </div>

                                        <div class="col-6 form-group">
                                            <label>Property Status</label>
                                            <select
                                                v-model="application.propertyStatus"
                                                required
                                                class="form-control"
                                            >
                                                <option
                                                    v-for="status in propertyStatusOptions"
                                                    :key="status"
                                                    :value="status"
                                                >{{ status }}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col form-group">
                                            <label>Source of Income</label>
                                            <select
                                                v-model="application.incomeSource"
                                                required
                                                class="form-control"
                                            >
                                                <option
                                                    v-for="source in incomeSourceOptions"
                                                    :key="source"
                                                    :value="source"
                                                >{{ source }}</option>
                                            </select>
                                        </div>
                                        <div
                                            class="col form-group"
                                            v-if="application.incomeSource === 'Salaried'"
                                        >
                                            <label>Monthly Income</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                required
                                                v-model="application.monthlyIncome"
                                            />
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Salaried'">
                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Company</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentCompany"
                                                    required
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Address</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentAddress"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Landline No.</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentLandlineNumber"
                                                    required
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Designation</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentDesignation"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Employment Status</label>
                                                <select
                                                    v-model="application.employmentStatus"
                                                    required
                                                    class="form-control"
                                                >
                                                    <option
                                                        v-for="status in employmentStatusOptions"
                                                        :key="status"
                                                        :value="status"
                                                    >{{ status }}</option>
                                                </select>
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Employment Work Tenure</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.employmentWorkTenure"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Self Employed'">
                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Business Name</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessName"
                                                    required
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Business Address</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessAddress"
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-6 form-group">
                                                <label>Business Contact No.</label>
                                                <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="application.businessContactNumber"
                                                    required
                                                />
                                            </div>

                                            <div class="col-6 form-group">
                                                <label>Business Operation in Years</label>
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    v-model="application.businessOperationYears"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="application.incomeSource === 'Local Remittance'">
                                        <div class="row">
                                            <div class="col-12 form-group">
                                                <label>Originating Country</label>
                                                <select
                                                    v-model="application.originatingCountry"
                                                    class="form-control"
                                                >
                                                    <option
                                                        v-for="country in countries"
                                                        :key="country"
                                                        :value="country"
                                                        required
                                                    >{{ country }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>
                                                Valid ID with Signature Specimen -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.validIDModal.show()"
                                                    >Click here for accepted valid IDs</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment1"
                                                :state="Boolean(application.attachment1)"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                required
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>
                                                Proof of Income -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.proofOfIncomeModal.show()"
                                                    >Click here for accepted proof of income</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment2"
                                                :state="Boolean(application.attachment2)"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                required
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>
                                                Proof of Billing -
                                                <small>
                                                    <a
                                                        class="text-primary"
                                                        href="javascript:void(0)"
                                                        @click="$refs.proofOfBillingModal.show()"
                                                    >Click here for accepted proof of billing</a>
                                                </small>
                                            </label>
                                            <b-form-file
                                                v-model="application.attachment3"
                                                :state="Boolean(application.attachment3)"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                required
                                            ></b-form-file>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12 form-group">
                                            <label>Latest Photo</label>
                                            <b-form-file
                                                v-model="application.photo"
                                                :state="Boolean(application.photo)"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                accept="image/jpeg, image/png"
                                            >
                                                required
                                                >
                                            </b-form-file>
                                        </div>
                                    </div>

                                    <div class="form-group text-center">
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            v-model="accepted"
                                            name="checkbox-1"
                                            :value="true"
                                            :unchecked-value="false"
                                        >
                                            In compliance with the Data Privacy Act (DPA) of 2012; and its Implementing
                                            Rules & Regulations (IRR). We will collect, use, process, stores and retain
                                            your personal information for the purpose of providing valuable and useful
                                            services that we believe you might find interesting and beneficial. Where we
                                            consider it necessary or appropriate for the purposes of data storage or
                                            processing or providing any service on our behalf to you, we may
                                            transfer/share your personal information within SCGC, under conditions of
                                            confidentiality and similar levels of security safeguards.
                                        </b-form-checkbox>
                                        <hr />

                                        <router-link
                                            class="btn btn-black rounded-0 text-uppercase mr-2"
                                            :to="`/products/${$route.params.slug}`"
                                        >Go back</router-link>
                                        <button
                                            type="submit"
                                            :disabled="
												!accepted ||
													application.attachment1 === null ||
													application.attachment2 === null ||
													application.attachment3 === null ||
													application.photo === null
											"
                                            class="btn btn-red rounded-0 text-uppercase"
                                        >Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal
                ref="confirmSubmissionModal"
                centered
                hide-footer
                title="Confirm Loan Application Submission"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <h6>Are you sure you want to submit your loan application?</h6>
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-right col-md-12">
                            <button class="btn btn-info mr-3" @click="cancelSubmission">No</button>
                            <button class="btn btn-danger" @click="sendApplication">Yes</button>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal ref="validIDModal" centered hide-footer title="Valid IDs">
                <span style="font-size: 14px; display: inline-block; padding-top: 0px; padding-bottom: 0px;">Sample Photo Reference</span>
                <b-img src="https://firebasestorage.googleapis.com/v0/b/scinnova-imghost.appspot.com/o/800x600-1618886670733.jpg?alt=media&token=d3e4914c-33bf-4d61-a8b8-8db07517dd05" fluid alt="Responsive image"></b-img>
                <span style="color:red;font-style:italic;font-size: 14px; display: inline-block; padding-top: 0px; padding-bottom: 0px;">Note: Please provide 3 signature specimens on each valid ID.
                </span>
                <span style="font-size: 14px; display: inline-block;padding-top: 10px; padding-bottom: 10px;">
                    Please select from any Valid IDs listed below.
                </span>
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <b>Primary Valid IDs</b>
                            <ul>
                                <li
                                    v-for="(validID, index) in primary"
                                    :key="validID"
                                >{{index + 1}}. {{validID}}</li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <b>Secondary Valid IDs</b>
                            <ul>
                                <li
                                    v-for="(validID, index) in secondary"
                                    :key="validID"
                                >{{index + 1}}. {{validID}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal> 

            <b-modal ref="proofOfIncomeModal" centered hide-footer title="Accepted Proof of Income">
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <b>Salaried</b>
                            <ul>
                                <li>Latest One (1) Month Payslip or Certificate of Employment with Compensation</li>
                            </ul>
                            <b>Business (Self-Employed)</b>
                            <ul>
                                <li>Latest ITR/Bank Statement, Sales Receipts</li>
                                <li>Proof of Business Existence</li>
                                <li class="ml-3">
                                    <ul>
                                        <li>Updated Business Permit/Barangay Permit</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal>

            <b-modal
                ref="proofOfBillingModal"
                centered
                hide-footer
                title="Accepted Proof of Billing"
            >
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-12">
                            <ul>
                                <li>Latest Electric Bill</li>
                                <li>Latest Telephone/Internet Bill</li>
                                <li>Latest Water Bill</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </b-modal>
        </main>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { analytics } from "../../main";
import { api } from "../../config";
import { countries } from "../../countries";
import { primary,secondary } from "../../validIDs";
import { propertyStatusOptions } from "../../propertyStatusOptions";
import { incomeSourceOptions } from "../../incomeSourceOptions";
import { employmentStatusOptions } from "../../employmentStatusOptions";
import banner from '@/components/ProductBanner';
export default {
    components: {
        banner
    },
    data: () => ({
        details: {
            price: 0,
        },
        variants: [],
        application: {
            firstname: "",
            lastname: "",
            middlename: "",
            suffix: "",
            email: "",
            contactNumber: "",
            address: "",
            age: "",
            propertyStatus: "",
            monthlyIncome: 0,
            incomeSource: "",
            productName: "",
            user: "",
            attachment1: null,
            attachment2: null,
            attachment3: null,
            photo: null,
            employmentCompany: "",
            employmentAddress: "",
            employmentLandlineNumber: "",
            employmentDesignation: "",
            employmentStatus: "",
            employmentWorkTenure: "",
            businessName: "",
            businessAddress: "",
            businessContactNumber: "",
            businessOperationYears: "",
            originatingCountry: null,
        },
        propertyStatusOptions,
        incomeSourceOptions,
        employmentStatusOptions,
        accepted: false,
        countries,
        primary,
        secondary
    }),
    computed: {
        ...mapGetters({
            user: "user",
            order: "Cart/order",
            loggedIn: "loggedIn",
        }),
    },
    async mounted() {
        await this.getProductVariant(this.$route.params.slug);

        this.application.firstname = this.user.firstname
            ? this.user.firstname
            : "";
        this.application.lastname = this.user.lastname
            ? this.user.lastname
            : "";
        this.application.middlename = this.user.middlename
            ? this.user.middlename
            : "";
        this.application.email = this.user.email ? this.user.email : "";
        this.application.contactNumber = this.user.contactNumber
            ? this.user.contactNumber
            : "";
        this.application.address = `${this.user.streetAddress}, ${this.user.barangay}, ${this.user.city}, ${this.user.province}`;
    },
    methods: {
        async getProductVariant(slug) {
            let request = await fetch(`${api}/products/details/${slug}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            this.details = await request.json();
            this.application.productName = this.details.name;
            this.application.sku = this.details.sku;
        },
        async sendApplication() {
            let data = new FormData();
            this.accepted = false;

            let keys = Object.keys(this.application);
            keys.forEach((k) => {
                data.append(k, this.application[k]);
            });
            data.append("orderId", this.order.orderId);

            await fetch(`${api}/applications`, {
                method: "POST",
                body: data,
                headers: {
                    Authorization: `Bearer ${this.$store.getters.token}`,
                },
            });
            analytics.logEvent("submitted_loan_application");
            this.$store.dispatch("Cart/getOrder");
            this.$router.push("/applications/status");
        },
        cancelSubmission() {
            analytics.logEvent("cancelled_loan_application_submission");
            this.$refs.confirmSubmissionModal.hide();
        },
    },
};
</script>

<style>
.description {
    margin-top: 50px;
}

image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
}

.gallery li {
    display: inline;
}

.button {
    background-color: rgb(179, 5, 34);
    margin-left: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.textarea {
    margin-left: 10px;
    margin-right: 20px;
}

.btn-margin {
    margin-left: 30px;
    margin-bottom: 20px;
}

.review-sub {
    margin-left: 60px;
    display: block;
}

.review-main {
    margin-left: 0px !important;
}

.query-sub {
    margin-left: 60px;
    display: block;
}

.query-main {
    margin-left: 0px !important;
}

ul:first-child {
    margin-left: 0px !important;
}

ul {
    margin-left: 60px;
    display: block;
}

textarea {
    display: block;
    box-sizing: padding-box;
    overflow: hidden;
    padding: 5px;
    width: 250px;
    font-size: 14px;
    margin: 10px auto;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(black, 0.3);
    border: 0;
}
</style>
