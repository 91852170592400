import { render, staticRenderFns } from "./SortByField.vue?vue&type=template&id=6c217876"
import script from "./SortByField.vue?vue&type=script&lang=js"
export * from "./SortByField.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports