<template>
    <section
        :class="'sub-banner d-flex align-items-center justify-content-center ' + [alt_banner]"
    >
        <div class="container page-title text-center">
            <h1>{{title}}</h1>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Enter Title here...'
        },
        alt_banner: {
            type: String,
            default: ''
        }
    }
}
</script>