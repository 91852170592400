<template>
	<div>
		<banner title="Compare Specs" />
		<main role="main">
			<div class="container">
				<div class="row" v-if="!ready">
					<div class="col-12 text-center mt-5">
						<img src="/img/spinner.gif" height="128" />
					</div>
				</div>
				<div class="row no-gutters" v-else>
					<div class="col-md-4 col-xs-12" v-for="(entry, index) in entries" :key="entry.sku">
						<div class="product-image mb-3">
							<img :src="entry.photo" class="img-fluid" height="300" :alt="entry.name" />
							<h2 class="text-center text-truncate">
								<span v-if="!entry.empty">{{ entry.name }}</span>
								<span v-else>-</span>
							</h2>

							<h2 class="text-center">
								<span v-if="!entry.empty">{{ entry.price | currencyDisplay }}</span>
								<span v-else>-</span>
							</h2>
							<b-row>
								<b-col cols="10">
									<router-link
										v-if="entry.empty"
										to="/products/motorcycles"
										class="btn btn-outline-primary btn-block"
									>Add to compare</router-link>
									<b-button
										v-else
										block
										variant="outline-danger"
										@click="removeFromComparison(entry, index)"
									>Remove from comparison</b-button>
								</b-col>
							</b-row>
						</div>
						<table class="table">
							<tbody
								v-for="(specificationSectionValue, specificationSectionKey) in entry.specification"
								:key="specificationSectionKey"
							>
								<tr style="background-color: rgba(0,0,0,.05);">
									<th scope="col" colspan="2">
										<span v-show="index === 0">{{ specificationSectionKey }}</span>
									</th>
								</tr>
								<tr v-for="(value, property) in specificationSectionValue" :key="property">
									<td>
										<span v-show="index === 0">{{ property }}</span>
									</td>
									<td class="text-center">{{ value }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { api } from "../../config";
import { mapGetters } from "vuex";
import Noty from "noty";
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	},
	data: () => ({
		entries: [],
		ready: false,
	}),
	computed: {
		...mapGetters({
			comparison: "Cart/comparison",
		}),
	},
	mounted() {
		this.$store.dispatch("Cart/getComparison");
		setTimeout(() => {
			this.compare();
		}, 1500);

		window.addEventListener("resize", () => {
			this.resizeTable();
		});
	},
	methods: {
		resizeTable() {
			let tables = document.getElementsByTagName("table");

			let firstTableRows = tables[0]
				? tables[0].getElementsByTagName("tr")
				: null;
			let secondTableRows = tables[1]
				? tables[1].getElementsByTagName("tr")
				: null;
			let thirdTableRows = tables[2]
				? tables[2].getElementsByTagName("tr")
				: null;

			for (let i = 0; i < firstTableRows.length; i++) {
				let firstTablecell = firstTableRows[i].getElementsByTagName(
					"td"
				)[0]
					? firstTableRows[i].getElementsByTagName("td")[0]
					: firstTableRows[i].getElementsByTagName("th")[0];

				let secondTableCell = secondTableRows[i].getElementsByTagName(
					"td"
				)[0]
					? secondTableRows[i].getElementsByTagName("td")[0]
					: secondTableRows[i].getElementsByTagName("th")[0];

				let thirdTableCell = thirdTableRows[i].getElementsByTagName(
					"td"
				)[0]
					? thirdTableRows[i].getElementsByTagName("td")[0]
					: thirdTableRows[i].getElementsByTagName("th")[0];

				let cells = [
					firstTablecell.offsetHeight,
					secondTableCell.offsetHeight,
					thirdTableCell.offsetHeight,
				];
				cells.sort((a, b) => {
					return b - a;
				});

				firstTablecell.height = cells[0];
				secondTableCell.height = cells[0];
				thirdTableCell.height = cells[0];
			}
		},
		async compare() {
			await this.asyncForEach(this.comparison, async (entry) => {
				let blank = this.createEmptyEntry(this.entries[0]);
				let index = this.entries.findIndex((e) => e.sku === entry);
				if (index < 0) {
					let request = await fetch(
						`${api}/products/details/sku/${entry}`,
						{
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					let response = await request.json();
					response.specification = response.longDescription
						? JSON.parse(response.longDescription)
						: blank.specification;
					response.empty = false;

					this.entries.push(response);
				}
			});

			if (this.comparison.length <= 2) {
				let empty = 3 - this.comparison.length;
				for (let i = 0; i < empty; i++) {
					let clone = this.createEmptyEntry(this.entries[0]);
					clone.photo =
						"https://i.postimg.cc/cL9hJKrr/motorcycle.png";
					clone.empty = true;
					this.entries.push(clone);
				}
			}

			this.ready = true;
			setTimeout(() => {
				this.resizeTable();
			}, 1500);
		},
		removeFromComparison(entry, index) {
			this.$store.dispatch("Cart/removeFromComparison", entry.sku);

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${entry.name} was removed from comparison`,
				timeout: 5000,
			}).show();

			this.entries.splice(index, 1);
			this.$store.dispatch("Cart/getComparison");
			this.compare();
		},
		async asyncForEach(array, callback) {
			for (let index = 0; index < array.length; index++) {
				await callback(array[index], index, array);
			}
		},
		createEmptyEntry(source) {
			var o = Array.isArray(source) ? [] : {};
			for (var key in source) {
				if (source.hasOwnProperty(key)) {
					var t = typeof source[key];
					o[key] =
						t == "object"
							? this.createEmptyEntry(source[key])
							: { string: "", number: 0, boolean: false }[t];
				}
			}
			return o;
		},
	},
};
</script>

<style></style>
