<template>
	<form name="form1" method="post" :action="url">
		<input name="paymentrequest" id="paymentrequest" v-model="paymentRequestValue" type="hidden" required />
		<input type="submit" class="btn btn-red" value="Proceed to Payment" />
	</form>
</template>

<script>
export default {
	props: ["paymentRequestValue"],
	data: () => ({
		url: process.env.VUE_APP_PAYNAMICS_GATEWAY_URL,
	}),
};
</script>

<style></style>
