import { api } from "../config";
import { channels } from "../channels";
import { db } from "../main";
import axios from 'axios';
import _ from "lodash";


export const Cart = {
	namespaced: true,
	state: {
		order: {},
		entries: [],
		entriesQuantity: 0,
		activeEntry: {
			quantity: 0,
		},
		motorcyclesOnly: false,
		partsOnly: false,
		comparison: [],
		shippingMethod: null,
		deliveryAddress: "",
		deliveryAllowed: false,
		deliveryLocationCovered: false,
		paymentMethod: null,
		onlineTransactionFee: 0,
		allowedChannels: [],
		availableBranches: [],
		ready: [],
		initializing: false,
		nearestBranchOnDeliveryAddress: {},
		hub: {},
		chargesReady: 0,
		ltoRegistrationFee: 0,
	},
	mutations: {
		SET_ORDER(state, order) {
			state.order = order;
		},
		SET_ORDER_ENTRIES(state, entries) {
			state.entries = entries;
		},
		SET_ACTIVE_ORDER_ENTRY(state, entry) {
			state.activeEntry = entry;
		},
		SET_ACTIVE_ORDER_QUANTITY(state, quantity) {
			state.activeEntry.quantity = quantity;
		},
		SET_COMPARISON(state, comparison) {
			state.comparison = comparison;
		},
		ADD_COMPARISON(state, product) {
			let comparison = localStorage.getItem("comparison") ? JSON.parse(localStorage.getItem("comparison")) : [];
			if (comparison.length < 3) {
				comparison.push(product);
			}
			state.comparison = comparison;
			localStorage.setItem("comparison", JSON.stringify(comparison));
		},
		REMOVE_COMPARISON(state, product) {
			let comparison = JSON.parse(localStorage.getItem("comparison"));
			let index = comparison.indexOf(product);
			comparison.splice(index, 1);
			state.comparison = comparison;
			localStorage.setItem("comparison", JSON.stringify(comparison));
		},
		RESET_SHIPPING_METHOD(state) {
			state.shippingMethod = null;
		},
		SET_SHIPPING_METHOD(state, method) {
			state.shippingMethod = method;
		},
		SET_LTO_REGISTRATION(state, method) {
			state.ltoRegistrationFee = method;
		},
		SET_PAYMENT_METHOD(state, method) {
			state.paymentMethod = method;
		},
		SET_ONLINE_TRANSACTION_FEE(state, fee) {
			state.onlineTransactionFee = fee;
		},
		SET_ALLOWED_CHANNELS(state, channels) {
			state.allowedChannels = channels;
		},
		SET_DELIVERY_ADDRESS(state, address) {
			state.deliveryAddress = address;
		},
		SET_DELIVERY_ALLOWED(state, allowed) {
			state.deliveryAllowed = allowed;
		},
		SET_MOTORCYCLES_ONLY(state, payload) {
			state.motorcyclesOnly = payload;
		},
		SET_PARTS_ONLY(state, payload) {
			state.partsOnly = payload;
		},
		SET_DELIVERY_LOCATION_COVERED(state, payload) {
			state.deliveryLocationCovered = payload;
		},
		SET_AVAILABLE_BRANCHES(state, payload) {
			state.availableBranches = payload;
		},
		SET_CART_READY(state, payload) {
			state.ready = payload;
		},
		SET_CART_INITIALIZING(state, payload) {
			state.initializing = payload;
		},
		SET_NEAREST_BRANCH_DELIVERY_ADDRESS(state, nearestBranchOnDeliveryAddress) {
			state.nearestBranchOnDeliveryAddress = nearestBranchOnDeliveryAddress;
		},
		SET_HUB(state, payload) {
			state.hub = payload;
		},
		SET_CHARGES(state, payload) {
			state.chargesReady = payload;
		},
	},
	actions: {
		async getOrder({ commit, rootState, dispatch }, payload) {
			commit("SET_CART_INITIALIZING", true)
			let request = await fetch(`${api}/cart/initialize`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			let response = await request.json();
			let orderDoc = await db
				.collection("cart")
				.doc(response.order.orderId)
				.get();
			let order = orderDoc.data();
			let entriesCollection = await db
				.collection("cart")
				.doc(response.order.orderId)
				.collection("entries")
				.where("status", "==", "Pending")
				.get();
			let entries = [];
			entriesCollection.forEach((entry) => {
				entries.push(entry.data());
			});

			let allowedChannels = [];
			channels.forEach((channel, index) => {
				allowedChannels.push({
					label: channel.label,
					options: [],
				});

				channel.options.forEach((option) => {
					let data = {
						value: option.value,
						text: option.text,
						max: option.max,
						disabled: true,
					};

					if (data.max === undefined || (data.max !== undefined && data.max >= order.total)) {
						data.disabled = false;
					} else {
						data.html = `<s>${data.text}</s> (Max: ${data.max})`;

						delete data.text;
					}

					allowedChannels[index].options.push(data);
				});
			});
			commit("SET_ORDER", order);
			commit("SET_ORDER_ENTRIES", entries);
			commit("SET_SHIPPING_METHOD", order.shippingMethod);
			commit("SET_ALLOWED_CHANNELS", allowedChannels);
			dispatch("checkDeliveryAvailability", entries);
			commit("SET_CART_INITIALIZING", false)
		},
		async getLoanOrder({ commit, rootState, dispatch }, payload) {
			console.log(payload,dispatch)
			console.log(rootState.Cart.order.shipping)
			commit("SET_CART_INITIALIZING", true)

			let req = await fetch(`${api}/order/loan/ltoRegistration/add`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload.orderId,
					ltoFee: payload.entries[0].ltoRegistrationFees,
					shipping: rootState.Cart.order.shipping
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			let res = await req.json();
			console.log(res)

			let request = await fetch(`${api}/cart/initialize`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			let response = await request.json();
			let orderDoc = await db
				.collection("cart")
				.doc(response.order.orderId)
				.get();
			let order = orderDoc.data();
			let entriesCollection = await db
				.collection("cart")
				.doc(response.order.orderId)
				.collection("entries")
				.where("status", "==", "Pending")
				.get();
			let entries = [];
			entriesCollection.forEach((entry) => {
				entries.push(entry.data());
			});

			let allowedChannels = [];
			channels.forEach((channel, index) => {
				allowedChannels.push({
					label: channel.label,
					options: [],
				});

				channel.options.forEach((option) => {
					let data = {
						value: option.value,
						text: option.text,
						max: option.max,
						disabled: true,
					};

					if (data.max === undefined || (data.max !== undefined && data.max >= order.total)) {
						data.disabled = false;
					} else {
						data.html = `<s>${data.text}</s> (Max: ${data.max})`;

						delete data.text;
					}

					allowedChannels[index].options.push(data);
				});
			});
			commit("SET_ORDER", order);
			commit("SET_ORDER_ENTRIES", payload.entries);
			commit("SET_SHIPPING_METHOD", order.shippingMethod);
			commit("SET_ALLOWED_CHANNELS", allowedChannels);
			dispatch("checkDeliveryAvailability", entries);
			commit("SET_CART_INITIALIZING", false)
		},
		sync({state, rootState}, payload) {
			return fetch(`${api}/cart/sync`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload ? payload: state.order.orderId ,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
		},
		// async checkDeliveryAvailability({ commit, dispatch, rootState }, payload) {
		async checkDeliveryAvailability({ commit, dispatch, rootState }, payload) {
			let motorcyclesOnly = false;
			let partsOnly = false;
			let deliveryAllowed = false;
			let checkYourLocation = false;
			let regionsAllowed= ["NCR","09","11"]

			if (
				payload.filter((e) => e.department === "Motorcycles").length &&
				payload.filter((e) => e.department !== "Motorcycles").length === 0
			) {
				motorcyclesOnly = true;
				partsOnly = false;
			}

			if (
				payload.filter((e) => e.department !== "Motorcycles").length &&
				payload.filter((e) => e.department === "Motorcycles").length === 0
			) {
				partsOnly = true;
				motorcyclesOnly = false;
			}

			if (!partsOnly && !motorcyclesOnly) {
				deliveryAllowed = false;
			} else {
				deliveryAllowed = true;
			}

			commit("SET_DELIVERY_ALLOWED", deliveryAllowed);
			commit("SET_MOTORCYCLES_ONLY", motorcyclesOnly);
			commit("SET_PARTS_ONLY", partsOnly);

			if (rootState.user.secondaryAddress) {
				checkYourLocation = !regionsAllowed.includes(rootState.user.region) && !regionsAllowed.includes(rootState.user.secondaryAddress.region) ? false : true
			} else {
				checkYourLocation = !regionsAllowed.includes(rootState.user.region) ? false : true
			}
			
			commit("SET_DELIVERY_LOCATION_COVERED", checkYourLocation);
			if (!deliveryAllowed || !checkYourLocation) {
				dispatch("resetShippingMethod");
			}
			dispatch("getBranches");
		},
		async setActiveOrderEntry({ commit }, payload) {
			commit("SET_ACTIVE_ORDER_ENTRY", payload);
		},
		async setActiveOrderEntryQuantity({ commit }, payload) {
			commit("SET_ACTIVE_ORDER_QUANTITY", payload);
		},
		async updateActiveOrderEntryQuantity({ rootState, state, dispatch }) {
			await fetch(`${api}/order/entries/update`, {
				method: "POST",
				body: JSON.stringify({
					sku: state.activeEntry.sku,
					orderId: state.activeEntry.orderId,
					price: state.activeEntry.price,
					discountCode: state.activeEntry.discountCode,
					status: state.activeEntry.status,
					quantity: state.activeEntry.quantity,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			await dispatch("getOrder",state.order.orderId);
		},
		async removeOrderEntry({ dispatch, rootState, state }, payload) {
			await fetch(`${api}/order/entries/remove`, {
				method: "DELETE",
				body: JSON.stringify({
					sku: payload.sku,
					orderId: payload.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});

			await dispatch("getOrder",state.order.orderId);
		},
		async getBranches({ rootState, state, commit }, payload) {
			let url = `${api}/branches`;
			let brands = "";
			state.entries.forEach((entry) => {
				brands += `&brands[]=${entry.manufacturer}`;
			});

			if (state.motorcyclesOnly && state.shippingMethod == 'delivery') {
				url += `?mcDelivery=1`;
			} 
			else if (state.motorcyclesOnly && state.shippingMethod == 'pickup') {
				url += `?mcPickup=1`;
			}
			else if(state.partsOnly && state.shippingMethod == 'delivery') {
				url += `?itemDelivery=1`;
			}
			else if(state.partsOnly && state.shippingMethod == 'pickup') {
				url += `?itemPickup=1`;
			}
			else if((!state.motorcyclesOnly && !state.partsOnly) && state.shippingMethod == 'delivery') {
				url += `?comboDelivery=1`;
			}
			else if((!state.motorcyclesOnly && !state.partsOnly) && state.shippingMethod == 'pickup') {
				url += `?comboPickup=1`;
			}
			else {
				url += "?mcDelivery=1&mcPickup=1&itemDelivery=1&itemPickup=1&comboDelivery=1&comboPickup=1"
			}
			
			if(payload) {
				if(state.shippingMethod == 'delivery' && payload) {
					if(payload.city) {
						url += `&city=${payload.city}`
					}
	
					if(payload.region) {
						url += `&region=${payload.region}`
					}
				}
			}
			url += brands
 
			let request = await fetch(`${url}`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});

			let response = await request.json();
			commit("SET_AVAILABLE_BRANCHES", response.data);
			commit("SET_CART_READY", true);
			return response.data
		},
		async getComparison({ commit }) {
			let comparison = [];
			if (localStorage.getItem("comparison") !== null) {
				comparison = JSON.parse(localStorage.getItem("comparison"));
			} else {
				localStorage.setItem("comparison", JSON.stringify([]));
			}
			commit("SET_COMPARISON", comparison);
		},
		async addToComparison({ commit }, payload) {
			commit("ADD_COMPARISON", payload);
		},
		async removeFromComparison({ commit }, payload) {
			commit("REMOVE_COMPARISON", payload);
		},
		async resetShippingMethod({ commit, dispatch, state }) {
			if (state.shippingMethod === "delivery") {
				await dispatch("removeShippingFee");
				commit("RESET_SHIPPING_METHOD");
			}
		},
		async setShippingMethod({ commit, dispatch }, payload) {
			commit("SET_SHIPPING_METHOD", payload.type);

			if (payload.type === "delivery") {
				await dispatch("addShippingFee",payload);
			} else {
				await dispatch("removeShippingFee");
			}
		},
		async resetLtoRegistration({ commit, dispatch, state }) {
			console.log(state)
			console.log(commit)
			console.log(dispatch)
			if (state === "LOAN") {
				// await dispatch("removeShippingFee");
				commit("SET_LTO_REGISTRATION");
			}
		},
		async setLtoRegistration({ commit, dispatch }, payload) {
			console.log(commit)
			if (payload == "LOAN") {
				await dispatch("addLoanRegistrationFee",payload);
			}
		},
		async addLoanRegistrationFee({ commit,state,rootState, dispatch }) {
			console.log(dispatch)
			
			let request = await fetch(`${api}/mc/lto/loan/${state.entries[0].sku}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			});
			let response = await request.json();
			let amount = !response.ltoRegistration ? 0 : response.ltoRegistration.amount 
			console.log(amount)
			state.entries[0].ltoRegistrationFees = amount
			let newEntries = state.entries
			console.log(newEntries)
			await db.collection("cart").doc(state.order.orderId).collection("entries").doc(state.entries[0].sku).update({
				ltoRegistrationFees: amount,
			});
			await fetch(`${api}/cart/update/lto-registration-fee`, {
				method: "POST",
				body: JSON.stringify({
					orderId: state.order.orderId,
					sku: state.entries[0].sku,
					ltoRegistrationFee: amount,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`
				},
			});
			commit("SET_ORDER_ENTRIES", newEntries)
			commit("SET_LTO_REGISTRATION", amount);
			await dispatch("getLoanOrder",{orderId:state.order.orderId, entries:newEntries});
		},
		async setDeliveryAddress({ commit, rootState, state }) {
			let address = `${rootState.user.streetAddress}, ${rootState.user.barangay}, ${rootState.user.city}, ${rootState.user.province}, ${rootState.user.region}`;
			await db
				.collection("cart")
				.doc(state.order.orderId)
				.update({
					deliveryAddress: address,
				});
			commit("SET_DELIVERY_ADDRESS", address);
		},
		async updateDeliveryDetails({rootState, dispatch,commit,state}, payload) { 
			commit("SET_CHARGES",0)
			const isNCR = payload.deliveryAddress.region === 'NCR'
			const branchDeliveryFilter = isNCR ? { region: payload.deliveryAddress.region } : { city: payload.deliveryAddress.city }
			let deliveryAddress = `${payload.deliveryAddress.streetAddress} ${payload.deliveryAddress.barangay} ${payload.deliveryAddress.city} ${payload.deliveryAddress.province} ${payload.deliveryAddress.region}`
			let deliveryAddressCoords = await dispatch("geoCodeDeliveryAddress",deliveryAddress)
			console.log(branchDeliveryFilter)
			let branches = await dispatch("getBranches",branchDeliveryFilter)
			console.log(branches)
			let list = [];
			for (let i = 0; i < branches.length; i++) {
				let branch = branches[i];
				branch.distance = getDistanceFromLatLonInKm(
					deliveryAddressCoords.lat,
					deliveryAddressCoords.lng,
					branches[i].latitude,
					branches[i].longitude
				);
				list.push(branch);
			}
			console.log(list)
			let computedBranches = _.orderBy(list, ["distance"], ["asc"]);
			console.log("computedBranches")
			console.log(computedBranches)
			if (computedBranches.length > 0) {
				await fetch(`${api}/order/update/delivery-address`, {
					method: "POST",
					body: JSON.stringify({
						orderId: payload.orderId,
						region: payload.deliveryAddress.region,
						deliveryAddress: `${payload.deliveryAddress.streetAddress}, ${payload.deliveryAddress.barangay} ${payload.deliveryAddress.city} ${payload.deliveryAddress.province} ${payload.deliveryAddress.region}`,
						distance: computedBranches[0].distance
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootState.token}`,
					},
				});
				commit("SET_HUB", computedBranches[0]);
				await dispatch("getOrder",state.order.orderId);
			} else {
				commit("SET_HUB", {});
			}
			await dispatch("getOrder",state.order.orderId);
			commit("SET_CHARGES",1)
		},
		async addShippingFee({ commit, state, rootState, dispatch }, payload) {
			commit("SET_CHARGES",0)
			console.log(payload)
			const isNCR = payload.origin.region === 'NCR'
			const branchDeliveryFilter = isNCR ? { region: payload.origin.region } : { city: payload.origin.city }
			let deliveryAddress = `${payload.origin.streetAddress} ${payload.origin.barangay} ${payload.origin.city} ${payload.origin.province} ${payload.origin.region}`

			let deliveryAddressCoords = await dispatch("geoCodeDeliveryAddress",deliveryAddress)
			let branches = await dispatch("getBranches",branchDeliveryFilter)
			let list = [];
			for (let i = 0; i < branches.length; i++) {
				let branch = branches[i];
				branch.distance = getDistanceFromLatLonInKm(
					deliveryAddressCoords.lat,
					deliveryAddressCoords.lng,
					branches[i].latitude,
					branches[i].longitude
				);
				list.push(branch);
			}
			let computedBranches = _.orderBy(list, ["distance"], ["asc"]);
			console.log("computedBranches")
			console.log(computedBranches)
			if (computedBranches.length > 0) {
				await fetch(`${api}/order/shipping/update`, {
					method: "POST",
					body: JSON.stringify({
						orderId: state.order.orderId,
						distance: computedBranches[0].distance,
						selectedDeliveryAddress: payload.origin
					}),
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${rootState.token}`,
					},
				});
				commit("SET_HUB", computedBranches[0]);
			} else {
				commit("SET_HUB", {});
				await dispatch("removeDeliveryMatrix",payload.origin);
			}
			await dispatch("getOrder",state.order.orderId);
			commit("SET_CHARGES",1)
		},
		async removeDeliveryMatrix({ commit, state, rootState, dispatch },payload) {
			commit("SET_CHARGES",0)
			console.log(payload)
			await fetch(`${api}/order/delivery-matrix/remove`, {
				method: "POST",
				body: JSON.stringify({
					orderId: state.order.orderId,
					deliveryAddress: `${payload.streetAddress}, ${payload.barangay} ${payload.city} ${payload.province} ${payload.region}` 
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			await dispatch("getOrder",state.order.orderId);
			commit("SET_CHARGES",1)
		},
		async removeShippingFee({ commit,state, rootState, dispatch }) {
			commit("SET_CHARGES",0)
			await fetch(`${api}/order/shipping/remove`, {
				method: "POST",
				body: JSON.stringify({
					orderId: state.order.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			await dispatch("getOrder",state.order.orderId);
			commit("SET_CHARGES",1)
		},

		setPaymentMethod({ commit }, payload) {
			commit("SET_PAYMENT_METHOD", payload);
		},

		async setOnlineTransactionFee({ commit, dispatch }, payload) {
			commit("SET_ONLINE_TRANSACTION_FEE", payload);

			if (payload === "Pay Online") {
				await dispatch("addOnlineTransactionFee");
			} else {
				await dispatch("removeOnlineTransactionFee");
			}
		},

		async addOnlineTransactionFee({ state, rootState, dispatch }, payload) {
			await fetch(`${api}/order/online-transaction/add`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload ? payload : state.order.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			// await dispatch("sync", state.order.orderId)
			await dispatch("getOrder", state.order.orderId);
		},
		async removeOnlineTransactionFee({ state, rootState, dispatch }, payload) {
			await fetch(`${api}/order/online-transaction/remove`, {
				method: "POST",
				body: JSON.stringify({
					orderId: payload ? payload : state.order.orderId,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${rootState.token}`,
				},
			});
			// await dispatch("sync", state.order.orderId)
			await dispatch("getOrder", state.order.orderId);
		},
		async geoCodeDeliveryAddress({rootState, dispatch}, payload) {
			console.log(rootState, dispatch)
			
			let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${payload}&key=AIzaSyDHAjcezNBpAbyyj9PwSnH5fplYE1o9cOE`
			let geoCodes = null
			await axios.get(url).then((res)=>{
				geoCodes = res.data.results[0].geometry.location
				console.log(geoCodes)
			})
			return geoCodes
		},
		async getNearestBranch({ commit, dispatch }, payload) {
			console.log(payload)
			let deliveryAddressCoords = await dispatch("geoCodeDeliveryAddress",payload.deliveryAddress)
			let branches = await dispatch("getBranches")
			let list = [];
			for (let i = 0; i < branches.length; i++) {
				let branch = branches[i];
				branch.distance = getDistanceFromLatLonInKm(
					deliveryAddressCoords.lat,
					deliveryAddressCoords.lng,
					branches[i].latitude,
					branches[i].longitude
				);
				list.push(branch);
			}
			let computedBranches = _.orderBy(list, ["distance"], ["asc"]);
			console.log("computedBranches")
			console.log(computedBranches)
			commit("SET_NEAREST_BRANCH_DELIVERY_ADDRESS", computedBranches.length > 0 ? computedBranches[0] : { uid: null, name: null });
			return computedBranches;
		}
	},
	getters: {
		order: (state) => state.order,
		entries: (state) => state.entries,
		entriesQuantity: (state) => state.entriesQuantity,
		activeEntry: (state) => state.activeEntry,
		comparison: (state) => state.comparison,
		shippingMethod: (state) => state.shippingMethod,
		paymentMethod: (state) => state.paymentMethod,
		onlineTransactionFee: (state) => state.onlineTransactionFee,
		allowedChannels: (state) => state.allowedChannels,
		deliveryAllowed: (state) => state.deliveryAllowed,
		deliveryLocationCovered: (state) => state.deliveryLocationCovered,
		motorcyclesOnly: (state) => state.motorcyclesOnly,
		partsOnly: (state) => state.partsOnly,
		availableBranches: (state) => state.availableBranches,
		ready: (state) => state.ready,
		initializing: (state) => state.initializing,
		nearestBranchOnDeliveryAddress: (state) => state.nearestBranchOnDeliveryAddress,
		hub: (state) => state.hub,
		chargesReady: (state) => state.chargesReady
	},
};

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
	let R = 6371; // Radius of the earth in km
	let dLat = deg2rad(lat2 - lat1); // deg2rad below
	let dLon = deg2rad(lon2 - lon1);
	let a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let d = R * c; // Distance in km
	return d;
}

function deg2rad(deg) {
	return deg * (Math.PI / 180);
}
