<template>
	<div>
		<banner title="Motorcycles"></banner>
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-3 col-sm-12 col-xs-12">
							<div class="sidebar products-sidebar">
								<div class="input-group md-form form-sm form-2 pl-0 mb-4 searchbar">
									<input
										class="form-control my-0 py-1 red-border rounded-0"
										type="text"
										placeholder="Search"
										aria-label="Search"
										v-model="filters.term"
									/>
									<div class="input-group-append">
										<span class="input-group-text red lighten-3 rounded-0" id="basic-text1">
											<i class="fas fa-search text-grey" aria-hidden="true"></i>
										</span>
									</div>
								</div>
								<h6 class="text-uppercase mb-3">Brands</h6>
								<b-form-checkbox-group stacked v-model="filters.manufacturers" :options="manufacturers" />
							</div>
							<h6 class="text-uppercase mt-3">Price</h6>
							<b-form-group>
								<b-form-radio name="price_range" v-model="filters.price" :value="null">All</b-form-radio>
								<b-form-radio
									v-for="(price, index) in prices"
									:key="index"
									name="price_range"
									v-model="filters.price"
									:value="price.value"
								>
									{{ price.minimum | thousandSeparator }} -
									{{ price.maximum | thousandSeparator }}
								</b-form-radio>
							</b-form-group>
							<b-button mb-3 block variant="danger" @click="resetProductFiltering">Clear All</b-button>
						</div>
						<div class="col-12 col-md-9">
							<div>
								<div class="row">
									<div class="col-12 col-md-8">
										<b-nav pills>
											<b-nav-item :active="filters.categories === null" @click="filters.categories = null">All</b-nav-item>
											<b-nav-item
												v-for="category in departmentCategories"
												:key="category"
												:active="filters.categories === category"
												@click="filters.categories = category"
											>{{ category }}</b-nav-item>
										</b-nav>
									</div>
									<div class="col-12 col-md-4 text-right">
										<sortBy v-model="filters.replica"></sortBy>
									</div>
								</div>
								<div class="row" v-if="searching">
									<div class="col-12 text-center mt-5">
										<img src="/img/spinner.gif" height="128" />
									</div>
								</div>
								<div class="products-list mt-5" v-else>
									<div class="row" v-if="items.length">
										<div
											class="col-12 col-sm-6 col-md-6 col-lg-4 text-center"
											v-for="(hit, index) in items"
											:key="index"
										>
											<router-link :to="`/products/${hit.slug}`">
												<div class="product-image">
													<img :src="hit.photo" class="img-fluid" />
												</div>
												<h4 class="product-name text-uppercase">{{ hit.name }}</h4>
												<p class="product-description">{{ hit.shortDescription }}</p>
												<h3 class="product-price">{{ hit.price | currencyDisplay }}</h3>
												<p class="thumbnail-promos" v-if="hit.department == 'Motorcycles' && (hit.LTOFee != 1 || hit.freebies != null)">
													<span v-if="hit.LTOFee != 1">Free LTO Registration for Loan Payment<br></span>
													<span v-if="hit.freebies != null && hit.freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
													<span v-if="hit.freebies != null && hit.freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
												</p>
											</router-link>
										</div>
									</div>
									<div v-else class="col-12 text-center">
										<img src="/img/empty_state.png" height="400" />
										<h5>
											No result(s) found
											<p>
												<span v-if="filters.term">
													<b>{{ filters.term }}</b>
												</span>
												<span v-if="filters.categories">
													<span v-if="filters.term">&nbsp;under&nbsp;</span>
													for <b>{{ filters.categories }}</b>
												</span>
												<span v-if="filters.manufacturers">
													<span v-if="filters.categories || filters.term">&nbsp;and&nbsp;</span>&nbsp;
													for <b>{{ filters.manufacturers.join() }}</b>&nbsp;
												</span>
												<span v-if="filters.price">
													<span>&nbsp;in price range of&nbsp;</span>
													<b>
														{{ filters.price.split(",")[0] | thousandSeparator }} -
														{{ filters.price.split(",")[1] | thousandSeparator }}
													</b>
												</span>
											</p>
										</h5>
									</div>
								</div>
							</div>

							<nav class="mt-5">
								<b-pagination
									:total-rows="pagination.totalRows"
									:items="pagination.items"
									:per-page="filters.hitsPerPage"
									v-model="filters.page"
								/>
							</nav>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
import { remove } from "lodash";
import _ from "lodash";
import { api } from "../../config";
import search from "@/libraries/Search";
import sortBy from "./SortByField";

export default {
	components: {
		banner,
		sortBy,
	},
	data() {
		let filters = search.setDefaultFilter(this.getDefaultFilter());

		return {
			manufacturers: [],
			departmentCategories: [],
			priceSortedProducts: [],
			prices: [],
			hits: [],
			topselling: [],
			items: [],
			pages: [],
			searching: true,
			compareItems: [],
			compareVariantSlugs: [],
			compareVariantPhoto: [],
			pagination: {
				totalRows: null,
				items: [],
			},
			filters,
			routeQuery: {},
		};
	},
	computed: {
		searchFilters() {
			let filters = { ...this.filters };
			return search.getURLString(filters);
		},
		filteredHitsArray() {
			let _tempHitsArray = this.hits
			let indexes = []
			let listOfTopSellingMaterialNos = _.map(this.topselling, 'materialNo')
			let listOfHitsMaterialNos = _.map(_tempHitsArray, 'materialNo')
			let intersection = listOfTopSellingMaterialNos.filter(element => listOfHitsMaterialNos.indexOf(element) !== -1);
			
			intersection.forEach(mc => {
				let index = _.findIndex(_tempHitsArray, function(obj) { return obj.materialNo == mc });
				indexes.push(index)
			})

			indexes.forEach(index => {
				_tempHitsArray.splice(index, 1)
			})

			let filteredArray = _tempHitsArray
			return filteredArray;
		},
		noResultsFound() {
			return this.filters.page == 1 && !this.topselling.length || this.filters.page > 1 && !this.filteredHitsArray.length ? true : false
		},
		searchBarAndSortDefault() {
			return this.filters.term && this.filters.replica != 'products_timestamp_desc' || this.filters.term || this.filters.replica != 'products_timestamp_desc' ? true : false
		}
	},
	async mounted() {
		this.routeQuery = this.$route.query

		if(this.routeQuery.fbclid) {
			await this.checkEnforceUrl();
		}

		await this.getTopSellingMcs();
		await this.searchProducts();
		await this.getManufacturers();
		await this.getDepartmentCategories();
		await this.generatePriceRange();
	},
	methods: {
		getDefaultFilter() {
			return {
				hitsPerPage: 20,
				page: 1,
				departments: "Motorcycles",
				price: null,
				replica: "products_timestamp_desc",
				term: null,
				categories: null,
				manufacturers: null,
			};
		},
		async checkEnforceUrl() {
			if(location.search) location.replace(location.href.replace(/\?.+/, ""));
		},
		async getTopSellingMcs() {
			this.searching = true

			let request = await fetch(
				`${api}/products/top_selling/mc/sorted?${this.searchFilters}`,
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			);
			let responseData = await request.json();
			this.topselling = responseData.data
			this.items = this.topselling
			this.searching = false
		},
		async getManufacturers() {
			let request = await fetch(
				`${api}/products/manufacturers?department=${this.filters.departments}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			let responseBody = await request.json();
			this.manufacturers = responseBody.map((rec) => rec.manufacturer);
		},
		async getDepartmentCategories() {
			let request = await fetch(
				`${api}/products/departments/categories/${this.filters.departments}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			let responseData = await request.json();
			remove(responseData, (rec) => rec === null);
			this.departmentCategories = responseData;
		},
		async searchProducts() {
			this.searching = true;

			let request = await fetch(
				`${api}/products/search?${this.searchFilters}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			let data = await request.json();

			this.hits = data.hits;
			this.hits.map((hit) => {
				hit.price = parseFloat(hit.price);
			});

			if(this.filters.page == 1 && this.searchBarAndSortDefault) this.items = this.hits
			if(this.filters.page > 1) this.items = this.filteredHitsArray

			this.searching = false;

			let _tempPageItems = [];
			this.pagination.totalRows = data.nbHits;
			for (let index = 0; index < data.nbPages; index++) {
				_tempPageItems.push(index);
			}
			this.pagination.items = _tempPageItems;
		},
		async generatePriceRange() {
			let request = await fetch(
				`${api}/products/sorted/price/ASC?department=${this.filters.departments}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			this.priceSortedProducts = await request.json();
			this.priceSortedProducts.map((product) => {
				product.price = parseFloat(product.price);
				return product;
			});

			let minimum = 35000;
			let maximum = 59999;
			let value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 60000;
			maximum = 79999;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 80000;
			maximum = 99999;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 100000;
			maximum = 119999;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 120000;
			maximum = 159999;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 160000;
			maximum = 199999;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - ${maximum}`});
			minimum = 200000;
			maximum = 500000;
			value = `${minimum},${maximum}`;
			this.prices.push({minimum,maximum,value,text: `${minimum} - above`});
			
		},
		resetProductFiltering() {
			this.pagination = {
				totalRows: null,
				items: [],
			};

			this.filters = this.getDefaultFilter();
			if(this.filters.page == 1) {
				this.items = this.topselling
				this.searchProducts();
			} else {
				this.searchProducts();
			}
		},
		clearItems() {
			let self = this;
			self.compareItems = [];
			self.compareVariantSlugs = [];
			self.compareVariantPhoto = [];
		},
	},
	watch: {
		routeQuery() {
			if(this.routeQuery.term) {
				this.getManufacturers();
				this.getDepartmentCategories();
				this.generatePriceRange();
				
				search.setURLParameters(this.routeQuery.query);
				this.searchProducts();
			}
		},
		filters: {
			handler: function (query) {
				// Will run if on page 1 and search bar or sort has values
				if(query.page == 1 && query.term || query.replica != 'products_timestamp_desc') {
					search.setURLParameters(query);
					this.searchProducts();
				// Will run if on page 1 and search bar and sort has values
				} else if(query.page == 1 && query.term && query.replica != 'products_timestamp_desc') {
					search.setURLParameters(query);
					this.searchProducts();
				// Will run if in page 2 or so on
				} else if(query.page > 1) {
					query.nextPage = 1
					search.setURLParameters(query);
					this.searchProducts();
				// Will run if has default filter
				} else {
					if(query.term === '') {
						search.setURLParameters(query);
						this.searchProducts();
					}
					this.topselling = []
					this.getTopSellingMcs();
				}
			},
			deep: true,
		},
		compareItems(newValue) {
			this.compareVariantSlugs = [];
			this.compareVariantPhoto = [];
			newValue.forEach((item) => {
				if (this.compareVariantSlugs.length >= 2) {
					alert("Maximum of 2 unit in comparison");
				} else {
					this.compareVariantSlugs.push(item.variantSlug);
					this.compareVariantPhoto.push(item.photo);
				}
			});
		},
	},
};
</script>

<style>
.grouping-badge {
	vertical-align: middle !important;
	line-height: 23px;
	background-color: #f03022;
}
ul {
	margin-left: 0px !important;
}

.products-list-item a {
	min-height: 410px;
	min-height: 0;
}

.thumbnail-promos {
	margin-top: -25px;
	line-height: 1;
}
.thumbnail-promos span {
	color: red;
	font-size: 10px;
}
</style>
