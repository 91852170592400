import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import "./registerServiceWorker";
import moment from "moment";
import firebase from "firebase";
import { firebaseConfig } from "./config";
import $ from "jquery";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import ImageZoom from "vue-zoom-on-hover";
import _ from "lodash";
import VueOffline from "vue-offline";
import Meta from 'vue-meta';

//Vue.config.devtools = true;

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const analytics = firebase.analytics();
db.enablePersistence();

store.dispatch("Setup/getRegions");
store.dispatch("Setup/getGroupedBranches");

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
Vue.use(ImageZoom);
Vue.use(VueOffline);
Vue.use(Meta);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAP_KEY,
        libraries: "geometry"
    },
});
Vue.config.productionTip = false;
window.$ = $;
Vue.prototype.$redirect = (page) => {
    router.push(page).catch();
};
Vue.prototype.$eventBus = new Vue();

Vue.filter("thousandSeparator", (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
});

Vue.filter("readableDateTime", (value) => {
    return moment(value).format("MMMM DD, YYYY hh:mm A");
});

Vue.filter("readableMonthDay", (value) => {
    return moment(value).format("MMM D");
});

Vue.filter("amountThousandSeparator", (value) => {
    var splitNum;
    var number = Math.abs(value);
    number = number.toFixed(2);
    splitNum = number.split(".");
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return splitNum.join(".");
});

Vue.filter("currencyDisplay", (value) => {
    let splitNum;
    let number = Math.abs(value);
    number = number.toFixed(2);
    splitNum = number.split(".");
    splitNum[0] = splitNum[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `₱ ${splitNum.join(".")}`;
});

Vue.filter("totalQuantity", (value) => {
    let total = _.sumBy(value, (item) => {
        return parseFloat(item.quantity);
    });
    return total;
});

Vue.filter("unCamelCase", (value) => {
    return (
        value
            .replace(/([A-Z])/g, " $1")
            // uppercase the first character
            .replace(/^./, function (str) {
                return str.toUpperCase();
            })
    );
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    let title = "";
    if (nearestWithTitle) {
        title = nearestWithTitle.meta.title;
    } else {
        title = previousNearestWithMeta.meta.title;
    }

    document.title = `Transcycle Online Store - ${title}`;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key]);
            });

            // We use this to track which meta tags we create, so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach((tag) => document.head.appendChild(tag));
    if (!to.matched.some((record) => record.meta.protected)) {
        //route is public, don't check for authentication
        if (to.matched.name === "Logout") {
            router.push("/logout");
        } else {
            next();
        }
    } else {
        //route is protected, if authenticated, proceed. Else, login
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                next();
            } else {
                let redirectURL = "/";
                if (to.path.length) {
                    redirectURL = to.path;
                }
                router.push({
                    path: `/login?redirectURL=${redirectURL}`,
                });
            }
        });
    }
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
