const UserLocation = {
    namespaced: true,
    state: {
        isEcqCustomer: false,
        currentLocation: null,
        ncrPlusCoords: [
            { lat: 14.801174049335877, lng: 120.82785723476591 },
            { lat: 14.882181063380084, lng: 121.05924844800747 },
            { lat: 14.790288407758512, lng: 121.1017132705552 },
            { lat: 14.888451926809758, lng: 121.34053503552947 },
            { lat: 14.260984756807062, lng: 121.54302704476845 },
            { lat: 13.983243240529255, lng: 121.32852836647349 },
            { lat: 14.160440535540468, lng: 121.13430362231617 },
            { lat: 14.069810219689066, lng: 120.8622957518302 },
            { lat: 14.361781790437908, lng: 120.53971598306961 }
        ]
    },
    getters: {
        currentLocation(state) {
            return state.currentLocation
        },
        isEcqCustomer(state) {
            return state.isEcqCustomer
        },
        ncrPolygon(state) {
            return new window.google.maps.Polygon({ paths: state.ncrPlusCoords });
        }
    },
    mutations: {
        SET_CURRENTLOCATION(state, geolocation) {
            state.currentLocation = geolocation
        },
        SET_ECQCUSTOMER(state, result) {
            state.isEcqCustomer = result
        },
    },
    actions: {
        setUserLocation({commit}, payload) {
            try{
                if(!navigator.geolocation) {
                    throw new Error("Geolocation is not supported by this browser.")
                }
            } catch(error) {
                if(payload && payload.error) payload.error(error)
            }

            navigator.permissions.query({ name: "geolocation" }).then(function(result) {
                doCurrentLocationSetting(result, payload, commit)

                result.onchange = async () => {
                    doCurrentLocationSetting(result, payload, commit)
                };
            });
        },
        validateEcqLocation({commit, state}, callback) {
            const boundary = new window.google.maps.Polygon({ paths: state.ncrPlusCoords });
            const latLng = new window.google.maps.LatLng(state.currentLocation.coords.latitude, state.currentLocation.coords.longitude)

            commit('SET_ECQCUSTOMER', window.google.maps.geometry.poly.containsLocation(latLng, boundary))

            if(callback) callback()
        }
    }
}

function doCurrentLocationSetting(result, payload, commit) {
    if (result.state !== "granted") {
        commit('SET_CURRENTLOCATION', null)
        if(payload && payload.error) payload.error(new Error("Location is not permitted"))
    } else {
        if(payload && payload.success) payload.success()
        navigator.geolocation.getCurrentPosition((pos) => {
            commit('SET_CURRENTLOCATION', pos)
        }, (error) => {
            commit('SET_CURRENTLOCATION', null)
            if(payload && payload.error) payload.error(error)
            throw new Error(error.message);
        })
    }
}

export default UserLocation