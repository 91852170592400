<template>
	<div>
		<banner title="About us"/>
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<h3 class="mb-3">Our Story</h3>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="offset-md-3 offset-sm-3 col-md-6 col-sm-6 mb-3 text-center">
								<img
									src="https://i.postimg.cc/FHh3yJRB/logo-circle-transcycle.png"
									class="img-fluid"
									height="100"
								/>
							</div>
							<p>
								<b>Gentrade International (Phils.) Inc.</b> was incorporated on May 16,1996 for the purpose of selling motorcycle units, spare parts and accessories. Its operation started March 14, 1997 when the motorcycle selling and financing was finally transferred from Siy Cha Hardware Corporation to Gentrade International (Phils.) Inc., and operating under the trade name of Trans Cycle. The principal place/ head office of the business is located at Alunan Highway Tacurong City Sultan Kudarat.
							</p>

							<p>The venture was further enhance when Suzuki (Phils.) Inc. offered the company to be an exclusive 3’S shop in Mindanao and Southern Luzon Area. It was this business partnership that enables Trans Cycle to expand in 1997.</p>

							<p>June 2000 marked the birth of Powercycle, Inc., an exclusive 3S shop of Honda. Concomitantly, Transcycle continues to sell Honda units in all its branches, side by side with Kawasaki, Yamaha and Suzuki.</p>
						</div>
						<div class="col-md-4 col-sm-12">
							<p>It was in October of 2009 when the first ever Yamaha 3S shop was opened in Manila and is still continuing to expand as it opens more branches in Visayas and Mindanao.</p>
							<p>Amazingly, GIPI’s venture into the motorcycle business has really proven its potential as it has survived the fast race of the industry. Worth noting is the fact that Transcycle and Powercyle has carved its name to the throng of top dealers of motorcycle. Through time, expansions were inevitable and the company has saturated developing places in Luzon, Visayas and Mindanao.</p>
							<p>Today, GIPI has more than 200 branches operating under the trade name of Transcycle and Powercycle. Its living vision is “To be the leading institution in providing financing services in the pursuit of uplifting Filipino lives”.</p>
							<div class="text-center">
								<img
									src="https://i.postimg.cc/pTjmb2rj/Siy-Cha-Hardware-1-300x184.jpg"
									class="img-fluid"
									alt
								/>
							</div>
							<p class="mt-3 lead text-center">Kasabay sa Tagumpay ng Buhay</p>
						</div>
						<div class="col-md-4 col-sm-12">
							<p class="lead">SIY CHA GROUP OF COMPANIES</p>
							<div>
								<b>VISION</b>
								<p>Be the leading institution in providing financing services in the pursuit of uplifting Filipino lives.</p>
							</div>
							<div>
								<b>MISSION</b>
							</div>
							<div>
								<b>Team Members</b>
								<p>Establish a continuous healthy relationship with customers through fair quality and professional delivery of service.</p>
							</div>
							<div>
								<b>Creditors and Suppliers</b>
								<p>Maintain a mutually beneficial and healthy business partnership with creditors and suppliers bound by respect. fairness and professionalism.</p>
							</div>
							<div>
								<b>Shareholders</b>
								<p>Give back to our investors/shareholders their fair return.</p>
							</div>
							<div>
								<b>Community</b>
								<p>Create employment and livelihood opportunities by providing affordable financing to every Filipino family.</p>
							</div>
							<div>
								<b>Country</b>
								<p>Love our country by obeying the laws of the land</p>
							</div>
							<div>
								<b>Customer Service</b>
								<p>Putting customer needs above all else</p>
							</div>
						</div>
					</div>
					<div class="row mb-5">
						<div class="col-12 text-center">
							<h3 class="mb-5 mt-5">Our 8 Core Values</h3>
							<img src="https://i.postimg.cc/yN4qpBMt/Screenshot-40.png" class="img-fluid" alt />
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
export default {
	components: {
		banner
	}
}
</script>