<template>
    <section class="login ptb-5">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                    <div class="row">
                        <div class="col-12 col-md-6 login-text p-5">
                            <h2 class="text-uppercase mb-4">Sign in to continue</h2>
                            <p>
                                Transcycle's goal is bringing the best possible shopping experience to any enthusiast
                                who visits us in-store or online looking for motorcycles, apparels, helmets, accessories
                                and genuine parts.
                            </p>
                        </div>
                        <div class="col-12 col-md-6 login-buttons p-5">
                            <div id="firebaseui-auth-container"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            centered
            no-close-on-backdrop
            no-close-on-esc
            hide-header-close
            ok-only
            ref="sessionExpiredModal"
            title="Session Expired"
            v-on:hide="removeSessionQueryParam"
        >
            <div class="d-block text-center">
                <p>Please login again.</p>
            </div>
        </b-modal>
    </section>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "../../node_modules/firebaseui/dist/firebaseui.css";
import { analytics } from "../main";

export default {
    mounted() {
        let self = this;
        let uiConfig = {
            signInFlow: 'popup',
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                firebase.auth.EmailAuthProvider.PROVIDER_ID,
            ],
            callbacks: {
                signInSuccessWithAuthResult() {
                    firebase.auth().onAuthStateChanged(async (user) => {
                        if (user) {
                            localStorage.setItem("authenticated", true);
                            localStorage.setItem("user", JSON.stringify(user));

                            analytics.logEvent("logged_in");

                            let destination = "/home";
                            if (
                                self.$route.query.hasOwnProperty(
                                    "redirectURL"
                                ) &&
                                self.$route.query.redirectURL.length
                            ) {
                                destination = self.$route.query.redirectURL;
                            }
                            console.log(self.$route.query.redirectURL)
                            self.$router.push(destination);

                            setTimeout(() => {
                                window.location.reload()     
                            }, 500);
                            
                        }
					});
					
					return false;
                },
            },
        };
        let ui =
            firebaseui.auth.AuthUI.getInstance() ||
            new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);

        this.showSessionExpiredModal()
    },
    methods: {
        showSessionExpiredModal() {
            const query = this.$route.query

            if(!query.hasOwnProperty('sessionTimeout')) return

            this.$refs.sessionExpiredModal.show()
        },
        removeSessionQueryParam() {
            let query = {...this.$route.query}

            delete query.sessionTimeout

            this.$router.replace({ path: "login", query })
        }
    }
};
</script>
