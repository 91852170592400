<template>
    <b-form-select @change="doEmit" :value="selectValue">
        <b-form-select-option value="products_timestamp_desc">Sort by Newest</b-form-select-option>
        <b-form-select-option value="products_price_asc">Sort by Price Low to High</b-form-select-option>
        <b-form-select-option value="products_price_desc">Sort by Price High to Low</b-form-select-option>
    </b-form-select>
</template>

<script>
export default {
    model: {
        prop: 'selectValue',
        event: 'change'
    },
    props: {
        selectValue: {
            type: String,
            default: 'products_timestamp_desc'
        }
    },
    methods: {
        doEmit(value) {
            this.$emit('change', value)
        }
    }
}
</script>