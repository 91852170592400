<template>
	<div>
		<banner title="Checkout" />
		<main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row" v-if="cartReady">
						<div class="col-md-6">
							<div class="row">
								<div class="col-md-12">
									<h4>Select Payment Method</h4>
								</div>
							</div>
							<div class="row mt-3 mb-3">
								<div class="col-md-12">
									<b-list-group>
										<b-list-group-item
											href="javascript:void(0)"
											:active="paymentMethod === 'Pay in Installment'"
											@click="initializeInstallment"
											class="flex-column align-items-start"
											:disabled="!payInInstallmentAllowed"
										>
											<div class="d-flex w-100 justify-content-between">
												<h5 class="mb-1">Apply for Installment</h5>
											</div>

											<small v-if="payInInstallmentAllowed">Pay on flexible monthly installment plan.</small>
											<small v-else>
												Available for cart that contains 1 motorcycle only.
											</small>
										</b-list-group-item>
										
										<b-list-group-item
											href="javascript:void(0)"
											:active="paymentMethod === 'Pay Online'"
											@click="setPaymentMethod('Pay Online')"
											class="flex-column align-items-start"
										>
											<div class="d-flex w-100 justify-content-between">
												<h5 class="mb-1">Pay Online</h5>
											</div>

											<small>Please select your preferred payment option by clicking the empty box below. By selecting your payment option, you will be able to pay online, pay using e-Wallet, or use other payment channel to complete your purchase securely.</small>
											<b-form-select
												@change="checkout('Pay Online')"
												v-if="paymentMethod === 'Pay Online'"
												v-model="channel"
												:options="allowedChannels"
											></b-form-select>
										</b-list-group-item>

										<!-- <b-list-group-item
											href="javascript:void(0)"
											:active="paymentMethod === 'Cash over the Counter'"
											@click="checkout('Cash over the Counter')"
											class="flex-column align-items-start"
											v-if="shippingMethod !== 'delivery'"
										>
											<div class="d-flex w-100 justify-content-between">
												<h5 class="mb-1">Cash over the Counter</h5>
											</div>

											<small>Pay on our branches upon pickup. Requires reservation fee.</small>
										</b-list-group-item>-->
										<b-list-group-item class="text-center" v-if="payInInstallmentAllowed && (entries[0].freebies != null)">
											<p :class="consentBorderClass" style="color: red; font-weight: bold;">
												<span>For Single Order Only:<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('LTO')">Free LTO Registration for Loan Payment<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('Helmet')">Free Helmet for Loan Payment<br></span>
												<span v-if="entries[0].freebies != null && entries[0].freebies.includes('Engine Oil')">Free Engine Oil for Loan Payment</span>
											</p>
										</b-list-group-item>
									</b-list-group>
								</div>
							</div>

							<div class="row mt-3 mb-3">
								<div class="col-md-12 text-center">
									<PaymentRequestForm :paymentRequestValue="paymentRequestValue" v-if="ready" />
									<div v-if="paymentRequestValue && !ready">
										<img src="/img/spinner.gif" height="64" />
									</div>
								</div>
							</div>

							<div class="row mt-3 mb-3">
								<div class="col-md-12">
									<span>*Order is for {{ order.shippingMethod }}.</span>
									<span v-if="order.shippingMethod === 'delivery'">
										&nbsp;Receive by
										<b>
											{{ order.deliveryDateStart | readableMonthDay }} -
											{{ order.deliveryDateEnd | readableMonthDay }}
										</b>
									</span>
									<span v-else>
										&nbsp;Pick your order at
										<b>{{ order.branchName }}</b>
									</span>
									<p>
										<router-link to="/cart" class="text-danger">Change shipping method?</router-link>
									</p>
								</div>
							</div>
						</div>
						<div class="col-md-6">
							<table class="table">
								<thead>
									<tr>
										<th scope="col" colspan="2">Summary</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Items</td>
										<td class="text-right">{{ entries | totalQuantity }}</td>
									</tr>
									<tr v-if="entries.length===1">
										<td>SRP</td>
										<td class="text-right">{{ entries[0].price | amountThousandSeparator }}</td>
									</tr>
									<tr v-if="paymentMethod === 'Pay in Installment'">
										<td>Downpayment</td>
										<td class="text-right">{{ order.downpayment | amountThousandSeparator }}</td>
									</tr>
									<tr v-if="partsOnly===false">
										<td>LTO Registration Fee</td>
										<td class="text-right">{{ order.ltoRegistrationFees | amountThousandSeparator }}</td>
									</tr>
									<tr v-if="shippingMethod === 'delivery'">
										<td>Delivery Fee</td>
										<td class="text-right">{{ order.shipping | amountThousandSeparator }}</td>
									</tr>
									<tr>
										<td>
											<b>Subtotal</b>
										</td>
										<td class="text-right">
											<b>{{ order.subtotal | amountThousandSeparator }}</b>
										</td>
									</tr>
									<tr v-if="order.onlineTransactionFees > 0">
										<td>Online Transaction Fee</td>
										<td class="text-right">{{ order.onlineTransactionFees | amountThousandSeparator }}</td>
									</tr>
									<tr>
										<td>
											<b>Total</b>
										</td>
										<td class="text-right">
											<b>{{ order.total | amountThousandSeparator }}</b>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<div class="row" v-else>
						<div class="col-12 text-center">
							<img src="/img/spinner.gif" height="128" />
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
import _ from "lodash";
import { analytics } from "../../main";
import { api } from "../../config";
import { mapGetters, mapActions } from "vuex";
import PaymentRequestForm from "../../components/Cart/PaymentRequestForm";
import banner from '@/components/ProductBanner';
export default {
	data: () => ({
		voucher: null,
		paymentRequestValue: null,
		cartReady: false,
		ready: false,
		channel: null,
	}),
	computed: {
		...mapGetters({
			user: "user",
			order: "Cart/order",
			entries: "Cart/entries",
			shippingMethod: "Cart/shippingMethod",
			paymentMethod: "Cart/paymentMethod",
			allowedChannels: "Cart/allowedChannels",
			partsOnly: "Cart/partsOnly",
		}),
		totalQuantity() {
			return _.sumBy(this.entries, (item) => {
				return parseFloat(item.quantity);
			});
		},
		payInInstallmentAllowed() {
			const conditions = (
				(this.totalQuantity === 1) &&
				(this.entries.filter((e) => e.department === "Motorcycles").length > 0)
			)
			return conditions
		}
	},
	async mounted() {
		this.$store.dispatch("Cart/getOrder", this.$route.params.orderId)
		
		setTimeout(() => {
			this.cartReady = true;
		}, 3000);
	},
	methods: {
		...mapActions({
			setPaymentMethod: "Cart/setPaymentMethod",
			addOnlineTransactionFee: "Cart/addOnlineTransactionFee",
			removeOnlineTransactionFee: "Cart/removeOnlineTransactionFee",
			resetLtoRegistration: "Cart/resetLtoRegistration",
			setLtoRegistration: "Cart/setLtoRegistration"
		}),
		async checkout(mode) {
			this.ready = false;

			this.setPaymentMethod(mode)
			await this.addOnlineTransactionFee(this.order.orderId);

			let request = await fetch(`${api}/payments/initialize`, {
				method: "POST",
				body: JSON.stringify({
					orderId: this.order.orderId,
					mode,
					channel: this.channel,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			let response = await request.json();
			this.paymentRequestValue = response.base64String;
			analytics.logEvent(`selected_payment_channel_${this.channel}`);
			analytics.logEvent("checked_out_cart");
			this.ready = true;
		},
		async initializeInstallment() {
			this.setLtoRegistration("LOAN")
			this.setPaymentMethod(this.paymentMethod)
			await this.removeOnlineTransactionFee(this.$route.params.orderId);
			analytics.logEvent("initiated_loan_application");
			this.$router.push(`/applications/new/${this.entries[0].product}`);
		},
	},
	components: {
		PaymentRequestForm,
		banner
	},
};
</script>
