<template>
    <div>
        <banner :title="bannerTitle == undefined ? 'Parts & Accessories' : bannerTitle.replace(/[\[\]']+/g,'')"></banner>
        <main role="main" id="app">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-12 col-md-3 col-sm-12 col-xs-12">
                            <div class="sidebar products-sidebar mb-3">
								<div class="input-group md-form form-sm form-2 pl-0 mb-4 searchbar">
									<input
										class="form-control my-0 py-1 red-border rounded-0"
										type="text"
										placeholder="Search"
										aria-label="Search"
										v-model="filters.term"
									/>
									<div class="input-group-append">
										<span class="input-group-text red lighten-3 rounded-0" id="basic-text1">
											<i class="fas fa-search text-grey" aria-hidden="true"></i>
										</span>
									</div>
								</div>
								<h6 class="text-uppercase mb-3">Categories</h6>
								<b-form-checkbox-group 
                                    class="mb-3" 
                                    stacked 
                                    v-model="filters.departments"
                                    :options="departments" 
                                />
                                <h6 class="text-uppercase mb-3">Brands</h6>
								<b-form-checkbox-group 
                                    stacked 
                                    v-model="filters.manufacturers" 
                                    :options="manufacturers" 
                                />
							</div>
							<b-button mb-3 block variant="danger" @click="resetProductFiltering">Clear All</b-button>
                        </div>
                        <div class="col-12 col-md-9">
                            <div>
                                <div class="row">
                                    <div class="col-12 col-md-4"></div>
									<div v-if="partsIsSelected" class="col-12 col-md-4 text-right">
										<b-form-select v-model="filters.model" :options="modelOptions" value-field="text">
											<template v-slot:first>
												<b-form-select-option :value="null">-- Filter By Model --</b-form-select-option>
											</template>
										</b-form-select>
									</div>
									<div v-else class="col-12 col-md-4 text-right"></div>
                                    <div class="col-12 col-md-4">
										<sortBy v-model="filters.replica"></sortBy>
									</div>
                                </div>
                                <div class="row" v-if="searching">
									<div class="col-12 text-center mt-5">
										<img src="/img/spinner.gif" height="128" />
									</div>
								</div>
                                <div class="products-list mt-5" v-else>
									<div class="row" v-if="hits.length">
										<div
											class="col-12 col-sm-6 col-md-6 col-lg-4 text-center"
											v-for="(hit, index) in hits"
											:key="index"
										>
											<router-link :to="`/products/${hit.slug}`">
												<div class="product-image">
													<img :src="hit.photo" class="img-fluid" />
												</div>
												<h4 class="product-name text-uppercase">{{ hit.name }}</h4>
												<p v-if="hit.department == 'Parts' || hit.department == 'Apparels' || hit.department == 'Accessories'" class="product-description">{{ hit.model }}</p>
												<h3 class="product-price">{{ hit.price | currencyDisplay }}</h3>
											</router-link>
										</div>
									</div>

									<div v-else class="col-12 text-center">
										<img src="/img/empty_state.png" height="400" />
										<h5>
											No result(s) found for
											<p>
												<span v-if="filters.term">
													<b>{{ filters.term }}</b>
												</span>
												<span v-if="filters.departments">
													<span v-if="filters.term">&nbsp;under&nbsp;</span>
													<b>{{ filters.departments.toString() }}</b>
												</span>
												<span v-if="filters.categories">
													<span v-if="filters.term">&nbsp;under&nbsp;</span>
													<b>{{ filters.categories }}</b>
												</span>
												<span v-if="filters.model">
													<span> ></span>
													<span v-if="filters.term">&nbsp;under&nbsp;</span>
													<b>{{ filters.model }}</b>
												</span>
												<span v-if="filters.manufacturers">
													<span> ></span>
													<span v-if="filters.categories || filters.term">&nbsp;and&nbsp;</span>&nbsp;
													<b>{{ filters.manufacturers.join() }}</b>&nbsp;
												</span>
												<span v-if="filters.price">
													<span>&nbsp;in price range of&nbsp;</span>
													<b>
														{{ filters.price.split(",")[0] | thousandSeparator }} -
														{{ filters.price.split(",")[1] | thousandSeparator }}
													</b>
												</span>
											</p>
										</h5>
									</div>
								</div>
                            </div>

                            <nav class="mt-5">
								<b-pagination
									:total-rows="pagination.totalRows"
									:items="pagination.items"
									:per-page="filters.hitsPerPage"
									v-model="filters.page"
								/>
							</nav>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import _ from 'lodash';
import banner from '@/components/ProductBanner';
import { api } from "../../config";
import search from "@/libraries/Search";
import sortBy from "./SortByField";
export default {
    components: {
        banner,
        sortBy,
    },
    data() {
        let filters = search.setDefaultFilter(this.getDefaultFilter());

        return {
            hits: [],
            selected: [],
			departmentId: 2,
            departments: [
                { text: 'Parts', value: 'Parts'},
                { text: 'Engine Oil / Lubricants', value: 'Engine Oil / Lubricants'},
                { text: 'Helmet', value: 'Helmet'},
                { text: 'Apparel', value: 'Apparels'},
                { text: 'Accessories', value: 'Accessories'} 
            ],
            manufacturers: [],
            pagination: {
				totalRows: null,
                items: [],
            },
            filters,
            slug: null,
            searching: true,
			modelOptions: []
        }
    },
    async mounted() {
		if(this.$route.query.departments) {
			if(this.$route.query.departments === 'Apparel') this.$route.query.departments = 'Apparels'
			if(this.$route.query.departments === 'Engine Oil ') this.$route.query.departments = 'Engine Oil / Lubricants'
			let departments = [this.$route.query.departments]
			this.filters = {
				hitsPerPage: 20,
				page: 1,
				departments: departments,
				price: null,
				replica: "products_timestamp_desc",
				term: null,
				categories: null,
				manufacturers: [],
				model: null
			}
		} else {
			this.checkEnforceUrl();
		}
		this.getManufacturers();
		this.getModels();
        this.searchProducts();
		
		window.onpopstate = function () {
			self.searchProducts();
		};
    },
    computed: {
        searchFilters() {
			let filters = { ...this.filters };
			let defaultDept = ['Parts', 'Engine Oil / Lubricants', 'Helmet', 'Apparels', 'Accessories']
			if(filters.departments.length < 1) {
				filters.departments = defaultDept
			}
			filters.model = filters.model == null || filters.model == undefined ? null : _.find(this.modelOptions, { text: filters.model}).value
			return search.getURLString(filters);
        },
		partsIsSelected() {
			let isSelected = this.filters.departments.includes('Parts')
			return isSelected;
		},
		bannerTitle() {
			if(this.$route.query.departments) {
				if(this.filters.departments.length > 1) {
					return 'Parts & Accessories'
				} else {
					if(this.$route.query.departments === 'Engine Oil ') {
						return 'Engine Oil / Lubricants';
					} else {
						if (this.filters.departments[0] === 'Apparels') {
							return 'Apparel';
						} else {
							return this.filters.departments[0];
						}
					}
				}
			} else {
				if(this.filters.departments.length) {
					if(this.filters.departments.length > 1) {
						return 'Parts & Accessories'
					}else {
						if (this.filters.departments[0] === 'Apparels') {
							return 'Apparel';
						} else {
							return this.filters.departments[0];
						}
						// return this.filters.departments[0];
					}
				} else {
					return 'Parts & Accessories'
				}
			} 
		}
    },
    methods: {
        getDefaultFilter() {
			return {
				hitsPerPage: 20,
				page: 1,
				departments: [],
				price: null,
				replica: "products_timestamp_desc",
				term: null,
				categories: null,
				manufacturers: [],
				model: null
			};
        },
		async checkEnforceUrl() {
			if(location.search) location.replace(location.href.replace(/\?.+/, ""));
		},
		async getManufacturers() {
			let request = await fetch(
				`${api}/products/manufacturers`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			let responseBody = await request.json();
			this.manufacturers = responseBody.map((rec) =>
				rec.manufacturer.toUpperCase()
			);
		},
		getModels(manufacturer) {
			let self = this
			self.modelOptions = []

			let sourceStr = `${api}/products/top_selling_parts/model?requester=pwa`

			if(manufacturer) sourceStr += `&brand=${manufacturer}`

			fetch(sourceStr, {
				headers: {
					"Content-Type": "application/json",
				}
			}).then(async request => {
				let responseBody = await request.json()
				let models = responseBody.rows.map(rec => rec.model)
				let _tempModels = []
				models.forEach(rec => {
					rec.split("/").forEach((val) => {
						let identifier = val.trim()
						const ind = _.findIndex(_tempModels, { text: identifier })
						
						// for having duplicate model in different parts
						if(ind < 0) {
							_tempModels.push({
								value: [rec],
								text: val.trim()
							})
						} else {
							_tempModels[ind].value.push(rec)
						}
					})
				});
				self.modelOptions = _tempModels
			})
		},
		async searchProducts() {
			this.searching = true;
			let request = await fetch(
				`${api}/products/search?${this.searchFilters}`,
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			let data = await request.json();

			this.hits = data.hits;
			this.hits.map((hit) => {
				hit.price = parseFloat(hit.price);
			});

			this.searching = false;
			let _tempPageItems = [];
			this.pagination.totalRows = data.nbHits;
			for (let index = 0; index < data.nbPages; index++) {
				_tempPageItems.push(index);
			}
			this.pagination.items = _tempPageItems;
		},
        resetProductFiltering() {
			this.pagination = {
				totalRows: null,
				items: [],
			};
			this.getModels();
			this.filters = this.getDefaultFilter();
		},
    },
	watch: {
		filters: {
			handler: function (query) {
				search.setURLParameters(query);
				this.searchProducts();
			},
			deep: true,
		},
		'filters.manufacturers': {
			handler(value) {
				this.getModels(value)
			}
		}
	},
}
</script>