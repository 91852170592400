<template>
	<div>
		<banner title="Account" />

		<main role="main">
			<section class="products ptb-5">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-3">
							<div>
								<b-tabs content-class="mt-3">
									<b-tab title="Account" active>
										<div class="col-md-12 mb-3" v-show="user">
											<address>
												<strong>{{ user.firstname }} {{ user.lastname }}</strong>
												<br />
												{{ user.email }}
											</address>

											<address>
												<strong>Contact no:</strong>
												<br />
												<div v-if="user.contactNumber">
													<span>{{ user.contactNumber }}</span>
												</div>
												<div v-else>
													<span>Contact number not available.</span>
													<a href="javascript:void(0)" @click="showUpdateProfileModal">Set now?</a>
												</div>
											</address>

											<!-- <address>
												<strong>Birthday:</strong>
												<br />
												<div v-if="user.birthday">
													<span>{{ user.birthday }}</span>
												</div>
												<div v-else>
													<span>Birthday not available.</span>
													<a href="javascript:void(0)" @click="showUpdateProfileModal">Set now?</a>
												</div>
											</address>-->

											<!-- <address>
												<strong>Gender:</strong>
												<br />
												<span>{{ user.gender }}</span>
											</address>-->

											<address>
												<strong>Registered Address:</strong>
												<br />
												<div v-if="user.province">
													<span>{{ user.streetAddress }}, {{ user.barangay }}, {{ user.city }}, {{ user.province }}, {{ user.region }}</span>
												</div>
												<div v-else>
													<span>Address not available.</span>
													<a href="javascript:void(0)" @click="showUpdateProfileModal">Set now?</a>
												</div>
											</address>

											<address v-if="user.secondaryAddress">
												<strong>Secondary Address:</strong>
												<br />
												<div>
													<span>{{ user.secondaryAddress.streetAddress }}, {{ user.secondaryAddress.barangay }}, {{ user.secondaryAddress.city }}, {{ user.secondaryAddress.province }}, {{ user.secondaryAddress.region }}</span>
												</div>
											</address>

											<a
												href="javascript:void(0)"
												class="btn btn-outline-info"
												@click="showUpdateProfileModal"
											>
												Edit
												<i class="fa fa-pen"></i>
											</a>
										</div>
									</b-tab>
									<b-tab title="Orders">
										<OrderList :userId="user.userId" />
									</b-tab>
									<b-tab title="Loan Applications">
										<ApplicationList :userId="user.userId" />
									</b-tab>
									<b-tab title="Wishlist">
										<table class="table" v-if="wishlist.length">
											<thead>
												<tr>
													<th scope="col">&nbsp;</th>
													<th scope="col">Item</th>
													<th scope="col">Price</th>
													<th scope="col">&nbsp;</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(wish, index) in wishlist" :key="index">
													<td>
														<img :src="wish.photo" width="100" />
													</td>
													<td>
														<router-link :to="`/products/${wish.slug}`">{{ wish.name }}</router-link>
													</td>
													<td>{{ wish.price | currencyDisplay }}</td>
													<td>
														<button class="btn btn-sm" @click="removeFromWishlist(wish)">
															<i class="fas fa-times text-danger"></i>
														</button>
													</td>
												</tr>
											</tbody>
										</table>
										<div class="row" v-else>
											<div class="col-12 text-center mt-5">
												<img src="/img/box.png" height="128" />
												<h5>No items in wishlist</h5>
											</div>
										</div>
									</b-tab>
								</b-tabs>
							</div>
						</div>
					</div>
				</div>
			</section>

			<b-modal size="xl" ref="profileModal" hide-footer title="Update Profile">
				<div class="d-block">
					<UpdateProfile />
				</div>
			</b-modal>
		</main>
	</div>
</template>

<script>
import banner from '@/components/ProductBanner';
import { api } from "../../config";
import { mapGetters } from "vuex";
import Noty from "noty";
import UpdateProfile from "../../components/Account/Update";
import OrderList from "./OrderList";
import ApplicationList from "./ApplicationList";
export default {
	components: {
		UpdateProfile,
		banner,
		OrderList,
		ApplicationList
	},
	data: () => ({
		wishlist: [],
		address: null,
	}),
	computed: {
		...mapGetters({
			user: "user",
		}),
	},
	async mounted() {
		this.$eventBus.$on("hideProfileModal", async () => {
			this.$refs.profileModal.hide();
		});
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === "SET_USER") {
				if (state.completeProfile) {
					this.address = `${this.user.streetAddress}, ${this.user.barangay}, ${this.user.city}, ${this.user.province}`;
				}
			}
		});

		setTimeout(() => {
			if (this.user !== null && (
				this.user.firstname === null ||
				this.user.lastname === null ||
				this.user.contactNumber === null ||
				this.user.streetAddress === null ||
				this.user.barangay === null ||
				this.user.city === null ||
				this.user.province === null ||
				this.user.region === null ||
				!this.user.contactNumber.length ||
				!this.user.streetAddress.length ||
				!this.user.barangay.length ||
				!this.user.city.length ||
				!this.user.province.length ||
				!this.user.region.length)
			) {
				this.$refs.profileModal.show();
			} else {
				this.address = `${this.user.streetAddress}, ${this.user.barangay}, ${this.user.city}, ${this.user.province}`;
			}

			this.getWishlist();
		}, 2000);
	},
	methods: {
		showUpdateProfileModal() {
			this.$refs.profileModal.show();
		},
		async getWishlist() {
			let request = await fetch(`${api}/customer/wishlists`, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			this.wishlist = await request.json();
		},
		async removeFromWishlist(wish) {
			await fetch(`${api}/customer/wishlists/remove`, {
				method: "DELETE",
				body: JSON.stringify({
					sku: wish.sku,
				}),
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${this.$store.getters.token}`,
				},
			});

			new Noty({
				type: "success",
				theme: "semanticui",
				layout: "bottomRight",
				text: `${wish.name} removed from wishlist`,
				timeout: 5000,
			}).show();

			await this.getWishlist();
		},
	}
};
</script>

<style scoped>
.wishlistImage {
	width: 100%;
}
</style>
