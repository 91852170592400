<template>
    <div>
        <main role="main">
            <section class="products pt-4 pb-4">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="text-center">
                                <span style="font-size:15pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">How to Apply for a Motorcycle Loan Online</span>
                            </p>
                            <p>
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Are you thinking about applying for a motorcycle loan with Transcycle for your website purchase? Easy!</span>
                            </p>
                            <p>
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Just follow the steps below:</span>
                            </p>
                            <p>
                                <span style="font-size:13pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">STEP 1: Make sure that you have digital or scanned copies of the following ready!</span>
                            </p>
                            <p class="ml-5">
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">1. Valid ID – Driver’s License, Voter’s ID, Passport, SSS ID, or UMID, and the like</span>
                            </p>
                            <p class="ml-5">
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">2. Proof of Income – One (1) Month Pay Slip, Certificate of Employment with Compensation, or Latest ITR or Bank Statement, and the like</span>
                            </p>
                            <p class="ml-5">
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">3. Proof of Billing – Latest Electric Bill, Latest Telephone or Internet Bill, Latest Water Bill</span>
                            </p>
                            <p class="ml-5">
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">4. Latest Photo – 2 inch by 2 inch Photo with White Background</span>
                            </p>
                            <p>
                                <span style="font-size:13pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">STEP 2: Register your new Transcycle Online account or Login 
                                    <router-link style="color:blue;text-decoration:underline" v-if="loggedIn" to="/products/motorcycles">here</router-link>
                                    <router-link style="color:blue;text-decoration:underline" v-else to="/login">here</router-link>.
                                </span>
                            </p>
                            <p>
                                <span style="font-size:13pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">STEP 3: Choose your Motorcycle from our selection of products and add to cart, 
                                    <router-link style="color:blue;text-decoration:underline" to="/products/motorcycles">see what's new</router-link>.
                                </span>
                            </p>
                            <p>
                                <span style="font-size:13pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">STEP 4: In Select Payment Method, choose “Apply for Installment”.</span>
                            </p>
                            <p>
                                <span style="font-size:13pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:600;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">STEP 5: Fill up the application information needed, and upload the required documents.</span>
                            </p>
                            <p>
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">Read and if you agree, click on the consent box of our Data Privacy Act and Implementing Rules and Regulations policies.</span>
                            </p>
                            <p class="pb-3">
                                <span style="font-size:11pt;font-family:Calibri,sans-serif;color:#000000;background-color:transparent;font-weight:400;font-style:normal;font-variant:normal;text-decoration:none;vertical-align:baseline;white-space:pre;white-space:pre-wrap;">You’re done applying! Just wait for 1 to 2 days so we may contact you for further instructions that may be needed for processing and approval.</span>
                            </p>
                            <div class="col-md-2 offset-md-5 text-center">
                                <p>
                                    <b-button squared size="sm" variant="danger" class="text-uppercase" to="/products/motorcycles">SHOP NOW</b-button>
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        </main>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
			loggedIn: "loggedIn",
		}),
    }
}
</script>