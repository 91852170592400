<template>
    <b-row>
        <b-col col md="12 text-center">
            <b-img src="/img/empty_state.png" alt="No Search" height="400"></b-img>
            <h5>
                <p>{{message}}</p>
            </h5>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props: {
        message: String
    }
}
</script>