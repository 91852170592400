<template>
    <div class="mb-5">
        <banner title="Frequently Asked Questions" />

        <main role="main">
            <div class="products single-product pt-5">
                <div class="container">
                    <div class="row mt-5">
                        <div class="col-12">
                            <b><u>How to Pay Online - GCash Pay Bills</u></b>
                            <ol style="padding-left: 12px;">
                                <li><p>Buksan ang GCash app at i-click ang <b>PAY BILLS</b> at piliin ang <b>LOANS</b> sa Biller Categories</p></li>
                                <li><p>Hanapin ang <b>UNISTAR</b> sa listahan ng mga biller sa pamamagitan ng pag-search o pag-scroll</p></li>
                                <li><p>I-click ang <b>Loan Type</b> sa payment form ng Unistar at piliin ang <b>Unistar Motorcycle Loan</b>. Pagkatapos ay i-fill up ang iyong loan account details, o kung kanino nakapangalan ang motorcycle loan na iyong babayaran.</p></li>
                                    <ul class="ml-5">
                                        <li><p>Kung hindi niyo kabisado ang Account Number, tumawag lang o mag-SMS sa branch kung saan kinuha ang motor at loan, o sa aming Customer Hotline numbers (Globe) 09171525914 / (Smart) 09190625302.</p></li>
                                    </ul>
                                <li><p>I-confirm ang iyong payment details at makatatanggap ka agad ng SMS mula sa GCash! Pagkatapos ng 2-3 na araw ay makatatanggap ka rin ng SMS mula sa Unistar kung saan nakasaad ang iyong payment reference number.</p></li>
                            </ol>

                            <p>
                                <b>GCash - Other FAQs</b>
                            </p>
                            <ol>
                                <li style="font-weight:bold;"><b>Saan maaaring makuha o makita ang Account Number?</b></li>
                                    <p>Maaaring tawagan ang aming Customer Support (CS) o itanong sa branch na pinagkunan mo ng loan para malaman ang iyong account number. Hanapin ang branch contact number dito sa website under “Branches” sa itaas.</p>
                                    <p>Note: Makatatanggap ka rin ng text message o SMS mula sa UNISTAR tungkol sa iyong Loan Details. I-open lamang ang message kung saan makikita ang ang Loan Account Number na sa susunod ay magiging available na rin sa text message o SMS na iyong matatanggap patungkol sa iyong payment confirmation.</p>
                                    <p>Transcycle Marketplace delivers to any location throughout the NCR region within the Philippines; standard shipping rates apply unless specified. Deliveries can only be made to locations with valid street address or rural route number with postal code. Deliveries to rural or remote areas and territories may require additional shipping charges.</p>
                                <li style="font-weight:bold;"><b>Ano ang mga fees kapag gumamit ng GCash sa pagbayad ng aking loan?</b></li>
                                    <p>Ang GCash ay may convenience fee na Php15 on top ng total amount ng iyong monthly amortization na binabayad nang diretso sa GCash</p>
                                <li style="font-weight:bold;"><b>Maaari pa rin ba akong makakuha ng rebate kung magbayad sa GCash?</b></li>
                                    <p>(Kung ang payment ay ginawa sa iyong due date at ang posting is on the next business day)</p>
                                    <p>Yes, ire-record ng aming system ang transaction date kung kailan ginawa ang payment originally sa GCash.</p>
                                <li style="font-weight:bold;"><b>Paano makakapag "cash-in" gamit ang Gcash?</b></li>
                                    <p>Maaari kang mag Cash-In sa pamamagitan ng BPI Mobile Banking, MoneyGram, Western Union, RCBC Mobile Banking, UnionBank Mobile Banking, PayPal Funds Transfer and MasterCard/Visa debit cards na makikita sa iyong GCash App. Maaari ring magpunta sa mga GCash Partner Outlets (GPOs) gaya ng Globe Stores, Puregold, SM Business Center, Cebuana Lhuillier, Villarica Pawnshop, Tambunting Pawnshop, RD Pawnshop, at 7-Eleven Cliqq Kiosks.</p>
                            </ol>
                            <b><u>Here’s How to Pay Online - Other Channels</u></b>
                            <ol>
                                <li>Mag-fill out ng online payment form sa <a style="color:blue" target="_blank" href="https://ptipages.paynamics.net/unistar/">https://ptipages.paynamics.net/unistar/</a></li>
                                <li>Basahin at mag-agree sa Terms and Conditions.</li>
                                <li>Piliin from the payment options ang inyong preferred online payment channel:</li>
                                    <ul class="ml-5">
                                        <li>BDO</li>
                                        <li>M Lhuillier</li>
                                        <li>O iba pang authorized online payment channels na nakalista sa ibaba</li>
                                    </ul>
                                <li>Sundin ang payment instructions mula sa inyong napiling online payment channel.</li>
                                <li>Kapag natapos na ang proseso ng inyong pagbayad sa napiling online payment channel, ise-send ang payment details sa inyong e-mail address.</li>
                                <li>Kapag successful ang inyong transaction, makata-tanggap kayo ng payment confirmation mula sa Unistar via SMS within 2-3 days.</li>
                            </ol>

                            <p>
                                <b>Online Payment Options</b>
                            </p>
                            <ol>
                                <li>
                                    <b>Online Bills Payment</b>
                                        <ul class="ml-3">
                                            <li>BDO</li>
                                            <li>PNB</li>
                                            <li>UCPB</li>
                                            <li>Security Bank</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Online Fund Transfer</b>
                                        <ul class="ml-3">
                                            <li>BancNet</li>
                                            <li>BPI</li>
                                            <li>Union Bank</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Mobile Wallet</b>
                                        <ul class="ml-3">
                                            <li>GCash</li>
                                            <li>Coins.ph</li>
                                            <li>GrabPay</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Online Over-the-Counter Payment</b>
                                        <ul class="ml-3">
                                            <li>M Lhuillier</li>
                                            <li>Cebuana Lhuillier</li>
                                            <li>SM Bills Pay</li>
                                            <li>Express Pay</li>
                                            <li>EC Pay</li>
                                            <li>7 Eleven</li>
                                            <li>Cliqq</li>
                                            <li>DA5</li>
                                            <li>Truemoney</li>
                                            <li>Posible</li>
                                            <li>PNB</li>
                                            <li>UCPB</li>
                                        </ul>
                                </li>
                            </ol>

                            <b><u>Here’s How to Pay Over-the-Counter via Authorized Payment Centers</u></b>
                            <br>
                            <br>
                            <p>
                                <b>M LHUILLIER KWARTA PADALA</b>
                            </p>
                            <ol>
                                <li>Go to any branch of M LHUILLIER</li>
                                <li><a>Fill up the ML Kwarta Padala form like as follows:</a>
                                    <ul class="ml-5">
                                        <li>COMPANY NAME: <b>UNISTAR CREDIT AND FINANCE CORP.</b></li>
                                        <li>ACCOUNT NAME: <b>Name of Account Owner</b></li>
                                        <li>ACCOUNT NUMBER: <b>[3-digit BRANCH CODE (see #3)]-0000-0000<br>e.g. “123-0000-0000”</b></li>
                                        <li>CELL/CONTACT NO.: <b>Mobile Number of Account Owner</b></li>
                                        <li>AMOUNT PHP: <b>Payment Amount</b></li>
                                    </ul>
                                </li>
                                <li>To know your nearest branch’s <b>BRANCH CODE</b>, contact our Customer Support at 09171525914 or 09190625302 (Mon-Fri, 8 A.M.-5 P.M. only), or click the Live Chat button on the bottom right corner of the website home page</li>
                                <li>Keep your payment receipt and expect an SMS notification from UNISTAR after 2-3 days confirming your payment</li>
                            </ol>
                            <br>
                            <p>
                                <b>OTHER AUTHORIZED OVER-THE-COUNTER PAYMENT CENTERS</b>
                            </p>
                            <ol>
                                <li>
                                    <a>Get your Reference No. via SMS within 2-3 days through the following:</a>
                                    <ul class="ml-5">
                                        <li>Contact our Customer Support at 09171525914 or 09190625302 (Mon-Fri, 8 A.M.-5 P.M. only)</li>
                                        <li>Click the Live Chat button on the bottom right corner of the website home page</li>
                                    </ul>
                                </li>
                                <li><a>Go to any of the Authorized Payment Centers on the list below:</a>
                                    <ul class="ml-5">
                                        <li><b>Cebuana Lhuillier</b></li>
                                        <li><b>Direct Agent 5</b></li>
                                        <li><b>EC Pay</b></li>
                                        <li><b>Express Pay</b></li>
                                        <li><b>SM Bills Pay</b></li>
                                        <li><b>True Money</b></li>
                                    </ul>
                                </li>
                                <li>Use the provided information from the SMS received after enrolling</li>
                                <li>Keep the receipt as proof of transaction</li>
                                <li>Upon successful transaction, you will receive the payment confirmation from Unistar via SMS within 2-3 days</li>
                            </ol>

                            <h4 class="mb-3">Other FAQs</h4>
                            <p>
                                <b><u>Return and Exchange Policy</u></b>
                            </p>
                            <a>Buyer may only apply for return and/or exchange of the product under the following circumstances:</a>
                            <ol>
                               <li>Unit or Item was damaged or defective during delivery;</li>
                               <li>Incorrect unit or item was delivered to the customer.</li>
                            </ol>
                            <p>Buyer must complete the Return/Exchange Request Form that will be provided by our Customer Support personnel. Please be advised that we will only accept refund and/or exchange of units and/or items within 7 days from the receipt of delivered units and/or item/s. Our company, Transcycle, has sole discretion over approval of buyer’s application upon investigation and validation of the request. Buyer will be notified by our customer care agents through email and/or SMS about the result of the request.</p>
                            
                            <a>Buyer must return the unit or item with below Return criteria:</a>
                            <ol>
                                <li>Original condition</li>
                                <li>Original packaging</li>
                                <li>Original copy of Sales Invoice</li>
                                <li>Product tag and label are still intact</li>
                            </ol>

                            <p>
                                <b>Exchange</b>
                            </p>
                            <p>Once a buyer's application for exchange has been accepted and approved, we will deliver a new unit or item in exchange for the damaged unit or item within 7 business days. When exchanging products, we can only offer a brand new unit or item of the same product. We are not obliged to provide an option of exchanging one product with another product or an option to claim a refund. We may charge administrative and handling charges depending on the circumstances of the return. Also, due to hygiene concerns, we are not accepting returns or exchanges for our Helmet products.</p>
                            <p>
                                <b>Return/Exchange FAQs</b>
                            </p>
                            <ol>
                                <li>
                                    <a>Where can I find the Return/Exchange Request Form?</a>
                                        <ul class="ml-5">
                                            <li>Our Customer Support will provide you our Return/Exchange Request Form. To contact our Customer Support, please send a chat message to one of them through our Live Chat messaging channel by clicking the blue icon located in the lower right hand corner of your screen.</li>
                                        </ul>
                                </li>
                                <li>
                                    <a>Can I return spare part products at the branch?</a>
                                    <ul class="ml-5">
                                        <li>Spare part product/s may only be exchanged at TCM Pasig branch once the customer’s return/exchange request has been approved by Transcycle’s Customer Support Team. The Customer must present the Sales Invoice and the Return Authorization Letter to the respective branch personnel.</li>
                                    </ul>
                                </li>
                                <li>
                                    <a>Do you provide delivery for returns/exchange?</a>
                                    <ul class="ml-5">
                                        <li>For spare parts, we will arrange the pick up and return of your item. We will also provide you with a Return Authorization Letter.</li>
                                        <li>For motorcycles, the customer must return the unit at the assigned branch provided by our Customer Support. The customer must also present the Sales Invoice and the Return Authorization Letter to the respective branch personnel.</li>
                                    </ul>
                                </li>
                                <li>
                                    <a>How do I receive the Return Authorization Letter?</a>
                                    <ul class="ml-5">
                                        <li>Once the request has been approved, our Customer Support will send a Return Authorization Letter to the customer.</li>
                                    </ul>
                                </li>
                            </ol>

                            <p>
                                <b>Questions?</b>
                            </p>
                            <p>If at any time you have questions about returning a unit or item purchased via Transcycle Website, please chat with our Live Chat Support by clicking the blue icon located at the lower right hand corner of your screen.</p>

                            <p>
                                <b><u>Other Terms and Conditions?</u></b>
                            </p>
                            <ol>
                                <li>Transcycle reserves the right to request valid identification prior to accepting product for a return.</li>
                                <li>Transcycle reserves the right to limit or refuse to accept the return of certain products at any time and for any reason.</li>
                                <li>Transcycle will only accept the return of merchandise purchased from Transcycle.</li>
                            </ol>
                            <p>
                                <b><u>Shipping and Delivery</u></b>
                            </p>
                            <ol>
                                <li>When will my ordered items be delivered?</li>
                                    <p>If all items in the order are available, items are usually delivered within 7 days (Mondays thru Saturdays) to any location throughout the NCR region within the Philippines. Please check the Estimated Delivery Time as posted on the product information page for individual items. If items are back ordered, a follow-up email will be sent to you with an estimated date and time of delivery.</p>
                                <li>Are there any shipping restrictions?</li>
                                    <p>Transcycle Marketplace delivers to any location throughout the NCR region within the Philippines; standard shipping rates apply unless specified. Deliveries can only be made to locations with valid street address or rural route number with postal code. Deliveries to rural or remote areas and territories may require additional shipping charges.</p>
                                <li>How are online orders delivered?</li>
                                    <p>Delivery is Mondays through Saturdays, between 8am and 6pm, Philippine local time. Multiple items may be shipped at different times due to the size of the item and/or due to the location that they will be shipped from.</p>
                                <li>Do I have to personally receive the delivered product/unit/item?</li>
                                    <p>Yes, a personal acceptance of the delivered product/unit/item is required. The buyer must be present during the orientation and the activation of the purchased motorcycle as well as during the signing of related documents. For spare parts and accessories, if the buyer cannot personally receive the delivered product/unit/item, a signed Authorization Letter with a photocopy of a government-issued ID must be emailed to our Customer Support one (1) day in advance of delivery date.
                                    However, any motorcycle unit must be accepted only by the customer who ordered the unit. For further concerns, please contact our Customer Support through our Live Chat messaging channel.</p>
                                <li>How do I track my order and/or package?</li>
                                    <p>You will receive a notification once your item has been shipped. You can track your order by looking at the respective Order ID that will be shown on your Order History page. To access your Order History page, just click the Account tab on your main screen, then click the Order option, then check the respective Order ID.</p>
                                <li>Can I refuse a package if it is damaged?</li>
                                    <p>If you would like to change or cancel your order, please contact our Customer Support as soon as possible through our Live Chat messaging channel by clicking the blue icon located in the lower right hand corner of your screen. We will do everything we can to accommodate your request if your order has not already been processed. Our Order Fulfillment and Shipping systems are designed to deliver orders quickly and efficiently. Therefore, it is not possible to change or cancel an order once it has been processed on our end. If this is the case, please accept the package.</p>
                            </ol>

                            <p>
                                <b><u>Account</u></b>
                            </p>
                            <ol>
                                <li>How do I create an account on Transcycle Website?</li>
                                    <p>To create an account on our website, just click the Login menu at the upper right portion of your screen. Then sign up using Google, Facebook or with your email account.</p>
                                <li>How do I navigate my Account page or update my Profile?</li>
                                    <p>To access your Account page, just click the Account menu on your main screen. You may update your profile by clicking the Edit button.</p>
                                <li>How do I reset my password?</li>
                                    <p>You may reset your password using the respective Account or Email platform.</p>
                                <li>
                                    <a>How do I check the status of my order?</a>
                                    <ul class="ml-5">
                                        <li>Log in using the account you used to create and submit your order</li>
                                        <li>Click the Account tab located in the upper right portion of your screen</li>
                                        <li>Click the Order option</li>
                                        <li>Click the respective Order ID that you want to check</li>
                                    </ul>
                                </li>
                                <br>
                                <p>Note: You will receive an email notification for every update on the status of your order.</p>
                            </ol>

                            <p>
                                <b><u>General Inquiries</u></b>
                            </p>
                            <ol>
                                <li>How do I avail an installment plan for Motorcycle?</li>
                                    <p>For motorcycle installment plans, please select the Apply for Installment option. You will be directed to our online Application Form wherein you can provide your personal information and upload the required documents. Once your loan application has been processed by our team, an email and SMS notification will be sent to you indicating the status of your loan application.</p>
                                <li>How do I contact the Customer Service team?</li>
                                    <p>Our Customer Service team is available from Mondays to Fridays, 8 A.M. to 6 P.M. Philippine local time. They can be reached through our Live Chat Support, a messaging channel represented by a blue icon located in the lower right hand corner of your screen.</p>
                            </ol>

                            <p>
                                <b><u>Cash Rebate Program</u></b>
                            </p>
                            <ul class="ml-5">
                                <li>What does the program offer?</li>
                                    <p>Cash Rebate</p>
                                <li>Who can avail the program?</li>
                                    <p>Customers who purchased motorcycle units (brand new, pre-owned, repo) from any Transcycle/Powercycle branches and paid for their loans through any of our authorized payment centers or online payment options.</p>
                                <li>How can I avail the cash rebate?</li>
                                    <p>Customers must pay for their monthly loans at any of the participating authorized payment centers or through any of our online payment channels in order to avail some cash rebate. A minimum payment amount of Php 2,000 is required to avail the rebate.</p>
                                <li>Where can I pay my monthly amortization for me to qualify for a cash rebate?</li>
                            <p class="mt-3">
                                <b>Online Payment Options</b>
                            </p>
                            <ol>
                                <li>
                                    <b>Online Bills Payment</b>
                                        <ul class="ml-3">
                                            <li>BDO</li>
                                            <li>PNB</li>
                                            <li>UCPB</li>
                                            <li>Security Bank</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Online Fund Transfer</b>
                                        <ul class="ml-3">
                                            <li>BancNet</li>
                                            <li>BPI</li>
                                            <li>Union Bank</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Mobile Wallet</b>
                                        <ul class="ml-3">
                                            <li>GCash</li>
                                            <li>Coins.ph</li>
                                            <li>GrabPay</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Online Over-the-Counter Payment</b>
                                        <ul class="ml-3">
                                            <li>M Lhuillier</li>
                                            <li>Cebuana Lhuillier</li>
                                            <li>SM Bills Pay</li>
                                            <li>Express Pay</li>
                                            <li>EC Pay</li>
                                            <li>7 Eleven</li>
                                            <li>Cliqq</li>
                                            <li>DA5</li>
                                            <li>Truemoney</li>
                                            <li>Posible</li>
                                            <li>PNB</li>
                                            <li>UCPB</li>
                                        </ul>
                                </li>
                                <li>
                                    <b>Over-the-Counter Payment or Deposit</b>
                                        <ul class="ml-3">
                                            <li>Any Transcycle or Powercycle branch nationwide</li>
                                            <li>M Lhuillier branches (follow the procedure on How To Pay section above)</li>
                                        </ul>
                                </li>
                            </ol>
                                <li class="mt-3">Am I still eligible for a cash rebate If I pay at a Transcycle store?</li>
                                    <p>Yes, you are still eligible for a cash rebate when you use the Over-the-counter (OTC) branch payment</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import banner from '@/components/ProductBanner';

export default {
	components: {
		banner
	}
}
</script>

<style scoped>
ol li {
    list-style-type: decimal;
}
ul li {
    list-style-type: circle;
}
</style>
